import { chartHoverLabelAttributes } from 'utils';

type ApiChartItem = {
  x: number[];
  y: number[];
  yLabel: string;
  xLabel: string;
};

type Chart = {
  marker: {
    color: string;
  };
  opacity: number;
  x: number[];
  y: number[];
  type: string;
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
  hovertemplate: string;
};

const sharedValues = {
  marker: {
    color: 'blue',
  },
  opacity: 0.6,
  textposition: 'outside',
  type: 'bar',
  hoverlabel: chartHoverLabelAttributes,
};

const formatToBarchartShape = (data: ApiChartItem): Chart[] => {
  return [{ ...sharedValues, x: data.x, y: data.y, hovertemplate: `%{x}: %{y:.2f}<extra></extra>` }];
};

export default formatToBarchartShape;
