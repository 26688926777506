import React, { useState, ReactNode, ReactElement } from 'react';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from 'Common/Shared.styles';

interface EllipsisButtonProps {
  children: ReactNode;
  icon: ReactElement;
  title: string;
  testId?: string;
  unStyled?: boolean;
  withIcon?: boolean;
}

// TODO look into console error related to
// ...Check the render method of `ForwardRef(Menu)`.
// https://insightcapital.atlassian.net/browse/PDT-2400
const StyledMenu = withStyles({
  list: {
    padding: 0,
    margin: 0,
  },
})(Menu);

const DropdownButton = ({ children, icon, title, testId }: EllipsisButtonProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const ref = React.createRef();

  // eslint-disable-next-line react/display-name
  const MuiMenu = React.forwardRef((props: { children: ReactNode }, ref) => {
    return (
      <StyledMenu
        ref={ref}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {props.children}
      </StyledMenu>
    );
  });

  return (
    <>
      <IconButton type="button" data-testid={testId} onClick={handleClick} title={title}>
        {icon}
      </IconButton>
      <MuiMenu ref={ref}>{children}</MuiMenu>
    </>
  );
};

export default DropdownButton;
