import styled from 'styled-components';

const StyledTableSticky = styled.div<{ withOverflow: boolean }>`
  ${({ withOverflow }) => {
    if (withOverflow) {
      return `
      overflow-x: auto;
    `;
    }
  }}
  width: 100%;
  text-transform: capitalize;
  position: relative;
  .table {
    .th,
    .td {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid #ddd;
      overflow: hidden;
      font-size: ${({ theme }) => theme.fontSizes.extraSmall};

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      background: ${({ theme }) => theme.colors.primaryDark};
      color: white;
      font-weight: bold;
    }
    .td {
      background: white;
      color: ${({ theme }) => theme.colors.darkGrey};
      white-space: nowrap;
      width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .right {
      text-align: right;
    }

    .tooltip {
      text-transform: uppercase;
      border-radius: 0.25rem;
      background: ${({ theme }) => theme.colors.black};
    }

    &.sticky {
      overflow: scroll;

      ::-webkit-scrollbar {
        display: block;
        width: 4px;
        border: 1px solid #d5d5d5;
      }

      ::-webkit-scrollbar-track {
        border-radius: 0;
        background: #eeeeee;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: #b0b0b0;
      }

      .header {
        top: 0;
        position: sticky;
        z-index: 1;
        width: 100%;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        align-self: flex-start;
        padding-left: 1.75rem;
        border-right: 2px solid;
        border-right-color: ${({ theme }) => theme.colors.lightGrey};
        text-align: left;
        font-weight: bold;
      }
    }
  }
`;

export const ThWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

export const THText = styled.span`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;

  padding-top: 0;
  padding-bottom: 0;

  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  text-transform: uppercase;

  svg {
    width: 7px;
    margin-right: 5px;
  }

  p {
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PageCount = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Pagination = styled.div<{ width: number }>`
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  position: sticky;
  z-index: 10;
  width: ${({ width }) => `${width}px`};
  background: white;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0 1.75rem;
  gap: 1rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
`;

export const HeaderWrapperButton = styled.div`
  margin-left: auto;
`;

export default StyledTableSticky;
