import React, { ReactElement, useRef } from 'react';
import { useQuery } from 'react-query';

import { Loader, TableLong } from 'Common';
import { CenterAlign, Card, Column } from 'Common/Shared.styles';
import { sortStringsAlphabetically, api, findColConfigLong, useDivSize } from 'utils';
import { chartMargin } from 'utils/useDivSize';
import { FinancialModels } from 'types/shared.types';

import { NonNumericDataTable as Props } from '../LayoutBuilder';

const fetchTableData = async (financialModel: FinancialModels, filterId: string, signal?: AbortSignal) => {
  const { data } = await api.get('/components/non_numeric_data', {
    params: {
      financial_model: financialModel,
      filter_id: filterId || null,
    },
    signal,
  });

  return {
    columns: ['index', ...sortStringsAlphabetically(data.columns.filter((column: string) => column !== 'index'))].map(
      (x: string, i: number) => findColConfigLong(x, i),
    ),
    rows: data.rows,
  };
};

const NonNumericDataTable = ({ id, financialModel, presentation, cacheKey, filterId = '' }: Props): ReactElement => {
  const { isLoading, data, isError } = useQuery([id, cacheKey, financialModel, filterId], ({ signal }) =>
    fetchTableData(financialModel, filterId, signal),
  );

  const parentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDivSize(parentRef);

  if (isError) {
    return <span data-testid="errorMessage">Unexpected error</span>;
  }

  return (
    <Column span={presentation.span}>
      <Card ref={parentRef}>
        {isLoading ? (
          <CenterAlign>
            <Loader testId="detailViewLoader" />
          </CenterAlign>
        ) : (
          <TableLong
            width={width - chartMargin}
            columns={data?.columns || []}
            data={data?.rows || []}
            endpoint="non_numeric_data"
            financialModel={financialModel}
            id="nonNumTable"
            initCollapsed={true}
            maxRowsToDisplay={15}
            title="Non-numeric Features"
            withColumnShowHide={true}
            withOverflow={window.innerWidth < 1200}
            withTip={true}
            filterId={filterId}
            cardWidth={width - chartMargin}
          />
        )}
      </Card>
    </Column>
  );
};

export default NonNumericDataTable;
