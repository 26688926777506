import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { Chart, Modal } from 'Common';
import {
  Card,
  ChartHeight,
  ChartWrapper,
  CardHeaderNew,
  CardHeaderNewTitle,
  Column,
  ModalChartWrap,
  CardHeaderActions,
} from 'Common/Shared.styles';
import { api, isUnauthenticated, useDivSize } from 'utils';
import { IconButton } from 'Common/Shared.styles';
import { DataGroupingBarChart as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import theme from 'theme';

type Point = {
  x: string;
};

type HandleClickProps = {
  points: Point[];
};

const fetchChartData = async (financialModel: FinancialModels, filterId: string, signal?: AbortSignal) => {
  const { data } = await api.get(`/components/data_grouping_bar_chart`, {
    params: {
      financial_model: financialModel,
      filter_id: filterId || null,
    },
    signal,
  });

  return data;
};

const DataGroupingBarChart = ({
  id,
  cacheKey,
  presentation,
  financialModel,
  selectedSegment,
  filterId = '',
  onClick,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { status, data, error } = useQuery([id, cacheKey, financialModel, filterId], ({ signal }) =>
    fetchChartData(financialModel, filterId, signal),
  );

  if (status === 'error' || isUnauthenticated(error)) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const handleFeatureClick = ({ points }: HandleClickProps) => {
    if (points.length > 0) {
      onClick(points[0].x);
    }
  };

  const sharedChartLayout = {
    barmode: 'relative',
    plot_bgcolor: 'white',
    showlegend: false,
    datarevision: new Date(),
    xaxis: {
      automargin: true,
      tickangle: data && data.x.length > 3 ? 20 : 0,
      ticklen: 8,
      tickcolor: 'white',
      tickfont: {
        size: 10,
      },
    },
    yaxis: {
      zerolinecolor: theme.colors.grey,
      gridcolor: theme.colors.lightGrey,
      showgrid: false,
      showticklabels: false,
    },
    margin: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
  };

  const itemToHighlight = selectedSegment || (data && data.default_grouping);

  const chartData = [
    {
      textposition: 'auto',
      textfont: {
        color: theme.colors.black,
        size: 10,
      },
      hoverinfo: 'none',
      type: 'bar',
      selectedpoints: data && data.x.length > 0 ? [data.x.indexOf(itemToHighlight)] : [],
      selected: {
        marker: {
          color: theme.colors.primary,
        },
      },
      unselected: {
        marker: {
          color: theme.colors.grey,
        },
      },
      x: (data && data.x) || [],
      text: (data && data.y) || [],
      y: (data && data.y) || [],
      width: 0.5,
    },
  ];

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Data grouping</CardHeaderNewTitle>
          <CardHeaderActions>
            <IconButton onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="dataGroupingBar"
            data={chartData}
            isLoading={status === 'loading'}
            layout={{ ...sharedChartLayout, height, width }}
            onClick={handleFeatureClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Data grouping</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>

          <ModalChartWrap>
            <Chart
              testId="dataGroupingBar"
              data={chartData}
              isLoading={status === 'loading'}
              layout={sharedChartLayout}
              onClick={handleFeatureClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default DataGroupingBarChart;
