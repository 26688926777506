import React, { ReactElement, useState, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { api, formatDate, useDivSize } from 'utils';
import { Chart, SettingsButton, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  CardTip,
  ChartHeight,
  ChartWrapper,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';

import { FinancialModels } from 'types/shared.types';
import { CrossSectionExposure as Props, PortfolioType } from '../LayoutBuilder';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import formatToCrossSection from './formatToCrossSection';

interface FetchChartData {
  financialModel: FinancialModels;
  portfolio: string;
  portfolioType: PortfolioType;
  crossSectionGrouping: string;
  date?: string;
  isDatasetMonthly: boolean;
  signal?: AbortSignal;
}

const fetchChartData = async ({
  financialModel,
  portfolio,
  portfolioType,
  crossSectionGrouping,
  date,
  isDatasetMonthly,
  signal,
}: FetchChartData) => {
  const { data } = await api.get('/components/cross_sectional_exposure', {
    params: {
      financial_model: financialModel,
      portfolio_dropdown: portfolio,
      portfolio_type: portfolioType,
      group_by: crossSectionGrouping,
      date: date || null,
    },
    signal,
  });

  const result = {
    chart: formatToCrossSection(data.data),
    dateOptions:
      data.date_options
        .sort((a: string, b: string) => {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .map((i: string) => ({ label: formatDate({ date: i, isDatasetMonthly }), value: i })) || [],
    groupBy: data.group_by_options.map((i: string) => ({ label: i, value: i })) || [],
    selectedDate: data.selected_date,
  };

  return result;
};

const PopoverContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const Popover = styled.div`
  border-radius: 0.5rem;
  background: white;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 2;
  right: 1rem;
  top: 3.5rem;
  padding: 1rem;
  box-shadow: 0 2px 9px rgb(0 0 0 / 25%);
`;

const PopoverItem = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

const Label = styled.div`
  font-size: 0.8rem;
  margon-bottom: 0.5rem;
`;

const CrossSectionalExposure = ({
  financialModel,
  portfolio,
  portfolioType,
  presentation,
  id,
  isPageLoading,
  cacheKey,
  grouping = 'Sector',
  isDatasetMonthly = false,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [dateFocus, setDateFocus] = useState('');
  const [crossSectionGrouping, setCrossSectionGrouping] = useState(grouping);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const payload = {
    financialModel,
    portfolio,
    portfolioType,
    date: dateFocus,
    crossSectionGrouping,
    isDatasetMonthly,
  };

  const hasPortfolio = Boolean(portfolio);
  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload, grouping],
    ({ signal }) => fetchChartData({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Cross Sectional Exposure</Spacer>
        </Card>
      </Column>
    );
  }

  const chartLayout = {
    margin: {
      t: 10,
      l: 20,
      r: 20,
    },
    yaxis: {
      automargin: true,
      title: 'Representation, %',
    },
    xaxis: {
      automargin: true,
      tickangle: 30,
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(),
  };

  const togglePopover = () => setIsPopoverVisible(!isPopoverVisible);

  return (
    <Column span={presentation.span} data-testid="crossSectionalExposure">
      <Card>
        <PopoverContainer>
          {hasPortfolio ? (
            <CardHeaderNew>
              <CardHeaderNewTitle>
                Cross Sectional Exposure <Pill>{portfolio}</Pill>
              </CardHeaderNewTitle>

              {!isLoading && (
                <CardHeaderActions>
                  <SettingsButton
                    data-testid="editCrossSectionalChart"
                    onClick={togglePopover}
                    isActive={isPopoverVisible}
                  />
                  <IconButton data-testid="portfolioBottomStockModal" onClick={() => setIsModalVisible(true)}>
                    <FullScreen />
                  </IconButton>
                </CardHeaderActions>
              )}

              {isPopoverVisible && !isLoading && (
                <Popover>
                  <PopoverItem>
                    <Label>Date</Label>
                    <Select
                      testId="crossSectionalDateSelect"
                      options={data?.dateOptions || []}
                      isControlled={true}
                      value={dateFocus || data?.selectedDate}
                      onChange={(e) => {
                        setDateFocus(e.target.value);
                        togglePopover();
                      }}
                    />
                  </PopoverItem>

                  <PopoverItem>
                    <Label>Group by</Label>
                    <Select
                      testId="crossSectionalGroupingSelect"
                      options={data?.groupBy || []}
                      isControlled={true}
                      value={crossSectionGrouping}
                      onChange={(e) => {
                        setCrossSectionGrouping(e.target.value);
                        togglePopover();
                      }}
                    />
                  </PopoverItem>
                </Popover>
              )}
            </CardHeaderNew>
          ) : (
            <CardTip>
              To see cross sectional exposure
              <br />
              please select a <strong>portfolio</strong>
            </CardTip>
          )}
          <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
            <Chart
              testId="crossSectionalExposure"
              data={data?.chart || null}
              isLoading={isPageLoading || isLoading}
              layout={{ ...chartLayout, height, width }}
            />
          </ChartWrapper>
        </PopoverContainer>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Cross Sectional Exposure <Pill>{portfolio}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <SettingsButton
                data-testid="editCrossSectionalChart"
                onClick={togglePopover}
                isActive={isPopoverVisible}
              />
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="crossSectionalExposureModal"
              data={data?.chart || null}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default CrossSectionalExposure;
