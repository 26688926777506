import React, { ReactElement, useState } from 'react';
import { ColumnInstance } from 'react-table';
import Fuse from 'fuse.js';

import FilterSearchHeader from '../../FilterSearchHeader';
import Checkbox from '../../Checkbox';

import { FilterWrapperStyle, GreyPill, FilterOptionsStyle } from '../../Shared.styles';

type Props = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnInstance<any>>;
  onClose: () => void;
};

const fuseOptions = {
  shouldSort: true,
  keys: ['id'],
};

const TableFilter = ({ id, columns, onClose }: Props): ReactElement => {
  const [searchText, setSearchText] = useState('');

  const filteredColumns = searchText ? new Fuse(columns, fuseOptions).search(searchText).map((i) => i.item) : columns;

  return (
    <FilterWrapperStyle data-testid={id}>
      <FilterSearchHeader
        testId="tableFilter"
        onSearch={(text) => setSearchText(text)}
        onClear={() => setSearchText('')}
        onClose={onClose}
        searchText={searchText}
      />

      <FilterOptionsStyle>
        {filteredColumns.length === 0
          ? 'No matches…'
          : filteredColumns.map((column) => (
              <GreyPill key={column.id}>
                <Checkbox
                  id={`${id}${column.id}`}
                  isChecked={column.isVisible}
                  label={column.id}
                  onChange={() => column.toggleHidden()}
                  value={column.id}
                />
              </GreyPill>
            ))}
      </FilterOptionsStyle>
    </FilterWrapperStyle>
  );
};

export default TableFilter;
