const formatAttributionView = (arg: string): string => {
  switch (arg) {
    case 'Regional':
      return 'regional';
    case 'Sectoral':
      return 'sectoral';
    case 'Stock Selection':
      return 'stock';
    default:
      return 'overall';
  }
};

export default formatAttributionView;
