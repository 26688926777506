import styled from 'styled-components';

export const ButtonLoader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    height: 6px;
    display: flex;
    width: 42px;
    position: relative;
    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
      animation: move 500ms linear 0ms infinite;
      margin-right: 12px;

      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
        animation: grow 500ms linear 0ms infinite;
      }

      &:last-child {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        animation: grow 500ms linear 0s infinite reverse;
      }
    }
  }

  @keyframes grow {
    from {
      transform: scale(0, 0);
      opacity: 0;
    }
    to {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes move {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(18px);
    }
  }
`;

export const ButtonStyle = styled.button<{
  btnTheme: 'primary' | 'transparent' | 'icon';
  size?: 'small' | 'large';
  disabled: boolean;
  withIcon: boolean;
  unStyled: boolean;
}>`
  position: relative;
  outline: none;
  width: 100%;
  font-size: 0.85rem;

  cursor: pointer;
  text-align: center;

  border-radius: 0.25rem;
  border-width: 2px;
  border-style: solid;
  outline: none;

  & + & {
    margin-left: 1rem;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => {
    if (size === 'small') {
      return `
        padding: 0.2rem 0.5rem 0.2rem;
      `;
    }

    if (size === 'large') {
      return `
        padding: 1rem;
        min-height: 2.85rem;
      `;
    }

    return `
      padding: 0.45rem 0.75rem 0.4rem;
    `;
  }};

  ${({ theme, btnTheme, disabled, withIcon, unStyled }) => {
    if (unStyled) {
      return `
      box-sizing: border-box;
        text-align: right;
        min-width: unset;
        margin: 0;
        padding: 0;
        background: none;
        border-radius: 0;
        border: none;
        width: 100%;
    
        &:hover {
          svg {
            fill: ${theme.colors.primary};
          }
          
          color: ${theme.colors.primary};
        }
    
        svg {
          fill: ${theme.colors.darkGrey};
          transform: translateX(1rem);
    
          &:hover {
            fill: ${theme.colors.primary};
          }
        }
      `;
    }

    if (btnTheme === 'icon') {
      return `
        border-color: ${theme.colour.lightGrey};
        background-color: transparent;
        
        &:hover {
          background-color: #f3f4f4;
        }
      `;
    }

    if (btnTheme === 'transparent') {
      return `
        border-color: ${theme.colour.lightGrey};
        background-color: transparent;
        
        &:hover {
          background-color: #f3f4f4;
        }
      `;
    }

    if (withIcon) {
      return `
        border: none;
        background-color: transparent;
        border-radius: 0;
        min-width: unset;
        width: unset;
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr;
        font-size: 0.85rem;
        color: ${theme.colors.darkGrey};

        &:hover {
          color: ${theme.colors.primary};
        }
      `;
    }

    return `
      color: white;
      border-color: ${disabled ? theme.colors.darkGrey : theme.colors.primaryDark};
      background-color: ${disabled ? theme.colors.darkGrey : theme.colors.primaryDark};
      transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
      &:hover {
        border-color: ${disabled ? theme.colors.darkGrey : theme.colors.primary};
        background-color: ${disabled ? theme.colors.darkGrey : theme.colors.primary};
      }
    `;
  }};
`;
