import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from 'theme';

export const StyledHeader = styled.div`
  background-color: #f7fbff;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  width: 100vw;
  z-index: 100;
`;

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(320px, 25%) 1fr;
  align-items: center;
  height: 72px;
`;

export const NavWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.75rem;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    justify-content: flex-end;
  }
`;

export const NavControl = styled.div`
  display: flex;
  height: 100%;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledLink = styled(NavLink)`
  padding: 0.75rem 1rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `4px solid ${theme.colors.white}`};
  max-width: 180px;

  &:hover {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.primary}`};
  }
`;

export const MenuLink = styled(NavLink)`
  background: none;
  border: none;
  outline: none;
  appearance: none;
  display: none;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  font-size: 14px;
  width: 100%;
  min-width: 12rem;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const NextIconWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLinkNoBorder = styled(StyledLink)`
  border-left: none;
`;

export const SelectModel = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  height: 100%;
  border-right: 1px solid #e5e5e5;

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    border: none;
  }

  select {
    outline: none;
    border: none;
    background: none;
    text-align-last: right;
    padding-right: 0.5rem;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const Avatar = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  font-size: 14px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
`;

export const MenuItem = styled.button`
  background: none;
  border: none;
  outline: none;
  appearance: none;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 14px;
  width: 100%;
  min-width: 12rem;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.black};
  padding: 0.5rem 1rem;
  font-size: 14px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const MenuTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  padding: 0.5rem 1rem;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: 600;
`;

export const ControlWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-left: 1.75rem;
`;
