import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';

import { ToastBlock } from 'Common';

import authConfig from './amplifyAuthCreds';

import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import theme from './theme';
import './index.css';

import { store } from 'store';
import { Provider } from 'react-redux';

Sentry.init({
  dsn: 'https://db4ae3df8a9c477988ee823a1931debf@o276923.ingest.sentry.io/5384543',
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
});

Amplify.configure(authConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ToastProvider components={{ Toast: ToastBlock }}>
              <App />
            </ToastProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
