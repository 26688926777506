import styled from 'styled-components';

export const FeatureFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Outliers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.h2`
  padding: 0.75rem 1.75rem;
  font-size: 0.85rem;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const OutliersLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 0.5rem;
  background: ${({ theme }) => theme.colors.lightGrey};
`;

export const OutliersLabelText = styled.span`
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 0.5rem;
`;

export const SmallSpacer = styled.div`
  padding: 0.75rem 1.75rem;
  background: transparent;

  select {
    width: 100%;
    text-align-last: left;
    font-size: 0.9rem;
    color: black;
  }
`;

export const ListWrapItem = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 1.75rem;
  display: flex;
  align-items: center;
`;
