import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api, findColConfig } from 'utils';
import { Table, CardLoader } from 'Common';
import {
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Spacer,
  SpacerTop,
  Card,
  LoaderMessage,
  Column,
} from 'Common/Shared.styles';
import { DownloadCsvButton, IndexAndValuesParams } from 'Common/CsvButton/CsvButton';

import { QuarterlyReturnsTable as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchTableDataProp = {
  financialModel: FinancialModels;
  benchmark: string;
  signal?: AbortSignal;
};

const CenterLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 654px;
`;

const fetchTableData = async ({ financialModel, benchmark, signal }: FetchTableDataProp) => {
  const { data } = await api.get('/components/ml_returns_and_index_table_data', {
    params: {
      financial_model: financialModel,
      benchmark_id: benchmark || null,
    },
    signal,
  });

  return { columns: data.columns.map(findColConfig) || [], rows: data.rows || [] };
};

const QuarterlyReturnsTable = ({
  id,
  financialModel,
  maxRowsToDisplay,
  isPageLoading,
  benchmark,
  cacheKey,
  presentation,
}: Props): ReactElement => {
  const { isLoading, data, isError } = useQuery(
    [id, financialModel, cacheKey, benchmark],
    ({ signal }) => fetchTableData({ financialModel, benchmark, signal }),
    {
      enabled: !isPageLoading,
    },
  );

  const [width, setWidth] = useState(300);

  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.offsetWidth);
    }
  }, [parentRef]);

  if (isError) {
    return <span>An error has occured with QuarterlyReturnsTable</span>;
  }

  const componentLoading = isPageLoading || isLoading;

  return (
    <Column span={presentation.span} data-testid="quarterlyReturnsTable">
      <Card>
        <Spacer>
          <CardHeaderNew>
            <CardHeaderNewTitle data-testid="returnsAndIndexHeader">
              Quarterly Returns and Index Values
            </CardHeaderNewTitle>

            {componentLoading === false && (
              <CardHeaderActions>
                <DownloadCsvButton
                  fetchData={{
                    url: '/components/ml_returns_and_index_table_data',
                    params: {
                      financial_model: financialModel,
                      output: 'csv',
                    } as IndexAndValuesParams,
                  }}
                  testId="downloadIndexAndValuesTableButton"
                  filename="mlQuarterlyReturns.csv"
                  cacheKey={`${financialModel}${benchmark}${cacheKey}`}
                />
              </CardHeaderActions>
            )}
          </CardHeaderNew>

          <SpacerTop>
            {componentLoading ? (
              <CenterLoader data-testid="returnsAndIndexTableLoader">
                <CardLoader />
                <LoaderMessage>Training the model</LoaderMessage>
              </CenterLoader>
            ) : (
              <Table
                id="returnsAndIndexTable"
                columns={data?.columns || []}
                data={data?.rows || []}
                withOverflow={window.innerWidth < 1200}
                maxRowsToDisplay={maxRowsToDisplay}
                width={width}
              />
            )}
          </SpacerTop>
        </Spacer>
      </Card>
    </Column>
  );
};

export default QuarterlyReturnsTable;
