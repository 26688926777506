import React, { ReactElement } from 'react';
import { ErrorBoundary } from '@sentry/react';
import styled from 'styled-components';
import { Column, ErrorCard } from 'Common/Shared.styles';
import { Text, Button } from 'Common';

import { FinancialModels } from 'types/shared.types';

import TopLevelReturns from './TopLevelReturns';
import TopLevelOverallAttributions from './TopLevelOverallAttributions';
import AttributionContributions from './AttributionContributions';
import AttributionReturns from './AttributionReturns';
import AttributionWeights from './AttributionWeights';
import ProfitAndLoss from './ProfitAndLoss';
import PortfolioPerformance from './PortfolioPerformance';
import PortfolioMetrics from './PortfolioMetrics';
import TopStock from './TopStock';
import TopBottomStock from './TopBottomStocks';
import BottomStock from './BottomStock';
import ConstituentReturns from './ConstituentReturns';
import PortfolioAndIndexReturns from './PortfolioAndIndexReturns';
import CrossSectionExposure from './CrossSectionalExposure';
import TimeSeriesExposure from './TimeSeriesExposure';
import ExposureByFeature from './ExposureByFeature';
import PortfolioScatterPlot from './PortfolioScatterPlot';
import NumericFeatures from './NumericFeatures';
import ExposureConstituentReturns from './ExposureConstituentReturns';
import QuarterlyReturnsTable from './QuarterlyReturnsTable';
import RocCurve from './RocCurve';
import ConfusionMatrix from './ConfusionMatrix';
import GroupedScoreAgainstTargetRate from './GroupedScoreAgainstTargetRate';
import DataGroupingBarChart from './DataGroupingBarChart';
import DataGroupingPieChart from './DataGroupingPieChart';
import DataGroupingTable from './DataGroupingTable';
import NumericDataTable from './NumericDataTable';
import NonNumericDataTable from './NonNumericDataTable';
import FinancialData from './FinancialData';
import ComparisonAcrossNumericFeatures from './ComparisonAcrossNumericFeatures';
import DistributionByFeature from './DistributionByFeature';
import FeatureImportancePlot from './FeatureImportancePlot';
import FeatureImportanceOverTime from './FeatureImportanceOverTime';
import FeatureCorrelation from './FeatureCorrelation';
import TargetDistributionAgainstFeature from './TargetDistributionAgainstFeature';
import TargetAgainstFeature from './TargetAgainstFeature';
import TargetMeanAgainstFeature from './TargetMeanAgainstFeature';

import theme from 'theme';

export enum SpanTypes {
  FULL = 'full',
  HALF = 'half',
  THIRD = 'third',
}

export enum ComponentTypes {
  TOP_LEVEL_RETURNS = 'TOP_LEVEL_RETURNS',
  TOP_LEVEL_OVERALL_ATTRIBUTIONS = 'TOP_LEVEL_OVERALL_ATTRIBUTIONS',
  ATTRIBUTION_CONTRIBUTIONS = 'ATTRIBUTION_CONTRIBUTIONS',
  ATTRIBUTION_RETURNS = 'ATTRIBUTION_RETURNS',
  ATTRIBUTION_WEIGHTS = 'ATTRIBUTION_WEIGHTS',
  PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
  PORTFOLIO_PERFORMANCE = 'PORTFOLIO_PERFORMANCE',
  PORTFOLIO_METRICS = 'PORTFOLIO_METRICS',
  TOP_STOCKS = 'TOP_STOCKS',
  TOP_BOTTOM_STOCKS = 'TOP_BOTTOM_STOCKS',
  BOTTOM_STOCKS = 'BOTTOM_STOCKS',
  CONSTITUENT_RETURNS = 'CONSTITUENT_RETURNS',
  PORTFOLIO_AND_INDEX_RETURNS = 'PORTFOLIO_AND_INDEX_RETURNS',
  CROSS_SECTION_EXPOSURE = 'CROSS_SECTION_EXPOSURE',
  TIME_SERIES_EXPOSURE = 'TIME_SERIES_EXPOSURE',
  EXPOSURE_BY_FEATURE = 'EXPOSURE_BY_FEATURE',
  PORTFOLIO_SCATTER_PLOT = 'PORTFOLIO_SCATTER_PLOT',
  NUMERIC_FEATURES = 'NUMERIC_FEATURES',
  EXPOSURE_CONSTITUENT_RETURNS = 'EXPOSURE_CONSTITUENT_RETURNS',
  QUARTERLY_RETURNS_AND_INDEX_VALUES = 'QUARTERLY_RETURNS_AND_INDEX_VALUES',
  ROC_CURVE = 'ROC_CURVE',
  CONFUSION_MATRIX = 'CONFUSION_MATRIX',
  GROUPED_SCORE_AGAINST_TARGET_RATE = 'GROUPED_SCORE_AGAINST_TARGET_RATE',
  DATA_GROUPING_BAR_CHART = 'DATA_GROUPING_BAR_CHART',
  DATA_GROUPING_PIE_CHART = 'DATA_GROUPING_PIE_CHART',
  DATA_GROUPING_TABLE = 'DATA_GROUPING_TABLE',
  NUMERIC_DATA_TABLE = 'NUMERIC_DATA_TABLE',
  NON_NUMERIC_DATA_TABLE = 'NON_NUMERIC_DATA_TABLE',
  FINANCIAL_DATA = 'FINANCIAL_DATA',
  COMPARISON_ACROSS_NUMERIC_FEATURES = 'COMPARISON_ACROSS_NUMERIC_FEATURES',
  DISTRIBUTION_BY_FEATURE = 'DISTRIBUTION_BY_FEATURE',
  FEATURE_IMPORTANCE_PLOT = 'FEATURE_IMPORTANCE_PLOT',
  FEATURE_IMPORTANCE_OVER_TIME = 'FEATURE_IMPORTANCE_OVER_TIME',
  FEATURE_CORRELATION = 'FEATURE_CORRELATION',
  TARGET_DISTRIBUTION_AGAINST_FEATURE = 'TARGET_DISTRIBUTION_AGAINST_FEATURE',
  TARGET_AGAINST_FEATURE = 'TARGET_AGAINST_FEATURE',
  TARGET_MEAN_AGAINST_FEATURE = 'TARGET_MEAN_AGAINST_FEATURE',
}

export enum PortfolioType {
  MACHINE_LEARNING = 'machine_learning',
  OPTIMISATION = 'optimisation',
  OPTIMISATION_TWO = 'optimisation2',
  MANUAL = 'manual',
  NONE = 'none',
}

export interface Base {
  id: string;
  portfolioType: PortfolioType;
  type: ComponentTypes;
  financialModel: FinancialModels;
  isPageLoading: boolean;
  isPageError?: boolean;
  cacheKey: string;
  presentation: {
    span: SpanTypes;
    height?: number;
  };
}

export interface FinancialData extends Base {
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface NonNumericDataTable extends Base {
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface NumericDataTable extends Base {
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface TopLevelReturns extends Base {
  onChange: (arg: string) => void;
  attributionDate?: string;
  portfolio: string;
  portfolioType: PortfolioType;
}

export interface TopLevelOverallAttributions extends Base {
  setAttributionSortBy: (arg: string) => void;
  onChangeAttributionSegment: (arg: string) => void;
  attributionSortBy: string;
  date?: string;
  portfolio: string;
  view: string;
}

export interface AttributionContributions extends Base {
  portfolio: string;
  portfolioType: PortfolioType;
  attributionDate?: string;
  attributionView: string;
  attributionSortBy: string;
  attributionRegion: string;
  attributionSector: string;
  selectAttributionRegion: (arg: string) => void;
  selectAttributionSector: (arg: string) => void;
}

export interface AttributionReturns extends Base {
  portfolio: string;
  attributionDate?: string;
  attributionView: string;
  attributionSortBy: string;
  attributionRegion: string;
  attributionSector: string;
}

export interface AttributionWeights extends Base {
  portfolio: string;
  attributionDate?: string;
  attributionView: string;
  attributionSortBy: string;
  attributionRegion: string;
  attributionSector: string;
}

export interface ProfitAndLoss extends Base {
  portfolio: string;
  isDatasetMonthly: boolean;
  cacheKey: string; // TODO this is hack for demo
  onChangeBenchmark?: (arg: string) => void;
  onSelectPortfolio?: (arg: string) => void;
}
export interface PortfolioPerformance extends Base {
  portfolio: string;
  performanceDate: { date: string; index: number } | null;
  onClick?: (arg: { date: string; index: number }) => void;
  cacheKey: string; // TODO this is hack for demo
}
export interface PortfolioMetrics extends Base {
  portfolioToFind: string;
  onRemoveFilter?: () => void;
  onClick?: (arg: string) => void;
  cacheKey: string; // TODO this is hack for demo
}
export interface TopStocks extends Base {
  portfolio: string;
  date: string;
  onRemoveDate: () => void;
  data: {
    text: number[];
    x: string[];
    y: string[];
  };
}
export interface TopBottomStocks extends Base {
  portfolio: string;
  date: string;
  onRemoveDate: () => void;
}
export interface BottomStocks extends Base {
  portfolio: string;
  date: string;
  onRemoveDate: () => void;
  data: {
    text: number[];
    x: number[];
    y: string[];
  };
}

export interface ConstituentReturns extends Base {
  portfolio: string;
  feature?: string;
  portfolioDropdownOptions?: Option[];
  onPortfolioSelect?: (arg: string) => void;
}

export interface PortfolioAndIndexReturns extends Base {
  maxRowsToDisplay: number;
  benchmark: string;
}

export interface CrossSectionExposure extends Base {
  portfolio: string;
  grouping?: string;
  isDatasetMonthly?: boolean;
}

export interface PortfolioScatterPlot extends Base {
  portfolio: string;
  onClick: (arg: string) => void;
  onLoad: (arg: string) => void;
}

export interface ExposureByFeature extends Base {
  selectedFeature: string;
  onChange: (arg: string) => void;
  allSelectedFeatures: {
    id: string;
    label: string;
    value: string;
    lower: number;
    upper: number;
    operator: string;
    name: string;
    threshold: number;
    direction: string;
  }[];
}

export interface TimeSeriesExposure extends Base {
  portfolio: string;
  crossSectionGrouping: string;
  feature: string;
}

export interface NumericFeatures extends Base {
  feature: string;
  onClick: (arg: string) => void;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface ExposureConstituentReturns extends Base {
  portfolio: string;
  feature: string;
}

export interface QuarterlyReturnsTable extends Base {
  maxRowsToDisplay: number;
  benchmark: string;
  cacheKey: string;
}

export interface RocCurve extends Base {
  selectedModel: string;
}
export interface ConfusionMatrix extends Base {
  selectedModel: string;
}
export interface GroupedScoreAgainstTargetRate extends Base {
  selectedModel: string;
}
export interface DataGroupingBarChart extends Base {
  selectedSegment: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
  onClick: (arg: string) => void;
}

export interface DataGroupingPieChart extends Base {
  groupBy: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
  onClick: (arg: string) => void;
}

export interface DataGroupingTable extends Base {
  primaryGroup: string;
  subGroup: string;
  onRemoveSubGroup: () => void;
  startDate?: string;
  endDate?: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
  selectedFeatures?: string;
  enableColumnShowHide?: boolean;
}

export interface Option {
  label: string;
  value: string;
}

export type OptionWithCheck = {
  isChecked: boolean;
} & Option;

export interface ComparisonAcrossNumericFeatures extends Base {
  startDate: string;
  endDate: string;
  features: string;
  financialModel: FinancialModels;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
  onClick: (arg: string) => void;
}

export interface DistributionByFeature extends Base {
  startDate: string;
  endDate: string;
  features: Option[];
  selectedFeature: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
  onChangeFeature: (arg: string) => void;
  onViolinItemClick: (arg: { primaryGroup: string; subGroup: string }) => void;
}

export interface FeatureImportancePlot extends Base {
  startDate: string;
  endDate: string;
  cardHeight: number;
  options: { id: string; value: string | number; isChecked: boolean }[];
  filterId?: string;
}
export interface FeatureImportanceOverTime extends Base {
  startDate: string;
  endDate: string;
  cardHeight: number;
  options: { id: string; value: string | number; isChecked: boolean }[];
  filterId?: string;
}

export interface FeatureCorrelation extends Base {
  options: { id: string; value: string | number; isChecked: boolean }[];
  dateRange: {
    startIndex: number;
    endIndex: number;
  };
}

export interface TargetMeanAgainstFeature extends Base {
  selectedFeature: string;
  startDate: string;
  endDate: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface TargetAgainstFeature extends Base {
  selectedFeature: string;
  startDate: string;
  endDate: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

export interface TargetDistributionAgainstFeature extends Base {
  selectedFeature: string;
  startDate: string;
  endDate: string;
  filterId?: string; // TODO https://insightcapital.atlassian.net/browse/PDT-1975
}

type ComponentList =
  | TopLevelReturns
  | TopLevelOverallAttributions
  | AttributionContributions
  | AttributionReturns
  | AttributionWeights
  | ProfitAndLoss
  | PortfolioPerformance
  | PortfolioMetrics
  | TopStocks
  | TopBottomStocks
  | BottomStocks
  | ConstituentReturns
  | PortfolioAndIndexReturns
  | CrossSectionExposure
  | TimeSeriesExposure
  | ExposureByFeature
  | PortfolioScatterPlot
  | NumericFeatures
  | ExposureConstituentReturns
  | QuarterlyReturnsTable
  | RocCurve
  | ConfusionMatrix
  | GroupedScoreAgainstTargetRate
  | DataGroupingBarChart
  | DataGroupingPieChart
  | DataGroupingTable
  | ComparisonAcrossNumericFeatures
  | DistributionByFeature
  | FeatureImportancePlot
  | FeatureImportanceOverTime
  | FeatureCorrelation
  | TargetAgainstFeature
  | TargetMeanAgainstFeature
  | TargetDistributionAgainstFeature
  | Base;

type LayoutBuilderProps = {
  components: ComponentList[][];
  className?: string;
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 1.75rem;
  padding-bottom: 0;
  gap: 1.75rem;
  width: 100%;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

function Component(props: ComponentList): ReactElement {
  switch (props.type) {
    case ComponentTypes.PROFIT_AND_LOSS: {
      return <ProfitAndLoss {...(props as ProfitAndLoss)} />;
    }
    case ComponentTypes.PORTFOLIO_PERFORMANCE: {
      return <PortfolioPerformance {...(props as PortfolioPerformance)} />;
    }
    case ComponentTypes.CONSTITUENT_RETURNS: {
      return <ConstituentReturns {...(props as ConstituentReturns)} />;
    }
    case ComponentTypes.TOP_LEVEL_RETURNS: {
      return <TopLevelReturns {...(props as TopLevelReturns)} />;
    }
    case ComponentTypes.TOP_LEVEL_OVERALL_ATTRIBUTIONS: {
      return <TopLevelOverallAttributions {...(props as TopLevelOverallAttributions)} />;
    }
    case ComponentTypes.ATTRIBUTION_CONTRIBUTIONS: {
      return <AttributionContributions {...(props as AttributionContributions)} />;
    }
    case ComponentTypes.ATTRIBUTION_RETURNS: {
      return <AttributionReturns {...(props as AttributionReturns)} />;
    }
    case ComponentTypes.ATTRIBUTION_WEIGHTS: {
      return <AttributionWeights {...(props as AttributionWeights)} />;
    }
    case ComponentTypes.PORTFOLIO_METRICS: {
      return <PortfolioMetrics {...(props as PortfolioMetrics)} />;
    }
    case ComponentTypes.TOP_STOCKS: {
      return <TopStock {...(props as TopStocks)} />;
    }
    case ComponentTypes.TOP_BOTTOM_STOCKS: {
      return <TopBottomStock {...(props as TopBottomStocks)} />;
    }
    case ComponentTypes.BOTTOM_STOCKS: {
      return <BottomStock {...(props as BottomStocks)} />;
    }
    case ComponentTypes.PORTFOLIO_AND_INDEX_RETURNS: {
      if (props.portfolioType !== PortfolioType.MACHINE_LEARNING) {
        return <p>Not supported…</p>;
      }
      return <PortfolioAndIndexReturns {...(props as PortfolioAndIndexReturns)} />;
    }
    case ComponentTypes.EXPOSURE_BY_FEATURE: {
      if (props.portfolioType !== PortfolioType.OPTIMISATION) {
        return <p>Not supported…</p>;
      }
      return <ExposureByFeature {...(props as ExposureByFeature)} />;
    }
    case ComponentTypes.CROSS_SECTION_EXPOSURE: {
      return <CrossSectionExposure {...(props as CrossSectionExposure)} />;
    }
    case ComponentTypes.TIME_SERIES_EXPOSURE: {
      if (props.portfolioType === PortfolioType.OPTIMISATION) {
        return <p>Not supported…</p>;
      }
      return <TimeSeriesExposure {...(props as TimeSeriesExposure)} />;
    }

    case ComponentTypes.PORTFOLIO_SCATTER_PLOT: {
      if (props.portfolioType !== PortfolioType.MANUAL) {
        return <p>Not supported…</p>;
      }
      return <PortfolioScatterPlot {...(props as PortfolioScatterPlot)} />;
    }

    case ComponentTypes.NUMERIC_FEATURES: {
      return <NumericFeatures {...(props as NumericFeatures)} />;
    }

    case ComponentTypes.EXPOSURE_CONSTITUENT_RETURNS: {
      return <ExposureConstituentReturns {...(props as ExposureConstituentReturns)} />;
    }

    case ComponentTypes.QUARTERLY_RETURNS_AND_INDEX_VALUES: {
      if (props.portfolioType !== PortfolioType.MACHINE_LEARNING) {
        return <p>Not supported…</p>;
      }
      return <QuarterlyReturnsTable {...(props as QuarterlyReturnsTable)} />;
    }

    case ComponentTypes.ROC_CURVE: {
      if (props.portfolioType !== PortfolioType.MACHINE_LEARNING) {
        return <p>Not supported…</p>;
      }
      return <RocCurve {...(props as RocCurve)} />;
    }

    case ComponentTypes.CONFUSION_MATRIX: {
      if (props.portfolioType !== PortfolioType.MACHINE_LEARNING) {
        return <p>Not supported…</p>;
      }
      return <ConfusionMatrix {...(props as ConfusionMatrix)} />;
    }

    case ComponentTypes.GROUPED_SCORE_AGAINST_TARGET_RATE: {
      if (props.portfolioType !== PortfolioType.MACHINE_LEARNING) {
        return <p>Not supported…</p>;
      }
      return <GroupedScoreAgainstTargetRate {...(props as RocCurve)} />;
    }

    case ComponentTypes.DATA_GROUPING_BAR_CHART: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <DataGroupingBarChart {...(props as DataGroupingBarChart)} />;
    }

    case ComponentTypes.DATA_GROUPING_PIE_CHART: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <DataGroupingPieChart {...(props as DataGroupingPieChart)} />;
    }

    case ComponentTypes.DATA_GROUPING_TABLE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <DataGroupingTable {...(props as DataGroupingTable)} />;
    }

    case ComponentTypes.NUMERIC_DATA_TABLE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <NumericDataTable {...(props as NumericDataTable)} />;
    }

    case ComponentTypes.NON_NUMERIC_DATA_TABLE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <NonNumericDataTable {...(props as NonNumericDataTable)} />;
    }

    case ComponentTypes.FINANCIAL_DATA: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <FinancialData {...(props as FinancialData)} />;
    }

    case ComponentTypes.COMPARISON_ACROSS_NUMERIC_FEATURES: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <ComparisonAcrossNumericFeatures {...(props as ComparisonAcrossNumericFeatures)} />;
    }

    case ComponentTypes.DISTRIBUTION_BY_FEATURE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <DistributionByFeature {...(props as DistributionByFeature)} />;
    }

    case ComponentTypes.FEATURE_IMPORTANCE_OVER_TIME: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <FeatureImportanceOverTime {...(props as FeatureImportanceOverTime)} />;
    }
    case ComponentTypes.FEATURE_IMPORTANCE_PLOT: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <FeatureImportancePlot {...(props as FeatureImportancePlot)} />;
    }

    case ComponentTypes.FEATURE_CORRELATION: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <FeatureCorrelation {...(props as FeatureCorrelation)} />;
    }

    case ComponentTypes.TARGET_MEAN_AGAINST_FEATURE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <TargetMeanAgainstFeature {...(props as TargetMeanAgainstFeature)} />;
    }

    case ComponentTypes.TARGET_AGAINST_FEATURE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <TargetAgainstFeature {...(props as TargetAgainstFeature)} />;
    }

    case ComponentTypes.TARGET_DISTRIBUTION_AGAINST_FEATURE: {
      if (props.portfolioType !== PortfolioType.NONE) {
        return <p>Not supported…</p>;
      }
      return <TargetDistributionAgainstFeature {...(props as TargetDistributionAgainstFeature)} />;
    }

    default: {
      return <p data-testid="componentUnavailable">Component Unavailable…</p>;
    }
  }
}

const LayoutBuilder = ({ components, className }: LayoutBuilderProps): ReactElement => {
  return (
    <div className={className}>
      {components.map((row, index) => (
        <Row key={index}>
          {row.map((item) => (
            <ErrorBoundary
              key={`${item.id}-error`}
              fallback={({ resetError }) => (
                <Column span={item.presentation.span}>
                  <ErrorCard>
                    <Text
                      text={`Unfortunately ${item.type.replace('_', ' ').toLowerCase()} could not be loaded`}
                      color={theme.colors.black}
                      align="center"
                      textType="p"
                    />
                    <Button onClick={() => resetError()}>Click here to try again</Button>
                  </ErrorCard>
                </Column>
              )}
              onError={(error) => console.log(error)}
            >
              <Component key={item.id} {...item} />
            </ErrorBoundary>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default LayoutBuilder;
