import { chartHoverLabelAttributes } from 'utils';

type Props = {
  x: string[];
  y: number[];
  text: number[];
};

type FigureType = {
  marker: {
    color: string[];
  };
  opacity: number;
  text: number[];
  textposition: string;
  type: string;
  x: string[];
  y: number[];
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
  hovertemplate: string;
};

export type TargetShape = FigureType[];

const formatToCrossSection = (props: Props): TargetShape => {
  const figure = {
    marker: {
      color: [
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
        '#b9b6e9',
        '#8ac9ed',
        '#b4e1d6',
        '#ffec9f',
        '#faccad',
        '#ffabc2',
      ],
    },
    opacity: 0.75,
    textposition: 'outside',
    text: props.text,
    x: props.x,
    y: props.y,
    type: 'bar',
    hoverlabel: chartHoverLabelAttributes,
    hovertemplate: 'Representation: %{y: .2f}<br>Count: %{text}<br>%{x}<extra></extra>',
  };

  return [figure];
};

export default formatToCrossSection;
