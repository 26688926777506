import React, { ReactElement, useState, ChangeEvent, FormEvent } from 'react';

import {
  AppliedFilters,
  AvailableFilters,
  AvailableFilter,
  FilterTypes,
  NumericFilter,
  TextFilter,
} from '../FinancialData';

import {
  FormWrap,
  Form,
  FilterWrapper,
  NumberInputWrapper,
  NumberTo,
  FilterButton,
  ClearFilterButton,
  FilterControlHeader,
  FilterButtonText,
  AppliedFilterList,
  ButtonPill,
  AppliedFilterListLi,
  NumberInput,
  TextInput,
  Select,
  FilterHeader,
} from './FilterControl.styles';

import { ReactComponent as RemoveIcon } from './icon_close.svg';
import { ReactComponent as DownIcon } from './icon_down.svg';

type TextValue = string;
type NumberValue = { min: number; max: number };

interface CurrentFilterType extends AvailableFilter {
  value: TextValue | NumberValue;
}

interface Props {
  options: AvailableFilters;
  appliedFilters: AppliedFilters;
  onAdd: (arg: AppliedFilters) => void;
}
interface HandleNumberUpdate {
  value: string;
  type: 'min' | 'max';
}

const formatDefaultValue = (type: string) => (type === FilterTypes.TEXTUAL ? '' : { min: NaN, max: NaN });

const formatToFilterPayload = ({
  appliedFilters,
  currentFilter,
  isNumeric,
}: {
  appliedFilters: AppliedFilters;
  currentFilter: CurrentFilterType;
  isNumeric: boolean;
}) => {
  if (appliedFilters.length === 0) {
    return [
      {
        key: currentFilter.key,
        type: currentFilter.type,
        values: isNumeric ? currentFilter.value : [currentFilter.value],
      } as NumericFilter | TextFilter,
    ];
  }

  let isEdited = false;

  const updatedFilterList = appliedFilters.map((i) => {
    if (i.key === currentFilter.key) {
      isEdited = true;

      const additionalItem = {
        ...i,
        values: isNumeric ? currentFilter.value : [...(i.values as string[]), currentFilter.value],
      } as NumericFilter | TextFilter;

      return additionalItem;
    }
    return i;
  });

  if (isEdited === false) {
    updatedFilterList.push({
      key: currentFilter.key,
      type: currentFilter.type,
      values: isNumeric ? currentFilter.value : [currentFilter.value],
    } as NumericFilter | TextFilter);
  }

  return updatedFilterList;
};

const FilterControl = ({ options, appliedFilters, onAdd }: Props): ReactElement => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<CurrentFilterType>({
    ...options[0],
    value: formatDefaultValue(options[0].type),
  });

  const isNumeric = currentFilter.type === FilterTypes.NUMERIC;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const matchingFilter = options.find((i) => i.key === e.target.value);

    const payload = matchingFilter
      ? { ...matchingFilter, value: formatDefaultValue(matchingFilter.type) }
      : currentFilter;

    setCurrentFilter(payload);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    onAdd(formatToFilterPayload({ appliedFilters, currentFilter, isNumeric }));
  };

  const handleNumberUpdate = ({ value, type }: HandleNumberUpdate) => {
    setCurrentFilter({
      ...currentFilter,
      value: {
        ...(currentFilter.value as NumberValue),
        [type]: Number(value),
      },
    });
  };

  const toggleIsFilterVisible = () => setIsFilterVisible(!isFilterVisible);

  const filterItemFromList = ({ key, value }: { key: string; value: string }) => {
    const result = appliedFilters.reduce<(TextFilter | NumericFilter)[]>((acc, i) => {
      if (i.key === key && i.type === FilterTypes.TEXTUAL && (i as TextFilter).values.length > 1) {
        const filteredItem = {
          ...i,
          values: (i as TextFilter).values.filter((b) => b !== value),
        } as TextFilter;

        return [...acc, filteredItem];
      }
      if (i.key === key && i.type === FilterTypes.TEXTUAL && (i as TextFilter).values.length === 1) {
        return acc;
      }

      return [...acc, i];
    }, []);

    onAdd(result);
  };

  const isButtonSubmittable = isNumeric
    ? ![NaN, false].some((i) =>
        [
          (currentFilter.value as NumberValue).min,
          (currentFilter.value as NumberValue).max,
          (currentFilter.value as NumberValue).max <= (currentFilter.value as NumberValue).min,
        ].includes(i),
      )
    : (currentFilter.value as TextValue).length === 0;

  return (
    <FilterWrapper>
      <FilterControlHeader>
        {appliedFilters.length > 0 && (
          <>
            <AppliedFilterList>
              {appliedFilters.map((i, index) => {
                if ((i as NumericFilter).type === FilterTypes.NUMERIC) {
                  return (
                    <AppliedFilterListLi key={i.key + index} data-testid="numericListItem">
                      <FilterHeader>{i.key}</FilterHeader>
                      <ButtonPill
                        type="button"
                        data-testid="numericFilterButton"
                        onClick={() => onAdd(appliedFilters.filter((b) => b.key !== i.key))}
                      >
                        {`${(i as NumericFilter).values.min} to ${(i as NumericFilter).values.max}`}
                        <RemoveIcon />
                      </ButtonPill>
                    </AppliedFilterListLi>
                  );
                } else {
                  return (
                    <>
                      <AppliedFilterListLi>
                        <FilterHeader>{i.key}</FilterHeader>
                      </AppliedFilterListLi>

                      {(i as TextFilter).values.map((z) => (
                        <AppliedFilterListLi key={z + i.key + index} data-testid="textListItem">
                          <ButtonPill
                            type="button"
                            data-testid="textFilterButton"
                            onClick={() => filterItemFromList({ key: i.key, value: z })}
                          >
                            {z} <RemoveIcon />
                          </ButtonPill>
                        </AppliedFilterListLi>
                      ))}
                    </>
                  );
                }
              })}
            </AppliedFilterList>

            <ClearFilterButton type="button" onClick={() => onAdd([])}>
              Clear Filters
            </ClearFilterButton>
          </>
        )}

        <div>
          <FilterButton
            type="button"
            onClick={toggleIsFilterVisible}
            isActive={isFilterVisible}
            data-testid="toggleFilterVisibility"
          >
            <FilterButtonText>Add Filter</FilterButtonText> <DownIcon />
          </FilterButton>
        </div>
      </FilterControlHeader>

      {isFilterVisible && (
        <FormWrap>
          <Form onSubmit={handleSubmit}>
            <Select onChange={handleChange} value={currentFilter.key} data-testid="selectFilter">
              {options
                .sort((a, b) => (a.key > b.key ? 1 : -1))
                .map((i) => (
                  <option key={i.key} value={i.key}>
                    {i.key}
                  </option>
                ))}
            </Select>

            {isNumeric ? (
              <NumberInputWrapper>
                <NumberInput
                  data-testid="minNumericValue"
                  type="number"
                  value={String((currentFilter.value as NumberValue).min || '')}
                  onChange={(e) => handleNumberUpdate({ value: e.target.value, type: 'min' })}
                  placeholder="Min"
                />
                <NumberTo>to</NumberTo>
                <NumberInput
                  type="number"
                  data-testid="maxNumericValue"
                  value={String((currentFilter.value as NumberValue).max || '')}
                  onChange={(e) => handleNumberUpdate({ value: e.target.value, type: 'max' })}
                  placeholder="Max"
                />
              </NumberInputWrapper>
            ) : (
              <TextInput
                type="text"
                data-testid="textFilterValue"
                placeholder="Enter criteria"
                value={currentFilter.value as TextValue}
                onChange={(e) => setCurrentFilter({ ...currentFilter, value: e.target.value })}
              />
            )}

            <ClearFilterButton type="submit" disabled={isButtonSubmittable} data-testid="addFilterValue">
              Add
            </ClearFilterButton>
          </Form>
        </FormWrap>
      )}
    </FilterWrapper>
  );
};

export default FilterControl;
