import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'hooks';

import { Button, Loader } from 'Common';
import { CenterAlign } from 'Common/Shared.styles';

import App from './App';
import Auth from '@aws-amplify/auth';
import handleAuthentication, { UserReturnShape } from 'utils/useIsAuthed/handleAuthentication';
import { useQuery } from 'react-query';
import { isAuthenticated } from 'appReducer';

const WarningMessage = styled.div`
  background-color: white;
  max-width: 500px;
  padding: 2rem;
  margin: 3rem auto;
  text-align: center;
`;

const WarningMessageHeader = styled.header`
  padding-bottom: 1rem;
`;

const WarningMessageP = styled.p`
  padding-top: 0.4rem;
`;

const AppContainer = (): ReactElement => {
  const dispatch = useAppDispatch();

  const { isLoading, data, isError } = useQuery<UserReturnShape, Error>('USER_AUTH', handleAuthentication, {
    staleTime: 1000 * 60 * 60 * 8,
    onSuccess: (data) => {
      dispatch(isAuthenticated(data));
    },
  });

  if (isLoading) {
    return (
      <CenterAlign>
        <Loader />
      </CenterAlign>
    );
  }

  const handleRetry = () => {
    Auth.signOut();
    location.reload();
  };

  if (isError) {
    return (
      <WarningMessage>
        <WarningMessageHeader>
          <h2>Something went wrong</h2>
          <WarningMessageP>If the issue persists please contact support.</WarningMessageP>
        </WarningMessageHeader>

        <Button type="button" onClick={handleRetry}>
          Retry
        </Button>
      </WarningMessage>
    );
  }

  return <App isSignedIn={Boolean(data && data.isAuthed)} />;
};

export default AppContainer;
