type DecorateChartDataProps = {
  data: {
    name: string[];
    x: number[];
    y: number[];
    color: number[];
  };
  portfolio: string;
};

type FormatChartWithAnnotationsProps = {
  data: {
    name: string[];
    x: number[];
    y: number[];
  };
  portfolio: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReturnShape = { data: any; layout: any };

const formatChartWithAnnotations = ({ data, portfolio }: FormatChartWithAnnotationsProps) => {
  const indexOfSelectedPortfolio = data.name.length > 0 && data.name.indexOf(portfolio);
  if (!indexOfSelectedPortfolio) return [];
  return [
    {
      x: data.x[indexOfSelectedPortfolio],
      y: data.y[indexOfSelectedPortfolio],
      xref: 'x',
      yref: 'y',
      text: '',
      showarrow: true,
      arrowsize: 0.8,
      arrowhead: 6,
      ax: 0,
      ay: 0,
    },
  ];
};

const decorateChartData = ({ data, portfolio }: DecorateChartDataProps): ReturnShape => {
  const hovertemplate =
    data.name?.map((i: string) => `${i}<br />Return: %{y: .1f}<br>Count: %{x: .0f}<extra></extra>`) || null;

  const selectedpoints = data.name.length > 0 && portfolio ? [data.name.indexOf(portfolio)] : [];
  const unselectedPointsOpacity = data.name.indexOf(portfolio) >= 0 ? 0.6 : 1;
  const annotations = portfolio ? formatChartWithAnnotations({ data, portfolio }) : [];

  return {
    data: [
      {
        x: data.x || [],
        y: data.y || [],
        name: data.name || [],
        hovertemplate,

        mode: 'markers',
        marker: {
          colorbar: {
            title: 'Colorbar',
          },
          color: data.color || [],
          colorscale: [
            [0, '#fb7660'],
            [0.5, '#dad9d6'],
            [1, '#69a194'],
          ],
          line: {
            color: data.color,
            width: 2,
            colorscale: [
              [0, '#fb7660'],
              [0.5, '#dad9d6'],
              [1, '#69a194'],
            ],
          },
        },

        hoverlabel: {
          bgcolor: '#ffec9f',
          bordercolor: '#333333',
          font: {
            color: '#333333',
          },
        },
        showlegend: false,
        type: 'scatter',

        selectedpoints,
        selected: {
          marker: {
            opacity: 1,
            size: 10,
          },
        },
        unselected: {
          marker: {
            opacity: unselectedPointsOpacity,
          },
        },
      },
    ],
    layout: {
      margin: {
        t: 10,
        r: 10,
        l: 10,
        b: 10,
      },
      yaxis: {
        automargin: true,
        title: { text: 'Return, % pa' },
      },
      xaxis: {
        automargin: true,
        tickangle: 0,
        title: { text: 'Average # of constituents' },
      },
      barmode: 'relative',
      hovermode: 'closest',
      plot_bgcolor: 'white',
      showlegend: false,
      annotations,
      datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
    },
  };
};

export default decorateChartData;
