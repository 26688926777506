import React, { ReactElement, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api, useDivSize, findColConfigLong } from 'utils';
import { chartMargin } from 'utils/useDivSize';
import { CardLoader, TableLong } from 'Common';

import { Card, Pill, IconWrap, Column } from 'Common/Shared.styles';
import { ReactComponent as Icon } from './x-circle.svg';
import { DataGroupingTable as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

interface FetchTableData {
  financialModel: FinancialModels;
  primaryGroup: string;
  subGroup: string;
  startDate?: string;
  endDate?: string;
  filterId: string;
  selectedFeatures: string;
  signal?: AbortSignal;
}

const CardLoaderAlign = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fetchTableData = async ({
  financialModel,
  primaryGroup,
  subGroup,
  startDate,
  endDate,
  filterId,
  selectedFeatures,
  signal,
}: FetchTableData) => {
  const baseParams = {
    financial_model: financialModel,
    primary_group: primaryGroup || null,
    sub_group: subGroup || null,
    start_date: startDate || null,
    end_date: endDate || null,
    filter_id: filterId || null,
    selected_features: selectedFeatures || null,
  };
  const { data: tableData } = await api.get('/components/data_grouping_table', {
    params: baseParams,
    signal,
  });

  return {
    tableData: { ...tableData, columns: tableData.columns.map((x: string, i: number) => findColConfigLong(x, i)) },
  };
};

const FeatureImportanceTable = ({
  id,
  financialModel,
  primaryGroup,
  subGroup,
  presentation,
  onRemoveSubGroup,
  startDate = '',
  endDate = '',
  cacheKey,
  filterId = '',
  selectedFeatures = '',
  enableColumnShowHide = false,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { width } = useDivSize(parentRef);
  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, { financialModel, primaryGroup, subGroup, startDate, endDate, filterId, selectedFeatures }],
    ({ signal }) =>
      fetchTableData({
        financialModel,
        primaryGroup,
        subGroup,
        startDate,
        endDate,
        filterId,
        selectedFeatures,
        signal,
      }),
  );

  if (isError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const safePrimaryGroup = primaryGroup || data?.tableData.primaryGroup;
  const safeSubGroup = subGroup || data?.tableData.subGroup || '';

  return (
    <Column span={presentation.span}>
      <Card ref={parentRef}>
        {isLoading ? (
          <CardLoaderAlign data-testid="dataGroupTableLoader">
            <CardLoader />
          </CardLoaderAlign>
        ) : (
          <>
            <TableLong
              width={width - chartMargin}
              columns={data?.tableData.columns || []}
              data={data?.tableData.rows || []}
              id="dataGroupTable"
              maxRowsToDisplay={15}
              withColumnShowHide={enableColumnShowHide}
              withTip={true}
              withOverflow={true}
              cardWidth={width - chartMargin}
              initCollapsed={true}
              endpoint="data_grouping_table"
              financialModel={financialModel}
              title={
                !safePrimaryGroup && !safeSubGroup ? (
                  'Features'
                ) : (
                  <>
                    Features by {safePrimaryGroup && <Pill>{safePrimaryGroup}</Pill>}
                    {safeSubGroup && (
                      <Pill onClick={onRemoveSubGroup}>
                        {safeSubGroup}
                        <IconWrap>
                          <Icon />
                        </IconWrap>
                      </Pill>
                    )}
                  </>
                )
              }
              dataGroupingParams={{
                primary_group: primaryGroup || '',
                sub_group: subGroup || '',
                start_date: startDate || '',
                end_date: endDate || '',
              }}
              filterId={filterId}
            />
          </>
        )}
      </Card>
    </Column>
  );
};

export default FeatureImportanceTable;
