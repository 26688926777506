import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, formatChartAxisLabels, formatToViolinDataShape, formatToScatterShape, useDivSize } from 'utils';
import { Chart, ChartDataWarning, Modal } from 'Common';
import {
  Card,
  Spacer,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  Column,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { FinancialModels } from 'types/shared.types';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { TargetAgainstFeature as Props } from '../LayoutBuilder';
import { colorWay } from 'theme';

interface FetchData {
  financialModel: FinancialModels;
  selectedFeature: string;
  startDate: string;
  endDate: string;
  filterId: string;
  signal?: AbortSignal;
}

const fetchData = async ({ financialModel, selectedFeature, startDate, endDate, filterId, signal }: FetchData) => {
  const { data } = await api.get('/components/ml_feature_target_plot', {
    params: {
      financial_model: financialModel,
      selected_feature: selectedFeature,
      start_date: startDate,
      end_date: endDate,
      filter_id: filterId || null,
    },
    signal,
  });

  const showChartWarning =
    (data.plot_data && data.plot_data.length > 0 && data.plot_data[0].x.length === 0) ||
    (data.plot_data && data.plot_data.x && data.plot_data.x.length === 0);

  const chartTitles = {
    equity_model: 'returns',
    ratings_downgrade_model: 'downgrades',
    sharp_price_drop_model: 'price drops',
    default: 'target',
  };

  if (data.component_type === 'violin') {
    return {
      yLabel: selectedFeature,
      xLabel: chartTitles[financialModel] || chartTitles.default,
      chartData: formatToViolinDataShape(data.plot_data),
      showChartWarning,
    };
  }

  return {
    yLabel: formatChartAxisLabels(data.plot_data.xLabel),
    xLabel: data.plot_data.yLabel,
    chartData: formatToScatterShape(data.plot_data),
    showChartWarning,
  };
};

const TargetAgainstFeature = ({
  id,
  financialModel,
  selectedFeature,
  startDate,
  endDate,
  presentation,
  cacheKey,
  isPageError,
  filterId = '',
}: Props): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const { status, data, isError } = useQuery(
    [id, cacheKey, financialModel, selectedFeature, startDate, endDate, filterId],
    ({ signal }) => fetchData({ financialModel, selectedFeature, startDate, endDate, filterId, signal }),
  );

  const chartTitles = {
    equity_model: 'returns',
    ratings_downgrade_model: 'downgrades',
    sharp_price_drop_model: 'price drops',
    default: 'target',
  };

  const sharedChartLayout = {
    yaxis: {
      title: {
        text: data?.yLabel || '',
        font: {
          size: 10,
        },
        standoff: 16,
      },
      automargin: true,
      tickfont: {
        size: 10,
      },
      ticklen: 8,
      tickcolor: 'white',
      showgrid: false,
    },
    xaxis: {
      title: {
        text: data?.xLabel || '',
        font: {
          size: 10,
        },
        standoff: 16,
      },
      automargin: true,
      tickfont: {
        size: 10,
      },
      showgrid: false,
    },
    margin: {
      t: 0,
      l: 60,
      r: 0,
      b: 0,
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(),
    colorway: colorWay,
  };

  if (isError || isPageError) {
    return (
      <Card>
        <Spacer data-testid="errorMessage">An error has occured with Target Against Feature</Spacer>
      </Card>
    );
  }

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>
            {chartTitles[financialModel] || chartTitles.default} Against <Pill>{selectedFeature}</Pill>
          </CardHeaderNewTitle>
          {data?.showChartWarning && <ChartDataWarning />}
          <CardHeaderActions>
            <IconButton data-testid="showTargetAgainstFeatureModal" onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="targetAgainstFeature"
            data={data?.chartData || null}
            isLoading={status === 'loading'}
            layout={{
              ...sharedChartLayout,
              height,
              width,
            }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              {chartTitles[financialModel] || chartTitles.default} Against <Pill>{selectedFeature}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="targetAgainstFeatureModal"
              data={data?.chartData || null}
              isLoading={status === 'loading'}
              layout={sharedChartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default TargetAgainstFeature;
