import { chartHoverLabelAttributes } from 'utils';

type Props = {
  color: string;
  data: {
    x: (string | number)[];
    y: (string | number)[];
    text: number[];
  };
};

export type FigureType = {
  marker: {
    color: string;
  };
  opacity: number;
  text: number[];
  textposition: string;
  type: string;
  x: (string | number)[];
  y: (string | number)[];
  orientation: string;
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
  hovertemplate: string;
};

export type TargetShape = FigureType[];

const formatToStockChart = ({ data, color }: Props): TargetShape => {
  const figure = {
    marker: {
      color,
    },
    orientation: 'h',
    opacity: 0.75,
    textposition: 'outside',
    text: data.text,
    x: data.x,
    y: data.y,
    type: 'bar',
    hoverlabel: chartHoverLabelAttributes,
    hovertemplate: '%{y}<br>Total Return: %{x:.2f}<extra></extra>',
  };

  return [figure];
};

export default formatToStockChart;
