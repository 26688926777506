import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0 1.75rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
`;

export const HeaderWrapperButton = styled.div`
  margin-left: auto;
`;

export const ThWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Resizer = styled.div<{ isResizing: boolean }>`
  display: inline-block;
  background-color: ${({ isResizing }) => (isResizing ? 'rgb(0 0 0 / 30%)' : 'rgb(0 0 0 / 10%)')};
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  ${'' /* prevents from scrolling while dragging on touch devices */}
  touch-action:none;
`;

export const THText = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;

  padding-top: 0;
  padding-bottom: 0;

  font-size: 14px;

  svg {
    width: 7px;
    margin-right: 5px;
  }
`;

export const LinkSpan = styled.span`
  color: #0e5ca7;
  cursor: pointer;
`;

export const Span = styled.span``;

export const TableWrapper = styled.div`
  padding: 1.75rem;
`;

export const StyledTable = styled.div<{ withOverflow: boolean }>`
  ${({ withOverflow }) => {
    if (withOverflow) {
      return `
        overflow-x: auto;
      `;
    }
  }}

  width: 100%;
  display: inline-block;
  border-spacing: 0;
  position: relative;
  overflow-y: scroll;
  font-size: 14px;

  .right {
    text-align: right;
  }

  .tr,
  .trOdd {
    display: flex;
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .trOdd {
    background-color: #f0f6f7;
  }

  .th {
    font-weight: bold;
    text-transform: capitalize;
    background-color: #7bb2e7;
    color: white;
    padding-top: 4px;
    padding-bottom: 5px;
  }

  .td {
    height: 25px;
    line-height: 25px;
  }

  .th,
  .td {
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-right: 2px solid #e2eaf2;
    overflow-x: scroll;

    :last-child {
      border-right: none;
    }
  }
`;

export const PageCount = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Pagination = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  padding-top: 3rem;
`;
