import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generate } from 'shortid';

import { RootState } from 'store';

interface Attribution {
  date: string;
  sortBy: string;
  region: string;
  sector: string;
  view: string;
}
export type PerformanceDate = null | { date: string; index: number };

export interface OptimisationState {
  id: string;
  inProgress: boolean;
  attribution: Attribution;
  performanceDate: PerformanceDate;
  feature: string;
}

const initialState: OptimisationState = {
  id: '',
  inProgress: true,
  attribution: { date: '', sortBy: 'top_contributions', region: '', sector: '', view: 'regional' },
  performanceDate: null,
  feature: '',
};

export const optimisationSlice = createSlice({
  name: 'optimisation',
  initialState,
  reducers: {
    setOptimisationPerformanceDate: (state, action: PayloadAction<PerformanceDate>) => {
      state.performanceDate = action.payload;
    },
    setClearOptimisationPerformanceDate: (state) => {
      state.performanceDate = null;
    },
    setOptimisationPortfolioConstruction: (state) => {
      state.id = generate();
      state.inProgress = true;
      state.attribution = initialState.attribution;
      state.performanceDate = null;
      state.feature = initialState.feature;
    },
    setOptimisationConstructPortfolioStatus: (state, action: PayloadAction<boolean>) => {
      state.inProgress = action.payload;
    },
    setOptimisationFeature: (state, action: PayloadAction<string>) => {
      state.feature = action.payload;
    },
    setOptimisationAttributionDate: (state, action: PayloadAction<string>) => {
      state.attribution.date = action.payload;
    },
    setOptimisationAttributionRegion: (state, action: PayloadAction<string>) => {
      state.attribution.region = action.payload;
    },
    setOptimisationAttributionSector: (state, action: PayloadAction<string>) => {
      state.attribution.sector = action.payload;
    },
    setOptimisationAttributionView: (state, action: PayloadAction<string>) => {
      state.attribution.view = action.payload;
    },
    setOptimisationAttributionSortby: (state, action: PayloadAction<string>) => {
      state.attribution.sortBy = action.payload;
    },
  },
});

export const {
  setOptimisationPerformanceDate,
  setOptimisationConstructPortfolioStatus,
  setClearOptimisationPerformanceDate,
  setOptimisationPortfolioConstruction,
  setOptimisationFeature,
  setOptimisationAttributionDate,
  setOptimisationAttributionRegion,
  setOptimisationAttributionSector,
  setOptimisationAttributionView,
  setOptimisationAttributionSortby,
} = optimisationSlice.actions;

export const selectOptimisationInProgress = (state: RootState): boolean => state.optimisation.inProgress;
export const selectOptimisationId = (state: RootState): string => state.optimisation.id;
export const selectOptimisationPerformanceDate = (state: RootState): PerformanceDate =>
  state.optimisation.performanceDate;
export const selectOptimisationFeature = (state: RootState): string => state.optimisation.feature;
export const selectOptimisationAttribution = (state: RootState): Attribution => state.optimisation.attribution;

export default optimisationSlice.reducer;
