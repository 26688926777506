import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from 'theme';

export const Spacer = styled.div`
  padding: 1rem;
  height: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const MediumWrapper = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

export enum CardHeight {
  Half = 'calc((100vh - 13.5rem) / 2)',
  Full = 'calc(100vh - 11.5rem)',
}

export enum ChartHeight {
  Half = 'calc((100vh - 20.5rem) / 2)',
  Full = 'calc(100vh - 20.5rem)',
}

export const Card = styled.div<{ cardHeight?: CardHeight }>`
  position: relative;
  background-color: white;
  border-radius: 0.25rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  height: ${({ cardHeight }) => (cardHeight ? cardHeight : '100%')};

  ${({ cardHeight }) => {
    if (cardHeight === CardHeight.Half) {
      return `
        height: ${CardHeight.Half};
        min-height: 300px;
      `;
    }

    if (cardHeight === CardHeight.Full) {
      return `
      height: ${CardHeight.Full};
      min-height: 600px;
      `;
    }

    return `
      height: 100%;
    `;
  }};
`;

export const ErrorCard = styled.div`
  position: relative;
  background-color: white;
  border-radius: 0.25rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  button {
    max-width: 10rem;
  }
`;

// Deprecate
export const CardHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 4rem;
`;

export const CardHeaderNew = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;
  padding: 0 1.75rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
`;

export const CardHeaderActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const SpacerTop = styled.div`
  margin-bottom: 1rem;
`;
export const SpacerRight = styled.div`
  margin-right: 1rem;
`;

export const CenterAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100%;
`;

export const CenterComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;

export const CenterColumnChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  height: 100%;
`;

export const DateWrap = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3rem;
  background-color: #e1f3ff;
  padding: 0.25rem 0.5rem;
`;

export const FilterWrapperStyle = styled.div`
  box-sizing: border-box;
  width: 300px;
  display: flex;
  flex-direction: column;
  background: red;
`;

export const FilterOptionsStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GreyPill = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: ${(props) => props.theme.colour.lightGrey};
  border-radius: 1rem;
  margin-right: 0.5rem;
`;

export const TableFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: auto;
  box-sizing: border-box;
  position: relative;
  background: red;
  width: 200px;
`;

export const H1 = styled.h1`
  margin: 0;
`;

export const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderOptionsCol = styled.div`
  & + & {
    margin-left: 0.7rem;
  }
`;

export const Header = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Col50 = styled.div`
  width: 49.5%;
`;

export const Column = styled.div<{ span: 'half' | 'full' | 'third' }>`
  width: ${({ span }) => {
    if (span === 'half') {
      return 'calc((100% - 1.75rem)/2)';
    }

    if (span === 'third') {
      return 'calc((100% - 3.5rem)/3)';
    }

    return '100%';
  }};

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const Pill = styled.span`
  display: none;

  @media screen and (min-width: ${theme.breakpoints.mobile}) {
    display: flex;
    background-color: #e6e6e6;
    border-radius: 1rem;
    padding: 0.25rem 0.4rem;
    margin-left: 0.5rem;
    align-items: center;
  }
`;

export const CardHeaderNewTitle = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const ChartWrapper = styled.div<{ chartHeight?: ChartHeight }>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding: 1.75rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  ${({ chartHeight }) => {
    if (chartHeight === ChartHeight.Half) {
      return `
        height: ${ChartHeight.Half};
        min-height: 250px;
      `;
    }

    if (chartHeight === ChartHeight.Full) {
      return `
      height: ${ChartHeight.Full};
      min-height: 500px;
      `;
    }

    return `
      height: 375px;
    `;
  }};
`;

export const Toggle = styled.div`
  display: flex;
  width: 400px;
  border-radius: 4rem;
  overflow: hidden;
`;

export const ToggleButton = styled(NavLink)`
  width: 50%;
  background-color: white;
  color: #000;
  border: none;
  text-transform: uppercase;
  padding: 0.3rem 0;
  outline: none;
  focus: none;
  text-align: center;
  cursor: pointer;

  &.selected,
  &.selected:hover {
    background-color: #7ab1e8;
    color: white;
    cursor: disabled;
  }
`;

export const CardTip = styled.p`
  height: 3rem;
  margin: 0;
  text-align: center;
  width: 100%;
`;

export const IconWrap = styled.span`
  display: flex;
  align-items: center;

  svg {
    width: 23px;
    height: 13px;
  }
`;

export const LoaderMessage = styled.span`
  color: #888;
  padding: 0.7rem 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 11px;
`;

export const CenterText = styled.div`
  text-align: center;
`;

export const ModalChartWrap = styled.div`
  height: calc(100% - 3.5rem);
  width: 100%;
  padding: 3.5rem;
  text-transform: uppercase;
`;

export const DownloadCsvButtonStyle = styled.div`
  min-width: unset;
  padding: 0;
  background: none;
  border-radius: 0;
  border: none;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }

    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    fill: ${({ theme }) => theme.colors.darkGrey};

    &:hover {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const DownloadButtonLoaderStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    height: 3px;
    display: flex;
    width: 21px;
    position: relative;
    .circle {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.darkGrey};
      animation: move 500ms linear 0ms infinite;
      margin-right: 6px;

      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
        animation: grow 500ms linear 0ms infinite;
      }

      &:last-child {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        animation: grow 500ms linear 0s infinite reverse;
      }
    }
  }

  @keyframes grow {
    from {
      transform: scale(0, 0);
      opacity: 0;
    }
    to {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes move {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(18px);
    }
  }
`;

export const IconButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  background: none;
  appearance: none;
  font-size: 0.85rem;
  svg {
    fill: ${theme.colors.darkGrey};
  }
  &:hover {
    svg {
        fill: ${theme.colors.primary};
      }
    }
  }
`;

export const CenterCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`;
