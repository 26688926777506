import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { api, findColConfigLong, useDivSize } from 'utils';
import { chartMargin } from 'utils/useDivSize';

import { CardLoader, TableLong } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  Pill,
  CardTip,
  CardHeaderActions,
} from 'Common/Shared.styles';

import { ConstituentReturns as Props, PortfolioType } from '../LayoutBuilder';
import { DownloadCsvButton, AttrConstituentReturnsParams } from 'Common/CsvButton/CsvButton';
import { FinancialModels } from 'types/shared.types';
import Select from 'Common/Select';

type FetchTableData = {
  financialModel: FinancialModels;
  portfolio: string;
  portfolioType: PortfolioType;
  signal?: AbortSignal;
};

const fetchMLOrManualData = async ({ financialModel, portfolio, portfolioType, signal }: FetchTableData) => {
  const { data } = await api.get('/components/attribution_constituent_returns', {
    params: {
      financial_model: financialModel,
      portfolio_dropdown: portfolio,
      portfolio_type: portfolioType,
    },
    signal,
  });

  return {
    columns: data.columns.map((x: string, i: number) => findColConfigLong(x, i)),
    rows: data.rows,
  };
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const ConstituentReturns = ({
  id,
  financialModel,
  portfolio,
  presentation,
  portfolioType,
  isPageLoading,
  cacheKey,
  portfolioDropdownOptions = [],
  onPortfolioSelect,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { width } = useDivSize(parentRef);
  const payload = { financialModel, portfolio, portfolioType };
  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, { payload }],
    ({ signal }) => fetchMLOrManualData({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Constituent Returns</Spacer>
        </Card>
      </Column>
    );
  }

  const downloadCsvProps = {
    fetchData: {
      url: '/components/attribution_constituent_returns',
      params: {
        financial_model: financialModel,
        portfolio_dropdown: portfolio,
        portfolio_type: portfolioType,
        output: 'csv',
      } as AttrConstituentReturnsParams,
    },
    testId: 'downloadPortfolioConstituentsReturnTable',
    filename: `constituentReturns.csv`,
    cacheKey: `${financialModel}${portfolio}${portfolioType}`,
  };

  return (
    <Column span={presentation.span} data-testid="constituentReturns" ref={parentRef}>
      {isPageLoading || isLoading ? (
        <Card data-testid="portfolioConstituentsReturnTableLoader">
          <LoaderWrapper>
            <CardLoader />
          </LoaderWrapper>
        </Card>
      ) : (
        <Card>
          {hasPortfolio ? (
            <>
              <CardHeaderNew>
                <CardHeaderNewTitle>
                  Portfolio Constituent Returns
                  <Pill>{portfolio}</Pill>
                </CardHeaderNewTitle>

                <CardHeaderActions>
                  {portfolioDropdownOptions.length > 1 && onPortfolioSelect && (
                    <Select
                      name="changePortfolio"
                      id="changePortfolio"
                      testId="changePortfolio"
                      isControlled={true}
                      options={portfolioDropdownOptions}
                      value={portfolio}
                      onChange={(event) => onPortfolioSelect(event.target.value)}
                    />
                  )}

                  {!isPageLoading && <DownloadCsvButton {...downloadCsvProps} />}
                </CardHeaderActions>
              </CardHeaderNew>

              <TableLong
                id="portfolioConstituentsReturnTable"
                columns={data?.columns || []}
                data={data?.rows || []}
                maxRowsToDisplay={10}
                isPaginated={true}
                width={width - chartMargin}
                withColumnShowHide={false}
              />
            </>
          ) : (
            <CardTip>
              To see portfolio constituents
              <br />
              please select a <strong>portfolio</strong>
            </CardTip>
          )}
        </Card>
      )}
    </Column>
  );
};

export default ConstituentReturns;
