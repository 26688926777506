import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

type Props = {
  testId?: string;
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

if (typeof window !== 'undefined') {
  ReactModal.setAppElement('body');
}

const ModalContainer = styled.div`
  height: 100%;
`;

const Modal = ({ testId, children, isOpen, onClose }: Props): ReactElement => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
      style={{
        content: { height: '80%', width: '75%', position: 'relative', padding: 0 },
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10,
          padding: 0,
        },
      }}
    >
      <ModalContainer data-testid={testId || 'modal'}>{children}</ModalContainer>
    </ReactModal>
  );
};
export default Modal;
