import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchOptimisationOptions } from 'App/Optimisation/handleFetchOptimisationOptions';
import { generate } from 'shortid';

import { RootState } from 'store';

export interface FeatureProps {
  id: string;
  index: number;
}
interface InvestmentPeriod {
  startIndex: number;
  endIndex: number;
}

export interface ChangeFilterBoundProps {
  index: number;
  input: {
    lower_bound: number;
    upper_bound: number;
  };
}
export interface OnChangeConstraintValue {
  index: number;
  value: number;
}
export interface ConstraintOption {
  id: string;
  input: {
    value: number;
  };
}

export enum DirectionInputValue {
  MINIMISE = '<=',
  MAXIMISE = '>=',
}

export interface OnChangeObjectiveDirectionProps {
  index: number;
  direction: DirectionInputValue;
}

export interface OptimisationOption {
  id: string;
  input: {
    direction: DirectionInputValue;
  };
}
export interface FilterOption {
  id: string;
  input: {
    lower_bound: number;
    upper_bound: number;
  };
}

export type PerformanceDate = null | { date: string; index: number };

export interface OptimisationNewState {
  id: string;
  inProgress: boolean;
  selectedInvestmentPeriod: null | InvestmentPeriod;
  performanceDate: PerformanceDate;
  constraints: ConstraintOption[];
  filters: FilterOption[];
  features: OptimisationOption[];
}

const defaultObjective: OptimisationOption = {
  id: '',
  input: { direction: DirectionInputValue.MAXIMISE },
};

const defaultFilter: FilterOption = {
  id: '',
  input: { lower_bound: NaN, upper_bound: NaN },
};

const defaultConstraint: ConstraintOption = {
  id: '',
  input: { value: NaN },
};

const initialState: OptimisationNewState = {
  id: '',
  inProgress: true,
  selectedInvestmentPeriod: null,
  performanceDate: null,
  constraints: [defaultConstraint],
  filters: [defaultFilter],
  features: [defaultObjective],
};

export const optimisationNewSlice = createSlice({
  name: 'optimisationNew',
  initialState,
  reducers: {
    setNewOptimisation: (state) => {
      state.id = generate();
      state.inProgress = true;
    },
    setNewOptimisationInvestmentPeriod: (state, action: PayloadAction<InvestmentPeriod>) => {
      state.selectedInvestmentPeriod = action.payload;
    },
    setOptimisationValues: (state, action: PayloadAction<FetchOptimisationOptions>) => {
      state.selectedInvestmentPeriod = action.payload.selectedDateRange;
      state.inProgress = action.payload.inProgress;
      state.features = action.payload.options
        ? action.payload.options.available_features
            .filter((i) => i.input_type === 'direction' && i.input.value)
            .map((i) => ({ id: i.id, input: { direction: i.input.value as DirectionInputValue } }))
        : [];
      state.constraints = action.payload.options
        ? action.payload.options.settings
            .filter((i) => i.input_type === 'range' && i.input.value)
            .map((i) => ({ id: i.id, input: { value: i.input.value as number } }))
        : [];

      state.filters = action.payload.options
        ? action.payload.options.factors_to_filter_by.map((i) => ({
            id: i.id,
            input: { lower_bound: i.input.lower_bound as number, upper_bound: i.input.upper_bound as number },
          }))
        : [];
    },
    setNewOptimisationPerformanceDate: (state, action: PayloadAction<PerformanceDate>) => {
      state.performanceDate = action.payload;
    },
    setClearNewOptimisationPerformanceDate: (state) => {
      state.performanceDate = null;
    },
    setOnChangeFilter: (state, action: PayloadAction<FeatureProps>) => {
      state.filters = state.filters.map((item, idx) => {
        return idx === action.payload.index ? { ...item, id: action.payload.id } : item;
      });
    },
    setOnRemoveFilter: (state, action: PayloadAction<{ index: number }>) => {
      state.filters = state.filters.filter((_, idx) => {
        return idx !== action.payload.index;
      });
    },
    setOnChangeFilterBounds: (state, action: PayloadAction<ChangeFilterBoundProps>) => {
      state.filters = state.filters.map((item, idx) => {
        return idx === action.payload.index ? { ...item, input: action.payload.input } : item;
      });
    },
    setOnChangeObjectiveFeature: (state, action: PayloadAction<FeatureProps>) => {
      state.features = state.features.map((item, idx) => {
        return idx === action.payload.index ? { ...item, id: action.payload.id } : item;
      });
    },
    setOnRemoveObjectiveFeature: (state, action: PayloadAction<{ index: number }>) => {
      state.features = state.features.filter((_, idx) => {
        return idx !== action.payload.index;
      });
    },
    setOnChangeObjectiveDirection: (state, action: PayloadAction<OnChangeObjectiveDirectionProps>) => {
      state.features = state.features.map((item, idx) => {
        return idx === action.payload.index ? { ...item, input: { direction: action.payload.direction } } : item;
      });
    },
    setOnChangeConstraintFeature: (state, action: PayloadAction<FeatureProps>) => {
      state.constraints = state.constraints.map((item, idx) => {
        return idx === action.payload.index ? { ...item, id: action.payload.id } : item;
      });
    },
    setOnRemoveConstraint: (state, action: PayloadAction<{ index: number }>) => {
      state.constraints = state.constraints.filter((_, idx) => {
        return idx !== action.payload.index;
      });
    },
    setOnChangeConstraintValue: (state, action: PayloadAction<OnChangeConstraintValue>) => {
      state.constraints = state.constraints.map((item, idx) => {
        return idx === action.payload.index ? { ...item, input: { value: action.payload.value } } : item;
      });
    },
    setOnAddFeature: (state) => {
      state.features = [...state.features, defaultObjective];
    },
    setOnAddConstraints: (state) => {
      state.constraints = [...state.constraints, defaultConstraint];
    },
    setOnAddFilters: (state) => {
      state.filters = [...state.filters, defaultFilter];
    },
  },
});

export const {
  setClearNewOptimisationPerformanceDate,
  setNewOptimisation,
  setNewOptimisationInvestmentPeriod,
  setNewOptimisationPerformanceDate,
  setOnAddConstraints,
  setOnAddFeature,
  setOnAddFilters,
  setOnChangeConstraintFeature,
  setOnChangeConstraintValue,
  setOnChangeFilter,
  setOnChangeFilterBounds,
  setOnChangeObjectiveDirection,
  setOnChangeObjectiveFeature,
  setOnRemoveConstraint,
  setOnRemoveFilter,
  setOnRemoveObjectiveFeature,
  setOptimisationValues,
} = optimisationNewSlice.actions;

export const selectNewOptimisationId = (state: RootState): string => state.optimisationNew.id;
export const selectNewOptimisationStatus = (state: RootState): boolean => state.optimisationNew.inProgress;
export const selectNewOptimisationInvestmentPeriod = (state: RootState): null | InvestmentPeriod =>
  state.optimisationNew.selectedInvestmentPeriod;
export const selectNewOptimisationPerformanceDate = (state: RootState): PerformanceDate =>
  state.optimisationNew.performanceDate;
export const selectNewOptimisationFeatures = (state: RootState): OptimisationOption[] => state.optimisationNew.features;
export const selectNewOptimisationConstraints = (state: RootState): ConstraintOption[] =>
  state.optimisationNew.constraints;
export const selectNewOptimisationFilters = (state: RootState): FilterOption[] => state.optimisationNew.filters;

export default optimisationNewSlice.reducer;
