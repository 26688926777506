import { RefObject, useEffect, useState } from 'react';

export const chartMargin = -56;

export default function useDivSize(ref: RefObject<HTMLDivElement>): { width: number; height: number } {
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(300);

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        setHeight(ref.current.offsetHeight + chartMargin);
        setWidth(ref.current.offsetWidth + chartMargin);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function handleMouseDown() {
      if (ref.current) {
        setHeight(ref.current.offsetHeight + chartMargin);
        setWidth(ref.current.offsetWidth + chartMargin);
      }
    }

    window.addEventListener('click', handleMouseDown);
    return () => window.removeEventListener('click', handleMouseDown);
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight + chartMargin);
      setWidth(ref.current.offsetWidth + chartMargin);
    }
  }, [ref]);

  return { height, width };
}
