// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Error = any;

const isUnauthenticated = (error: Error): boolean => {
  const message: string = error && error.message;

  return message === 'Request failed with status code 401';
};

export default isUnauthenticated;
