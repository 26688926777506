import React, { ReactElement } from 'react';
import styled from 'styled-components';

const LoaderStyle = styled.div<{ isSmall: boolean; color?: string }>`
  display: inline-block;
  position: relative;

  ${({ isSmall }) => {
    if (isSmall) {
      return `
        width: 10px;
        height: 10px;
      `;
    }

    return `
      width: 80px;
      height: 80px;
    `;
  }};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;

    ${({ isSmall }) => {
      if (isSmall) {
        return `
          width: 8px;
          height: 8px;
          margin: 1px;
          border: 2px solid #fff;
        `;
      }

      return `
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
      `;
    }};

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) =>
      color ? `${color} transparent transparent transparent` : `#fff transparent transparent transparent`};
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  size?: 'small';
  testId?: string;
  color?: string;
};

const Loader = ({ size, testId = 'loader', color }: Props): ReactElement => (
  <LoaderStyle data-testid={testId} isSmall={size === 'small'} color={color}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoaderStyle>
);

export default Loader;
