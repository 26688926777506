import theme from 'theme';

export const listItemStyle = `
border-bottom: ${`2px solid ${theme.colors.lightGrey}`};
border-top: ${`2px solid ${theme.colors.lightGrey}`};
border-left: ${`4px solid ${theme.colors.white}`};
background: ${theme.colors.white};

&:hover {
  background: ${theme.colors.lightGrey};
  border-left: ${`4px solid ${theme.colors.primary}`};
}
`;
