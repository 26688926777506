type Props = {
  features: string[];
  f25: number[];
  f50: number[];
  f75: number[];
  color25: string[];
};

type Chart = {
  marker: {
    color: string | string[];
  };
  opacity: number;
  text: string;
  textposition: string;
  x: string[];
  y: number[];
  type: string;
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
  hovertemplate: string;
};
type TargetShape = Chart[];

const sharedValues = {
  marker: {
    color: 'blue',
  },
  opacity: 0.6,
  text: 'q1',
  textposition: 'outside',
  type: 'bar',
  hoverlabel: {
    bgcolor: '#ffec9f',
    bordercolor: '#333333',
    font: {
      color: '#333333',
    },
  },
};

const formatToBarchartShape = ({ color25, features, f25, f50, f75 }: Props): TargetShape => {
  const q2 = f50.map((x, i) => x - f25[i]);
  const q3 = f75.map((x, i) => x - f50[i]);

  const feature25 = {
    ...sharedValues,
    marker: {
      color: color25,
    },
    text: 'q1',
    hovertemplate: '%{x}: %{customdata:.2f}<br>Quartile 1<extra></extra>',
    textposition: 'outside',
    x: features,
    y: f25,
    customdata: f25,
  };
  const feature50 = {
    ...sharedValues,
    hovertemplate: '%{x}: %{customdata:.2f}<br>Quartile 2<extra></extra>',
    text: 'q2',
    x: features,
    y: q2,
    customdata: f50,
  };

  const feature75 = {
    ...sharedValues,
    text: 'q3',
    hovertemplate: '%{x}: %{customdata:.2f}<br>Quartile 3<extra></extra>',
    x: features,
    y: q3,
    customdata: f75,
  };

  return [feature25, feature50, feature75];
};

export default formatToBarchartShape;
