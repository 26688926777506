import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface FeatureEngineeringOption {
  id: string;
  label: string;
  value: string;
  isChecked: boolean;
}

export interface FeatureEngineeringState {
  options: FeatureEngineeringOption[];
}

const initialState: FeatureEngineeringState = {
  options: [],
};

export const featureEngineeringSlice = createSlice({
  name: 'featureEngineering',
  initialState,
  reducers: {
    setFeatureEngineeringOptions: (state, action: PayloadAction<FeatureEngineeringOption[]>) => {
      state.options = action.payload;
    },
    setFeatureEngineeringOptionsChecked: (state, action: PayloadAction<FeatureEngineeringOption[]>) => {
      state.options = action.payload.map((i: FeatureEngineeringOption) => ({ ...i, isChecked: true }));
    },
    setFeatureEngineeringOptionsUnChecked: (state, action: PayloadAction<FeatureEngineeringOption[]>) => {
      state.options = action.payload.map((i: FeatureEngineeringOption) => ({ ...i, isChecked: false }));
    },
  },
});

export const {
  setFeatureEngineeringOptions,
  setFeatureEngineeringOptionsChecked,
  setFeatureEngineeringOptionsUnChecked,
} = featureEngineeringSlice.actions;

export const selectFeatureEngineeringOptions = (state: RootState): FeatureEngineeringOption[] =>
  state.featureEngineering.options;

export default featureEngineeringSlice.reducer;
