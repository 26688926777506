import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';

import Checkbox from '../Checkbox';
import Button from '../Button';

import FilterSearchHeader from '../FilterSearchHeader';
import { FilterWrapperStyle, GreyPill, FilterOptionsStyle } from '../Shared.styles';

const Spacer = styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

type Option = {
  label: string;
  value: string | number;
  isChecked: boolean;
};

type Props = {
  id: string;
  options: Option[];
  onChange: (arg: Option[]) => void;
  onClose: () => void;
  onSubmit: () => void;
};

const fuseOptions = {
  shouldSort: true,
  keys: ['label'],
};

const FilterOptions = ({ id, options, onChange, onClose, onSubmit }: Props): ReactElement => {
  const [searchText, setSearchText] = useState('');

  const handleChange = (value: string) => {
    const withUpdatedItem = options.map((o) => {
      if (o.value === value) {
        return {
          ...o,
          isChecked: !o.isChecked,
        };
      }

      return o;
    });

    onChange(withUpdatedItem);
  };

  const handleOnClose = () => {
    setSearchText('');
    onClose();
  };

  const filteredItems = searchText
    ? new Fuse(options, fuseOptions)
        .search(searchText)
        .map(({ item: { label, value, isChecked } }) => ({ label, value, isChecked }))
    : options;

  return (
    <FilterWrapperStyle data-testid={id}>
      <FilterSearchHeader
        testId={`${id}Filter`}
        onSearch={(text) => setSearchText(text)}
        onClear={() => setSearchText('')}
        onClose={handleOnClose}
        searchText={searchText}
      />
      <FilterOptionsStyle>
        {filteredItems.length === 0
          ? 'No matches…'
          : filteredItems.map((i) => (
              <GreyPill key={i.value}>
                <Checkbox
                  id={String(i.value)}
                  isChecked={i.isChecked}
                  label={i.label}
                  onChange={handleChange}
                  value={i.value}
                />
              </GreyPill>
            ))}
      </FilterOptionsStyle>
      <Spacer>
        <Button type="button" onClick={onSubmit} testId="updateFilters">
          Update
        </Button>
      </Spacer>
    </FilterWrapperStyle>
  );
};

export default FilterOptions;
