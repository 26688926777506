import React, { useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { Button, Text } from 'Common';
import {
  Wrapper,
  Label,
  Input,
  SwitchViewButton,
  StyledForm,
  PasswordContainer,
  PasswordInput,
  VisibilityButton,
} from '../SignIn.styles';
import theme from 'theme';

import { ReactComponent as InvisibilityIcon } from 'icons/invisibility.svg';
import { ReactComponent as VisibilityIcon } from 'icons/visibility.svg';
import FirstLogin from '../FirstLogin';
import { IS_AUTHED_KEY } from 'utils/useIsAuthed/useIsAuthed';

interface LoginFormProps {
  switchView: () => void;
}

interface SignInProps {
  email: string;
  password: string;
}

interface ErrorProps {
  message: string;
}

const LoginForm = ({ switchView }: LoginFormProps): ReactElement => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [user, setUser] = useState<{ cognito: CognitoUser; isFirstLogin: boolean }>();

  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSignIn = async ({ email, password }: SignInProps) => {
    const user = await Auth.signIn(email, password);
    return user;
  };

  const signInMutation = useMutation(handleSignIn, {
    onError: (error: ErrorProps) => {
      console.log('Error signing in', error);
      setValid(false);
    },
    onSuccess: async (user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser({ cognito: user, isFirstLogin: true });
      } else {
        await queryClient.fetchQuery(IS_AUTHED_KEY);
        history.push('/manage-dataset');
      }
    },
  });

  return user && user.isFirstLogin ? (
    <FirstLogin user={user} />
  ) : (
    <StyledForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        signInMutation.mutate({ email, password });
      }}
    >
      <Wrapper>
        <Label>Email Address</Label>
        <Input
          type="text"
          name="email"
          value={email}
          onChange={(e) => updateEmail(e)}
          autoComplete="off"
          autoCapitalize="off"
        />
      </Wrapper>
      <Wrapper>
        <Label>Password</Label>
        <PasswordContainer>
          <PasswordInput
            type={isVisible ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={(e) => updatePassword(e)}
            autoComplete="off"
            autoCapitalize="off"
          />
          <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
            {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        </PasswordContainer>
        <SwitchViewButton unStyled onClick={switchView}>
          Forgot Password
        </SwitchViewButton>
      </Wrapper>

      {!valid && signInMutation.error && (
        <Text textType="p" color={theme.colors.red} align="right" text={signInMutation.error.message} />
      )}

      <Button
        theme="primary"
        size="large"
        type="submit"
        isDisabled={!email || !password || signInMutation.isLoading}
        isLoading={signInMutation.isLoading}
      >
        Login
      </Button>
    </StyledForm>
  );
};

export default LoginForm;
