import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generate } from 'shortid';

import { RootState } from 'store';

interface Attribution {
  date: string;
  sortBy: string;
  region: string;
  sector: string;
  view: string;
}
export type PerformanceDate = null | { date: string; index: number };

export interface ScreeningState {
  id: string;
  portfolio: string;
  numericFeature: string;
  isPortfolioConstructionInProgress: boolean;
  portfolioMetricsFilter: string;
  attribution: Attribution;
  performanceDate: PerformanceDate;
  rebalancingFrequency: number;
  allocationType: string;
}

const initialState: ScreeningState = {
  id: '',
  portfolio: '',
  numericFeature: '',
  isPortfolioConstructionInProgress: true,
  portfolioMetricsFilter: '',
  performanceDate: null,
  attribution: { date: '', sortBy: 'top_contributions', region: '', sector: '', view: 'regional' },
  rebalancingFrequency: 1,
  allocationType: 'market_cap_weighted',
};

export const screeningSlice = createSlice({
  name: 'screening',
  initialState,
  reducers: {
    setConstructionStatus: (state, action: PayloadAction<boolean>) => {
      state.isPortfolioConstructionInProgress = action.payload;
    },
    setPortfolio: (state, action: PayloadAction<string>) => {
      state.portfolio = action.payload;
    },
    setPorfolioMetricsId: (state, action: PayloadAction<string>) => {
      state.portfolioMetricsFilter = action.payload;
      state.portfolio = action.payload;
    },
    setClearPorfolioMetricsId: (state) => {
      state.portfolioMetricsFilter = '';
    },
    setPerformanceDate: (state, action: PayloadAction<PerformanceDate>) => {
      state.performanceDate = action.payload;
    },
    setClearPerformanceDate: (state) => {
      state.performanceDate = null;
    },
    setPortfolioConstruction: (state) => {
      state.id = generate();
      state.isPortfolioConstructionInProgress = true;
      state.portfolio = '';
      state.numericFeature = '';
      state.isPortfolioConstructionInProgress = true;
      state.portfolioMetricsFilter = '';
      state.performanceDate = null;
      state.attribution = { date: '', sortBy: 'top_contributions', region: '', sector: '', view: 'regional' };
    },
    setNumericFeature: (state, action: PayloadAction<string>) => {
      state.numericFeature = action.payload;
    },
    setAttributionDate: (state, action: PayloadAction<string>) => {
      state.attribution.date = action.payload;
    },
    setAttributionSortBy: (state, action: PayloadAction<string>) => {
      state.attribution.sortBy = action.payload;
    },
    setAttributionView: (state, action: PayloadAction<string>) => {
      state.attribution.view = action.payload;
    },
    setAttributionRegion: (state, action: PayloadAction<string>) => {
      state.attribution.region = action.payload;
    },
    setAttributionSector: (state, action: PayloadAction<string>) => {
      state.attribution.sector = action.payload;
    },
    setRebalancingFrequency: (state, action: PayloadAction<number>) => {
      state.rebalancingFrequency = action.payload;
    },
    setAllocationType: (state, action: PayloadAction<string>) => {
      state.allocationType = action.payload;
    },
  },
});

export const {
  setConstructionStatus,
  setPorfolioMetricsId,
  setPortfolio,
  setPerformanceDate,
  setClearPerformanceDate,
  setClearPorfolioMetricsId,
  setPortfolioConstruction,
  setNumericFeature,
  setAttributionDate,
  setAttributionSortBy,
  setAttributionView,
  setAttributionRegion,
  setAttributionSector,
  setRebalancingFrequency,
  setAllocationType,
} = screeningSlice.actions;

export const selectConstructionStatus = (state: RootState): boolean =>
  state.screening.isPortfolioConstructionInProgress;
export const selectScreeningId = (state: RootState): string => state.screening.id;
export const selectPortfolio = (state: RootState): string => state.screening.portfolio;
export const selectPortfolioMetricsFilter = (state: RootState): string => state.screening.portfolioMetricsFilter;
export const selectPerformanceDate = (state: RootState): PerformanceDate => state.screening.performanceDate;
export const selectNumericFeature = (state: RootState): string => state.screening.numericFeature;
export const selectAttribution = (state: RootState): Attribution => state.screening.attribution;
export const selectRebalancingFrequency = (state: RootState): number => state.screening.rebalancingFrequency;
export const selectAllocationType = (state: RootState): string => state.screening.allocationType;

export default screeningSlice.reducer;
