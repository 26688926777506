import React, { ReactElement, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

const MoreDetail = styled.div`
  a {
    color: white;
    text-decoration: underline;
  }

  p {
    font-size: 13px;
    line-height: 1.2rem;
  }
`;

const MoreDetailButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  text-decoration: underline;
  outline: none;
`;

const CookiePolicy = (): ReactElement => {
  const [isMoreVisible, setIsMoreVisible] = useState(false);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Yes, I’m Happy"
      cookieName="cookieConsent"
      style={{ background: '#121827', margin: 0, padding: '0 15%', boxSizing: 'border-box' }}
      buttonStyle={{
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '1rem',
        fontSize: '13px',
        outline: 'none',
      }}
      expires={999}
    >
      <p>
        This website uses cookies to enhance the user experience.
        <MoreDetailButton type="button" onClick={() => setIsMoreVisible(!isMoreVisible)}>
          {isMoreVisible ? 'Tell me less' : 'Tell me more'}
        </MoreDetailButton>
      </p>

      {isMoreVisible && (
        <MoreDetail>
          <p>
            We use Hotjar in order to better understand our users’ needs and to optimize this service and experience.
            Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time
            they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this
            enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies
            to collect data on our users’ behavior and their devices. This includes a device’s IP address (processed
            during your session and stored in a de-identified form), device screen size, device type (unique device
            identifiers), browser information, geographic location (country only), and the preferred language used to
            display our website. Hotjar stores this information on our behalf in a pseudonymized user profile.
          </p>

          <p>Hotjar is contractually forbidden to sell any of the data collected on our behalf.</p>

          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
            >
              More about Hotjar
            </a>
          </p>

          <p>
            <a target="_blank" rel="noreferrer" href="https://insg.ai/cookies/">
              Read more about our Cookie Policy
            </a>
          </p>
        </MoreDetail>
      )}
    </CookieConsent>
  );
};

export default CookiePolicy;
