import React, { ReactElement, useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import { api, findColConfigLong, useDivSize } from 'utils';
import { chartMargin } from 'utils/useDivSize';
import { FinancialModels } from 'types/shared.types';

import { Pagination, PageCount } from 'Common/TableLong/TableLong.styles';
import { TableLong, CardLoader, Button } from 'Common';
import { Card, CardHeaderNew, CardHeaderNewTitle, CardHeaderActions, Column, CardHeight } from 'Common/Shared.styles';

import { FinancialData as Props } from '../LayoutBuilder';

import FilterControl from './FilterControl';
import { CardLoaderAlign } from './FinancialData.styles';

export enum FilterTypes {
  TEXTUAL = 'textual',
  NUMERIC = 'numeric',
}

export interface AvailableFilter {
  key: string;
  type: FilterTypes;
}
export interface TextFilter extends AvailableFilter {
  values: string[];
}

export interface NumericFilter extends AvailableFilter {
  values: { min: number; max: number };
}

export type AvailableFilters = AvailableFilter[];
export type AppliedFilters = (TextFilter | NumericFilter)[];

interface ReturnShape {
  rows: Record<string, unknown>[];
  columns: string[];
  filterOptions: AvailableFilters;
  pagination: {
    current: number;
    total: number;
  };
}

interface FetchTableData {
  financialModel: FinancialModels;
  offset: number;
  filters: AppliedFilters;
  filterId: string;
  signal?: AbortSignal;
}

const fetchTableData = async ({
  financialModel,
  filters = [],
  offset,
  filterId,
}: FetchTableData): Promise<ReturnShape> => {
  const { data } = await api.post('/v2/financial_data', filters, {
    params: {
      financial_model: financialModel,
      offset,
      filter_id: filterId || null,
    },
  });

  const filterOptions = data.filters.numeric_filters
    .map((i: string) => ({ key: i, type: FilterTypes.NUMERIC }))
    .concat(data.filters.textual_filters.map((i: string) => ({ key: i, type: FilterTypes.TEXTUAL })));

  return {
    columns: data.result.columns,
    rows: data.result.rows || [],
    filterOptions,
    pagination: {
      current: data.result.pagination.current_offset,
      total: data.result.pagination.total_offset_count,
    },
  };
};

const FinancialData = ({ id, financialModel, presentation, cacheKey, filterId = '' }: Props): ReactElement => {
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState<AppliedFilters>([]);
  const [fixedHeight, setFixedHeight] = useState<number>();

  const parentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDivSize(parentRef);

  const queryKey = [id, cacheKey, { financialModel, filters, offset, filterId }];
  const unavailableSpace = 18 * 16;
  const handleResize = () => {
    setFixedHeight(window.innerHeight - unavailableSpace);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLoading, data, isError } = useQuery(queryKey, () =>
    fetchTableData({ financialModel, filters, offset, filterId }),
  );

  const memoizedColumns = useMemo(() => {
    if (data) {
      const headers = ['company_name', ...data.columns.filter((x) => x !== 'company_name')];
      return headers.map((x: string, i: number) => findColConfigLong(x, i));
    }
  }, [data]);

  if (isError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  return (
    <Column span={presentation.span}>
      <Card cardHeight={CardHeight.Full} ref={parentRef}>
        <CardHeaderNew>
          <CardHeaderNewTitle>Financial Data</CardHeaderNewTitle>
          {isLoading === false && data?.filterOptions && (
            <CardHeaderActions>
              <FilterControl options={data.filterOptions} appliedFilters={filters} onAdd={setFilters} />
            </CardHeaderActions>
          )}
        </CardHeaderNew>

        {isLoading ? (
          <CardLoaderAlign data-testid="financialDataLoader">
            <CardLoader />
          </CardLoaderAlign>
        ) : (
          <TableLong
            id="dataGroupTable"
            columns={memoizedColumns || []}
            data={data?.rows || []}
            maxRowsToDisplay={40}
            withOverflow={true}
            withTip={true}
            isSortByVisible={false}
            fixedHeight={fixedHeight}
            width={width - chartMargin}
            withColumnShowHide={false}
          />
        )}
        {data && (
          <Pagination width={350}>
            <Button
              withIcon
              type="button"
              onClick={() => setOffset(offset - 1)}
              isDisabled={data?.pagination.current === 0}
            >
              Back
            </Button>

            <PageCount>{`${data?.pagination?.current + 1} of ${data?.pagination?.total + 1}`}</PageCount>

            <Button
              withIcon
              type="button"
              onClick={() => setOffset(offset + 1)}
              isDisabled={data?.pagination.current === data?.pagination.total}
            >
              Next
            </Button>
          </Pagination>
        )}
      </Card>
    </Column>
  );
};

export default FinancialData;
