import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  ChartWrapper,
  ChartHeight,
  CardTip,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';

import { FinancialModels } from 'types/shared.types';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { PortfolioPerformance as Props, PortfolioType } from '../LayoutBuilder';

import formatToPerformanceChart from './formatToPerformanceChart';

type OnClickArgs = {
  date: string;
  index: number;
};

type Point = {
  label: string;
  pointIndex: number;
};

type OnSegmentClick = {
  points: Point[];
};

type DataProp = {
  x: string[];
  y: number[];
};

type FetchProps = {
  financialModel: FinancialModels;
  portfolioType: PortfolioType;
  portfolio: string;
  signal?: AbortSignal;
};

type ConstructChartDataProps = {
  data: null | DataProp[];
  performanceDate: null | OnClickArgs;
};

const decorateChartDataWithSelectedColour = ({
  data,
  performanceDate,
}: {
  data: DataProp[];
  performanceDate: OnClickArgs;
}) => {
  const plotWithColours = {
    ...data[0],
    marker: {
      color: data[0].x.map((i: string, index: number) => {
        if (index === performanceDate.index) {
          return '#003A80';
        }
        return '#8CB8F0';
      }),
    },
  };

  return [plotWithColours];
};

const determineTickAngle = (count: number): number => {
  if (count > 30) {
    return 90;
  }

  return 30;
};

const constructChartData = ({ data, performanceDate }: ConstructChartDataProps) => {
  const chartData = data && performanceDate ? decorateChartDataWithSelectedColour({ data, performanceDate }) : data;

  const dateFormatter = (i: string): string => format(new Date(i), 'QQQ y');
  const isMonthlyAndMoreThanTwoYears = data && data[0].x && data[0].x.length > 12;

  const xAxis = isMonthlyAndMoreThanTwoYears
    ? {
        automargin: true,
        type: 'date',
      }
    : {
        automargin: true,
        type: 'date',
        tickangle: data && determineTickAngle(data[0].x.length),
        ticktext: data && data[0].x.map((i: string) => dateFormatter(i)),
        tickvals: data && data[0].x,
        tickfont: {
          size: data && data[0].x.length > 30 ? 8 : 10,
        },
      };

  const layout = {
    margin: {
      t: 10,
      b: 40,
      r: 10,
      l: 50,
    },
    yaxis: {
      automargin: true,
      title: 'Total Return, %',
      gridcolor: 'lightgrey',
    },
    xaxis: xAxis,
    barmode: 'relative',
    plot_bgcolor: 'white',
    showlegend: false,
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
  };

  return {
    data: chartData,
    layout,
  };
};

const fetchMLOrManualData = async ({ financialModel, portfolio, portfolioType, signal }: FetchProps) => {
  const { data } = await api.get('/components/performance', {
    params: {
      financial_model: financialModel,
      portfolio_dropdown: portfolio,
      portfolio_type: portfolioType,
    },
    signal,
  });

  return formatToPerformanceChart(data);
};

const PortfolioPerformance = ({
  id,
  financialModel,
  portfolio,
  portfolioType,
  presentation,
  performanceDate,
  onClick,
  isPageLoading,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, financialModel, portfolio],
    ({ signal }) => fetchMLOrManualData({ financialModel, portfolio, portfolioType, signal }),
    {
      enabled: hasPortfolio,
      refetchInterval: isPageLoading ? 1500 : false,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Portfolio Performance</Spacer>
        </Card>
      </Column>
    );
  }

  const handleClick = ({ points }: OnSegmentClick) => {
    onClick &&
      onClick({
        date: points[0].label,
        index: points[0].pointIndex,
      });
  };

  const chart = constructChartData({
    data: data || null,
    performanceDate,
  });

  return (
    <Column span={presentation.span}>
      <Card>
        {hasPortfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Portfolio Performance <Pill>{portfolio}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton data-testid="portfolioPerformanceModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see performance
            <br />
            please select a <strong>portfolio</strong> above
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="portfolioPerformanceBar"
            data={chart?.data || null}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chart?.layout, plot_bgcolor: 'transparent', paper_bgcolor: 'transparent', height, width }}
            onClick={handleClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Portfolio Performance <Pill>{portfolio}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="portfolioPerformanceBarModal"
              data={chart?.data || null}
              isLoading={isPageLoading || isLoading}
              layout={{ ...chart?.layout, plot_bgcolor: 'transparent', paper_bgcolor: 'transparent' }}
              onClick={handleClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default PortfolioPerformance;
