import React from 'react';
import { FrozenDirection } from 'react-base-table';

export interface Col {
  id: string;
  key: React.Key;
  title: string;
  dataKey: string;
  width: number;
  resizable: boolean;
  sortable: boolean;
  frozen: FrozenDirection;
  hidden: boolean;
}

const findColConfigLong = (keyName: string, index: number): Col => {
  return {
    id: `${keyName}-${index}`,
    key: keyName,
    title: keyName,
    dataKey: keyName,
    width: index === 0 ? 240 : 160,
    resizable: true,
    sortable: true,
    frozen: index === 0 ? 'left' : false,
    hidden: false,
  };
};

export default findColConfigLong;
