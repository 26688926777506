import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { ExposureByFeature as Props } from '../LayoutBuilder';

const fetchChartData = async (selectedFeature: string, signal?: AbortSignal) => {
  const { data } = await api.get('/v2/optimisation/exposure', {
    params: {
      selected_feature: selectedFeature || null,
    },
    signal,
  });

  return data;
};

const ExposureByFeature = ({
  id,
  selectedFeature,
  onChange,
  presentation,
  allSelectedFeatures,
  isPageLoading,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, selectedFeature],
    ({ signal }) => fetchChartData(selectedFeature, signal),
    {
      refetchInterval: isPageLoading ? 1500 : false,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Numeric Features </Spacer>
        </Card>
      </Column>
    );
  }

  const handleFeatureChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  const chartLayout = {
    margin: {
      t: 0,
      l: 40,
      r: 30,
      b: 20,
    },
    xaxis: {
      automargin: true,
      tickangle: 50,
    },
    barmode: 'relative',
    plot_bgcolor: 'white',
    showlegend: false,
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
  };

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Exposure by Feature</CardHeaderNewTitle>

          {!isPageLoading && !isLoading && (
            <CardHeaderActions>
              <Select
                testId="featureSelect"
                options={allSelectedFeatures}
                isControlled={true}
                value={selectedFeature}
                onChange={(e) => handleFeatureChange(e)}
              />
              <IconButton onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          )}
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="exposureByFeatures"
            data={data || null}
            isLoading={isPageLoading || isLoading}
            layout={{
              ...chartLayout,
              height,
              width,
            }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Exposure by Feature</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="exposureByFeaturesModal"
              data={data || null}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default ExposureByFeature;
