import { chartHoverLabelAttributes } from 'utils';

type Item = {
  x: number[];
  y: number[];
  name: number;
};

type Data = Item[];

type Violin = {
  name: number;
  type: string;
  x: number[];
  y: number[];
  box: {
    visible: boolean;
  };
  line: {
    color: string;
  };
  meanline: {
    visible: boolean;
  };
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
};

type ReturnShape = Violin[];

const formatToViolinDataShape = (data: Data): ReturnShape => {
  const shape = data.map((i) => ({
    name: i.name,
    type: 'violin',
    x: i.x,
    y: i.y,
    box: {
      visible: true,
    },
    line: {
      color: 'dark grey',
    },
    meanline: {
      visible: true,
    },
    hoverlabel: chartHoverLabelAttributes,
  }));

  return shape;
};

export default formatToViolinDataShape;
