import React, { ReactElement, useRef, useState } from 'react';
import { format } from 'date-fns';

import { useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  ChartWrapper,
  ChartHeight,
  CardTip,
  IconWrap,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';

import { ReactComponent as Icon } from './x-circle.svg';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import theme from 'theme';

import { TopStocks as Props } from '../LayoutBuilder';

import formatToStockChart from './formatToStockChart';

const TopStock = ({ portfolio, presentation, date, onRemoveDate, isPageLoading, data }: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const chartData = data ? formatToStockChart({ data, color: theme.colour.green }) : [];

  const chartLayout = {
    margin: {
      t: 10,
      r: 30,
      l: 140,
      b: 20,
    },
    yaxis: {
      automargin: true,
      autorange: 'reversed',
    },
    xaxis: {
      automargin: true,
      tickangle: 0,
      title: { text: 'Total Return, %' },
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
  };

  return (
    <Column span={presentation.span}>
      <Card>
        {portfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Portfolio Attribution <Pill>Top Stocks</Pill>
              {date && onRemoveDate && (
                <Pill onClick={onRemoveDate}>
                  {format(new Date(date), 'P')}
                  <IconWrap>
                    <Icon />
                  </IconWrap>
                </Pill>
              )}
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton data-testid="portfolioTopStockModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see top stocks
            <br />
            please select a <strong>portfolio</strong> above
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="topStock"
            data={chartData}
            isLoading={isPageLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Portfolio Attribution <Pill>Top Stocks</Pill>
              {date && onRemoveDate && (
                <Pill onClick={onRemoveDate}>
                  {format(new Date(date), 'P')}
                  <IconWrap>
                    <Icon />
                  </IconWrap>
                </Pill>
              )}
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart testId="topStock" data={chartData || null} isLoading={isPageLoading} layout={chartLayout} />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default TopStock;
