import { api } from 'utils';
import { PortfolioType } from '../LayoutBuilder';

interface Props {
  financialModel: string;
  portfolio: string;
  attributionDate?: string;
  attributionView: string;
  attributionSortBy: string;
  attributionRegion: string;
  attributionSector: string;
  portfolioType: PortfolioType;
  signal?: AbortSignal;
}
type ReturnShape = (
  | {
      x: string[];
      y: number[];
      type: string;
      name: string;
      marker: {
        color: string;
      };
    }
  | {
      x: string[];
      y: number[];
      type: string;
      name: string;
    }
)[];

const barChartColors = {
  regional: 'rgba(217,132,22,0.8)',
  sectoral: 'rgba(46,54,115, 0.8)',
  stock: 'rgba(222,45,38,0.8)',
};

const fetchAttributionWeights = async ({
  financialModel,
  portfolio,
  attributionDate,
  attributionView,
  attributionSector,
  attributionSortBy,
  attributionRegion,
  portfolioType,
  signal,
}: Props): Promise<ReturnShape> => {
  const { data } = await api.get(`/v2/attribution/${attributionView}_weights`, {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
      selected_date: attributionDate,
      region: attributionRegion,
      option: attributionSortBy,
      sector: attributionSector,
    },
    signal,
  });

  return [
    {
      x: data.x,
      y: data.y.portfolio,
      type: 'bar',
      name: 'portfolio',
      marker: {
        color: barChartColors[attributionView as 'regional'],
      },
    },
    {
      x: data.x,
      y: data.y.benchmark,
      type: 'bar',
      name: 'benchmark',
    },
    {
      x: data.x,
      y: data.y.relative,
      type: 'bar',
      name: 'relative',
      marker: {
        color: barChartColors[attributionView as 'regional'],
      },
    },
  ];
};

export default fetchAttributionWeights;
