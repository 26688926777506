import styled from 'styled-components';

export const FormWrap = styled.div`
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 100;
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #efefef;
  padding: 1rem;
  box-shadow: 0 3px 5px rgb(107 106 106 / 30%);
  width: 500px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const NumberTo = styled.span`
  padding: 0 0.5rem;
  font-size: 0.8rem;
`;

export const FilterButton = styled.button<{ isActive?: boolean }>`
  border-radius: 3rem;
  padding: 0 1rem;
  height: 2rem;
  line-height: 2rem;
  border: 1px solid #56585b;
  outline: none;
  font-size: 12px;
  background-color: #56585b;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-left: 1rem;

  display: flex;
  align-items: center;

  svg {
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0')});
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const ClearFilterButton = styled(FilterButton)`
  background-color: #ffffff;

  border-color: #56585b;
  color: #56585b;
`;

export const FilterControlHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FilterButtonText = styled.span`
  padding-right: 0.5rem;
`;

export const AppliedFilterList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  position: relative;
  z-index: 0;
`;

export const ButtonPill = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 0 1rem;
  height: 2rem;
  line-height: 2rem;
  margin-left: 0.5rem;
  font-size: 12px;
  cursor: pointer;

  svg {
    margin-left: 0.5rem;
  }
`;

export const AppliedFilterListLi = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
`;

export const NumberInput = styled.input`
  height: 2rem;
  width: 50%;
  border-radius: 3rem;
  border: 1px solid #f3f3f3;
  padding: 0 1rem;
  background-color: #f3f3f3;
  outline: none;
`;

export const TextInput = styled(NumberInput)`
  width: 100%;
  margin-left: 1rem;
`;

export const Select = styled.select`
  width: 70%;
  height: 2rem;
  border-radius: 3rem;
  border: 1px solid #f3f3f3;
  border-right-width: 10px;
  padding: 0 1rem;
  background-color: #f3f3f3;
  outline: none;
  text-transform: capitalize;
`;

export const FilterHeader = styled.strong`
  margin-left: 0.5rem;
  text-transform: capitalize;
`;
