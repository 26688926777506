import React, { ReactElement, useEffect, useState, useRef } from 'react';
import BaseTable, { Column, BaseTableProps } from 'react-base-table';
import ReactTooltip from 'react-tooltip';

import { Button, DropdownButton } from 'Common';
import { Header, CardHeaderActions } from 'Common/Shared.styles';
import { DownloadCsvButton } from 'Common/CsvButton/CsvButton';
import StyledTableSticky, { HeaderWrapper } from './TableLong.styles';
import { Pagination, PageCount } from 'Common/TableLong/TableLong.styles';

import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { Col } from 'utils/findColConfigLong';

import TableFilter from './TableFilter';

interface CsvParams {
  financialModel: string;
  endpoint: string;
  filterId?: string;
  dataGroupingParams?: {
    primary_group: string;
    sub_group: string;
    start_date: string;
    end_date: string;
  };
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface TableProperties extends BaseTableProps {
  id: string;
  initCollapsed?: boolean;
  maxRowsToDisplay?: number;
  title?: string | JSX.Element;
  withColumnShowHide?: boolean;
  financialModel?: string;
  endpoint?: string;
  filterId?: string;
  fixedHeight?: number;
  dataGroupingParams?: {
    primary_group: string;
    sub_group: string;
    start_date: string;
    end_date: string;
  };
  isPaginated?: boolean;
}

export const TABLE_ROW_HEIGHT = 40;

const DownloadTableCsv = ({ financialModel, endpoint, filterId = '', dataGroupingParams }: CsvParams): ReactElement => {
  const url = `components/${endpoint}`;
  const params = {
    financial_model: financialModel,
    output: 'csv',
    filter_id: filterId || null,
    ...dataGroupingParams,
  };

  return (
    <DownloadCsvButton
      fetchData={{ url, params }}
      testId={`downloadTableButton-${endpoint}`}
      filename={
        dataGroupingParams
          ? `${dataGroupingParams.primary_group}${dataGroupingParams.sub_group}Table.csv`
          : `${financialModel}_${endpoint}_table.csv`
      }
      cacheKey={`${financialModel}${endpoint}`}
    />
  );
};

const TableLong: React.FC<TableProperties> = ({
  width,
  columns = [],
  data = [],
  withColumnShowHide = true,
  id = '',
  initCollapsed = false,
  maxRowsToDisplay,
  title = '',
  financialModel = '',
  endpoint = '',
  filterId = '',
  fixedHeight,
  dataGroupingParams,
  isPaginated = false,
}): ReactElement => {
  const ref = useRef<BaseTable>(null);

  const [isCollapsed, setIsCollapsed] = useState(initCollapsed);
  const toggleOptions = () => setIsCollapsed(!isCollapsed);

  const [allColumns, setAllColumns] = useState(columns);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => !column.hidden));
  const [offset, setOffset] = useState(0);
  const [content, setContent] = useState(data);
  const [sortBy, setSortBy] = useState<{ key: any; order: SortOrder }>();

  const handleOnColumnSort =
    (content: any, setContent: any, setSortBy: any) =>
    ({ key, order }: any) => {
      const dataSorted = [...content].sort((a, b) => {
        if (order === SortOrder.ASC) {
          return a[key] > b[key] ? 1 : -1;
        } else {
          return a[key] < b[key] ? 1 : -1;
        }
      });
      setSortBy({ key, order });
      setContent(dataSorted);
    };

  const onColumnsChange = (visibleCols: Col[], allCols: Col[]) => {
    setVisibleColumns(visibleCols);
    setAllColumns(allCols);
  };

  const height =
    maxRowsToDisplay && data.length >= maxRowsToDisplay
      ? maxRowsToDisplay * TABLE_ROW_HEIGHT
      : (data.length + 1) * TABLE_ROW_HEIGHT;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  React.useEffect(() => {
    setContent(data);
  }, [data]);

  React.useEffect(() => {
    setAllColumns(columns);
    setVisibleColumns(columns.filter((column) => !column.hidden));
  }, [columns]);

  return (
    <StyledTableSticky data-testid={id}>
      {(title || withColumnShowHide || endpoint) && (
        <HeaderWrapper>
          {title && <Header>{title}</Header>}

          <CardHeaderActions>
            {withColumnShowHide && (
              <DropdownButton icon={<SettingsIcon />} title="Show Table Filters" testId="showFilterButton">
                <TableFilter
                  id={`${id}Filter`}
                  columns={allColumns as Col[]}
                  onClose={toggleOptions}
                  onColumnsChange={onColumnsChange}
                />
              </DropdownButton>
            )}

            {endpoint && (
              <DownloadTableCsv
                financialModel={financialModel}
                endpoint={endpoint}
                filterId={filterId || ''}
                dataGroupingParams={dataGroupingParams}
              />
            )}
          </CardHeaderActions>
        </HeaderWrapper>
      )}
      <BaseTable
        ref={ref}
        width={width - 4}
        height={fixedHeight ? fixedHeight : height}
        data={isPaginated ? content.slice(offset * 20, offset * 20 + 20) : content}
        fixed
        sortBy={sortBy}
        onColumnSort={handleOnColumnSort(content, setContent, setSortBy)}
        headerHeight={TABLE_ROW_HEIGHT}
        rowHeight={TABLE_ROW_HEIGHT}
      >
        {visibleColumns.map((column) => (
          <Column {...column} key={column.key} />
        ))}
      </BaseTable>
      {isPaginated && (
        <Pagination width={350}>
          <Button
            withIcon
            type="button"
            onClick={() => {
              setOffset(offset - 1);
              ref.current?.scrollToTop(0);
            }}
            isDisabled={offset === 0}
          >
            Back
          </Button>

          <PageCount>{`${offset + 1} of ${Math.round(content.length / 20)}`}</PageCount>

          <Button
            withIcon
            type="button"
            onClick={() => {
              setOffset(offset + 1);
              ref.current?.scrollToTop(0);
            }}
            isDisabled={offset + 1 === Math.round(content.length / 20)}
          >
            Next
          </Button>
        </Pagination>
      )}
    </StyledTableSticky>
  );
};

export default TableLong;
