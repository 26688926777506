import React, { ReactElement, ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
  defaultValue?: string | number;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: 'text' | 'number';
  testId?: string;
  isInvalid?: boolean;
  value?: string | number;
  label?: string;
  version?: string;
};

const Wrapper = styled.div<{ version: string }>`
  position: relative;
  width: 100%;
  display; flex;
  padding: 0.5rem;
  padding-bottom: 0rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  opacity: 0.75;

  label {
    font-size: 10px;
    padding-left: 3px;
    font-weight: bold;
  }

  ${({ theme, version }) => {
    if (version === 'secondary') {
      return `
      border-left: 1px solid ${theme.colors.lightGrey};
      border-bottom: 1px solid ${theme.colors.lightGrey};
  `;
    }
  }}
`;

const InputStyle = styled.input<{ isInvalid: boolean; version?: string; value?: string | number }>`
  box-sizing: border-box;

  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.red : theme.colors.lightGrey)};
  padding: 0.25rem;
  font-weight: bold;
  margin: 0;

  outline: none;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  &:focus {
    border-color: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.red : theme.colors.white)};
  }

  ${({ theme, version, value }) => {
    if (version === 'secondary') {
      return `
    color: ${!value ? theme.colors.darkGrey : theme.colors.black};
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
    cursor: pointer;
    border-radius: 0;
    height: auto;
    line-height: unset;
    padding-left: 0.3rem;
    margin-top: 0.25rem;
    border-bottom: ${!value ? `2px solid ${theme.colors.red}` : `2px solid ${theme.colors.white}`};
    font-size: 0.75rem;
    font-weight: ${value && 'bold'};

    &:hover,
    &:focus {
      border-bottom: 2px solid ${theme.colors.primary};
    }
  `;
    }
  }}
`;

const Input = ({
  defaultValue = '',
  onChange,
  onFocus,
  placeholder,
  type,
  testId = '',
  isInvalid = false,
  value,
  label,
  version = '',
}: Props): ReactElement => {
  return (
    <Wrapper version={version}>
      {label && <label htmlFor="input">{label}</label>}
      <InputStyle
        id="input"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        data-testid={testId}
        onFocus={onFocus}
        isInvalid={isInvalid}
        value={value}
        version={version}
      />
    </Wrapper>
  );
};

export default Input;
