import React, { ReactElement } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import Button from '../Button';
import { MediumWrapper, Card, Spacer } from '../Shared.styles';

const CenterText = styled.div`
  text-align: center;
`;

const P = styled.p`
  margin-top: 0;
`;

const Unauthenticated = (): ReactElement => (
  <Spacer>
    <MediumWrapper data-testid="unauthenticated">
      <Card>
        <Spacer>
          <CenterText>
            <P>Your session has expired</P>

            <Button testId="signInButton" type="button" onClick={() => Auth.federatedSignIn()}>
              Sign in
            </Button>
          </CenterText>
        </Spacer>
      </Card>
    </MediumWrapper>
  </Spacer>
);

export default Unauthenticated;
