import styled from 'styled-components';
import { listItemStyle } from 'cssVars';
import theme from 'theme';

import { Link, NavLink } from 'react-router-dom';

const minSideBarWidth = 320;

export const MainBody = styled.div`
  background-color: #eff3f9;
  height: 100%;
  padding-top: 72px;
`;

export const PrimaryColumn = styled.div`
  width: 30%;
  margin-right: 1rem;
`;

export const DataNavigation = styled.nav<{ isFullWidth?: boolean; noPanel?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0 1.75rem;
  align-items: center;
  height: 3.5rem;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: ${({ isFullWidth, noPanel }) =>
    noPanel ? '100%' : isFullWidth ? 'calc(100% - 2.75rem)' : `calc(min(75%, 100% - ${minSideBarWidth}px))`};
  z-index: 50;
  top: 72px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const NavList = styled.ul<{ isFullWidth?: boolean }>`
  position: fixed;
  height: 5rem;
  width: ${({ isFullWidth }) => (isFullWidth ? 'calc(100% - 2.75rem)' : `calc(min(75%, 100% - ${minSideBarWidth}px))`)};
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 0 1.75rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  border-top: 1px solid #e5e5e5;
  list-style-type: none;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    justify-content: space-evenly;
  }

  @media screen and (min-width: ${theme.breakpoints.desktop}) {
    position: relative;
    display: flex;
    height: 3.5rem;
    padding: 0;
    margin: 0;
    background: transparent;
    justify-content: flex-end;
    width: auto;
  }
`;

export const NavListItem = styled.li`
  margin-left: 0;
  height: 100%;

  @media screen and (min-width: ${theme.breakpoints.desktop}) {
    margin-left: 1rem;
  }
`;

export const NavLinkPills = styled(NavLink)`
  height: 100%;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  color: ${({ theme }) => theme.colors.darkGrey};

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 4px;
    width: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    transition: width 0.5s ease-in-out;
  }

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 4px;
      width: 2rem;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.darkGrey};
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary};

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 4px;
      width: 100%;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media screen and (max-width: ${theme.breakpoints.desktop}) {
    font-size: 0.7rem;
    text-align: center;
  }
`;

export const InputText = styled.input`
  width: 100%;
  border: none;
  border-bottom: ${({ theme }) => `4px solid ${theme.colors.lightGrey}`};
  background: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 0.7rem;
  outline: none;
  &:focus {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.primary}`};
  }
  &::placeholder {
    color: lightgrey;
  }
`;

export const LoaderWraper = styled.div`
  margin-top: 1rem;
`;

export const LoaderWraperSpacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
`;

export const ToggleLayoutConfigPanel = styled.div<{ isToggled: boolean; isSmallTitle?: boolean }>`
  width: ${({ isToggled }) => (isToggled ? '25%' : '2.75rem')};
  min-width: ${({ isToggled }) => isToggled && `${minSideBarWidth}px`};
  border-right: 1px solid #e5e5e5;
  display: block;
  position: fixed;
  height: calc(100vh - 72px);
  overflow-y: scroll;
  background-color: white;
  padding: 0rem;
  z-index: 2;
  padding-top: ${({ isSmallTitle }) => (isSmallTitle ? '3.5rem' : '5.5rem')};
  padding-bottom: ${({ isSmallTitle }) => (isSmallTitle ? '0' : '5rem')};

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    width: ${({ isToggled }) => (isToggled ? 'calc(100% - 2.75rem)' : '2.75rem')};
  }
`;

export const ToggleLayoutConfigPanelInner = styled.div<{ isToggled: boolean }>`
  width: 100%;
  display: ${({ isToggled }) => (isToggled ? 'block' : 'none')};
`;

export const ToggleLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ToggleLayoutBody = styled.div<{ isFullWidth: boolean }>`
  width: ${({ isFullWidth }) => (isFullWidth ? 'calc(100% - 2.75rem)' : `calc(min(75%, 100% - ${minSideBarWidth}px))`)};
  margin-left: ${({ isFullWidth }) => (isFullWidth ? '2.75rem' : `calc(max(25%, ${minSideBarWidth}px))`)};
  min-height: calc(100vh - 72px);
  padding-top: 3.5rem;
  padding-bottom: 6.75rem;
  z-index: 1;

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    width: ${({ isFullWidth }) => (isFullWidth ? 'calc(100% - 2.75rem)' : '2.75rem')};

    & > div {
      display: ${({ isFullWidth }) => (isFullWidth ? 'block' : 'none')};
    }
  }

  @media screen and (min-width: ${theme.breakpoints.desktop}) {
    padding-bottom: 1.75rem;
  }
`;

export const ToggleHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const SpacerTop = styled.div`
  margin-top: 1rem;
`;

export const Group = styled.div`
  border-bottom: 2px solid #efefef;
`;

export const DataList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  font-size: 0.85rem;
  box-sizing: border-box;
`;

export const DataListItem = styled.li`
  word-wrap: break-word;
  list-style-type: none;

  display: flex;
  align-items: center;

  width: 100%;
  height: 3.5rem;
  padding: 0 1.75rem;
  font-size: 0.9rem;

  ${listItemStyle}
`;

export const Size = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 3.5rem;
  padding: 0 1.75rem;
  font-size: 0.9rem;

  ${listItemStyle}
`;

export const StyledLink = styled(Link)`
  position: relative;
  outline: none;
  width: 100%;
  font-size: 0.85rem;

  cursor: pointer;
  text-align: center;

  border-radius: 0.25rem;
  border-width: 2px;
  border-style: solid;
  outline: none;
  padding: 1rem;

  ${({ theme }) => {
    return `
    color: white;
    border-color: ${theme.colors.primaryDark};
    background-color: ${theme.colors.primaryDark};
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
    &:hover {
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};
    }`;
  }};
`;

export const FeatureInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FeatureInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0 1.75rem;
  height: 5rem;

  ${listItemStyle}
`;

export const FeatureInputHeader = styled.h5`
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const ToggleLayoutConfigPanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  width: 100%;
`;

export const ToggleLayoutConfigPanelFixed = styled.div<{ withShadow?: boolean }>`
  position: fixed;
  width: calc(max(25%, 320px));
  background: ${({ theme }) => theme.colors.primaryDark};
  top: 72px;
  left: 0;
  z-index: 100;
  padding: 0 1.75rem;
  border-right: 1px solid #e5e5e5;
  box-shadow: ${({ withShadow }) => withShadow && '0 2px 4px rgba(0, 0, 0, 0.2)'};

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    width: calc(100% - 2.75rem);
  }
`;

export const ActionButtonFixed = styled.div`
  position: fixed;
  width: calc(max(25%, 320px));
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0 1.75rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    width: calc(100% - 2.75rem);
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 3.5rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  padding: 0 1.75rem;
  position: relative;
`;

export const SearchBox = styled.input`
  width: 100%;
  height: 2.5rem;
  padding-left: 1.5rem;
  background: none;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: ${({ theme }) => `4px solid ${theme.colors.darkGrey}`};
  &:focus {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.primary}`};
  }
`;

export const SearchCloseButton = styled.button`
  outline: none;
  border: none;
  appearance: none;
  cursor: pointer;
  position: absolute;
  right: 1.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  width: 2rem;
  height: 1.5rem;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end;
  grid-gap: 0.5rem;
  height: 2rem;
  padding: 0 1.75rem;
`;

export const StickyWrapper = styled.div<{ top?: number }>`
  height: 100%;
  width: 100%;
  top: ${({ top }) => (top ? `${top}rem` : 0)};
  position: sticky;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const Title = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.75rem;
`;

export const StyledSpan = styled.span`
  transform: translateY(0.25rem);
  font-weight: bold;
  font-size: 0.8rem;
`;

export const ErrorWrapper = styled.div`
  padding: 1.75rem;
  background: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.red};
  font-size: 0.8rem;
`;

export const MenuItem = styled.button`
  background: none;
  border: none;
  outline: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 14px;
  width: 10rem;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const SliderWrapper = styled.div`
  width: 400px;
  padding: 0.5rem 1.75rem;
  padding-top: 2rem;
  padding-bottom: 0;
`;

export const FlexEnd = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
`;

export const SpaceBetween = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 1rem;
  height: 100%;
`;

export const Wrapper = styled.div`
  padding: 0.5rem 1.75rem;
`;

export const SelectWrapper = styled.div<{ rightAlign?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5rem;
  height: 100%;
  justify-content: ${({ rightAlign }) => (rightAlign ? 'flex-end' : 'flex-start')};
`;

export const StyledSelect = styled.select`
  outline: none;
  border: none;
  background: none;
  text-align-last: right;
  padding-right: 0.75rem;
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.darkGrey};
  height: 100%;
`;

export const StyledMethodSelectionLabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledMethodSelectionLabel = styled.label<{ isActive: boolean }>`
  border-bottom: ${({ isActive, theme }) => isActive && `4px solid ${theme.colors.primary}`};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.white)};
  background-color: none;
  cursor: pointer;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-bottom: 0.5rem;

  input {
    display: none;
  }

  font-size: 0.75rem;

  @media (min-width: 1500px) {
    font-size: 0.9rem;
  }
`;
