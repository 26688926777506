import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';

const StyledSlider = withStyles({
  root: {
    color: theme.colors.primaryDark,
    height: 4,
    '& .Mui-disabled': {
      height: 16,
      width: 16,
      marginTop: -4,
      marginLeft: -12,
    },
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    fontSize: '0.75rem',
  },
})(Slider);

export default StyledSlider;
