import styled, { css } from 'styled-components';

export type TextProps = {
  textType: 'h1' | 'h2' | 'p';
  align: 'left' | 'center' | 'right';
  color: string;
  text: string;
  onClick?: () => void;
  className?: string;
  testId?: string;
  fontWeight?: string;
};

const sharedStyles = css`
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  text-align: ${(props: TextProps) => props.align};
  color: ${(props: TextProps) => props.color};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  font-weight: ${({ fontWeight }) => fontWeight};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const H1 = styled.h1<TextProps>`,
  ${sharedStyles}
  text-transform: uppercase;
`;

export const H2 = styled.h2<TextProps>`
  ${sharedStyles}
`;

export const Paragraph = styled.p<TextProps>`
  ${sharedStyles}
`;
