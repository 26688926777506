import { Auth } from 'aws-amplify';

async function isAuthenticated(): Promise<boolean> {
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    return Boolean(currentAuthenticatedUser);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default isAuthenticated;
