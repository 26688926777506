type Option = {
  label: string;
  value: string;
};

const formatToOptions = (items: string[]): Option[] => {
  const options = items.map((i) => ({ label: i, value: i }));

  return options;
};

export default formatToOptions;
