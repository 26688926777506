import React, { ReactElement, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { api, findColConfigLong, useDivSize } from 'utils';
import { chartMargin } from 'utils/useDivSize';

import { CardLoader, TableLong } from 'Common';
import { Card, Spacer, Column, CardHeaderNew, CardHeaderNewTitle, Pill, CardTip } from 'Common/Shared.styles';

import { FinancialModels } from 'types/shared.types';
import { DownloadCsvButton, ConstituentReturnsParams } from 'Common/CsvButton/CsvButton';
import { ExposureConstituentReturns as Props, PortfolioType } from '../LayoutBuilder';

type FetchTableData = {
  financialModel: FinancialModels;
  portfolio: string;
  portfolioType: PortfolioType;
  feature?: string;
  signal?: AbortSignal;
};

const fetchTableData = async ({ financialModel, portfolio, portfolioType, feature, signal }: FetchTableData) => {
  const { data } = await api.get('/components/constituent_returns', {
    params: {
      financial_model: financialModel,
      portfolio_dropdown: portfolio,
      portfolio_type: portfolioType,
      selected_feature: feature || null,
    },
    signal,
  });

  const response = {
    columns: data.columns,
    rows: data.rows,
  };

  return response;
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const ExposureConstituentReturns = ({
  id,
  financialModel,
  portfolio,
  presentation,
  portfolioType,
  feature,
  cacheKey,
}: Props): ReactElement => {
  const payload = { financialModel, portfolio, portfolioType, feature };
  const parentRef = useRef<HTMLDivElement>(null);
  const { width } = useDivSize(parentRef);
  const hasPortfolio = Boolean(portfolio);
  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchTableData({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  const memoizedColumns = useMemo(() => data?.columns?.map((x: string, i: number) => findColConfigLong(x, i)), [data]);

  const memoizedRows = useMemo(() => data?.rows, [data]);

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Constituent Returns</Spacer>
        </Card>
      </Column>
    );
  }

  const downloadCSVProps = {
    fetchData: {
      url: '/components/constituent_returns',
      params: {
        financial_model: financialModel,
        portfolio_dropdown: portfolio,
        portfolio_type: portfolioType,
        selected_feature: feature || null,
        output: 'csv',
      } as ConstituentReturnsParams,
    },
    testId: 'downloadExposureButton',
    filename: `constituentReturns.csv`,
    cacheKey: `${financialModel}${portfolio}${portfolioType}`,
  };

  return (
    <Column span={presentation.span} data-testid="exposureConstituentReturns" ref={parentRef}>
      {isLoading ? (
        <Card data-testid="portfolioConstituentsReturnTableLoader">
          <LoaderWrapper>
            <CardLoader />
          </LoaderWrapper>
        </Card>
      ) : (
        <Card>
          {hasPortfolio ? (
            <>
              <CardHeaderNew>
                <CardHeaderNewTitle>
                  Portfolio Constituents
                  <Pill>{portfolio}</Pill>
                  {feature && <Pill>{feature}</Pill>}
                </CardHeaderNewTitle>

                <DownloadCsvButton {...downloadCSVProps} />
              </CardHeaderNew>

              <TableLong
                id="portfolioConstituentsReturnTable"
                columns={memoizedColumns || []}
                data={memoizedRows || []}
                maxRowsToDisplay={10}
                isPaginated={true}
                width={width - chartMargin}
                withColumnShowHide={false}
              />
            </>
          ) : (
            <CardTip>
              To see portfolio constituents
              <br />
              please select a <strong>portfolio</strong>
            </CardTip>
          )}
        </Card>
      )}
    </Column>
  );
};

export default ExposureConstituentReturns;
