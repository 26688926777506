import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Loader } from 'Common';
import theme from 'theme';

const StyledSubPageLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 80vh;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightGrey};
`;

const SubPageLoader = (): ReactElement => {
  return (
    <StyledSubPageLoader>
      <Loader color={theme.colors.primary} />
    </StyledSubPageLoader>
  );
};

export default SubPageLoader;
