import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from './x-circle.svg';

const StyledDiv = styled.div<{ isVisible: boolean }>`
  background-colour: red;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  top: 2.5rem;
  left: 0;
  background-color: rgb(255 255 255 / 80%);
  width: 100%;
  border-radius: 1rem;
  z-index: 1000;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.7rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #ccc;

  justify-content: space-between;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Text = styled.span`
  font-size: 0.8rem;
`;

const ChartDataReact = (): ReactElement => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <StyledDiv isVisible={isVisible} onClick={() => setIsVisible(false)}>
      <Text>Not Enough Data to Calculate the Buckets for the Chart</Text> <Icon />
    </StyledDiv>
  );
};

export default ChartDataReact;
