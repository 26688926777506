import styled from 'styled-components';

export const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputWrap = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  position: relative;
  width: 70%;
  max-width: 20rem;
`;

export const ClearButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  z-index: 1;
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
`;
