import React, { useState, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { Button, Text } from 'Common';
import { Wrapper, Label, StyledForm, PasswordContainer, PasswordInput, VisibilityButton, Row } from '../SignIn.styles';
import theme from 'theme';

import { ReactComponent as InvisibilityIcon } from 'icons/invisibility.svg';
import { ReactComponent as VisibilityIcon } from 'icons/visibility.svg';

interface UserType {
  cognito: CognitoUser;
  isFirstLogin: boolean;
}

interface FirstLoginProps {
  user: UserType;
}

interface CompleteNewPasswordProps extends FirstLoginProps {
  newPassword: string;
}

const FirstLogin = ({ user }: FirstLoginProps): ReactElement => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMatching, setIsMatching] = useState(true);

  const updateNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePassword = async ({ user, newPassword }: CompleteNewPasswordProps) => {
    const request = await Auth.completeNewPassword(user.cognito, newPassword);
    return request;
  };

  const handleBlur = () => {
    if (newPassword === confirmPassword) {
      setIsMatching(true);
    } else {
      setIsMatching(false);
    }
  };

  const mutation = useMutation(handleChangePassword, {
    onError: (error: Error) => {
      setValid(false);
      console.log(error.message);
    },
    onSuccess: () => {
      setSuccess(true);
      setTimeout(() => history.push('/manage-dataset'), 1500);
    },
  });

  const { error } = mutation;

  return (
    <StyledForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        mutation.mutate({ user, newPassword });
      }}
    >
      <Wrapper>
        <Text textType="h2" color={theme.colors.darkGrey} align="left" text="Please enter your new password." />
      </Wrapper>
      <Wrapper className="password">
        <Label>New Password</Label>
        <PasswordContainer>
          <PasswordInput
            type={isVisible ? 'text' : 'password'}
            name="password"
            value={newPassword}
            onChange={(e) => updateNewPassword(e)}
            autoComplete="off"
            autoCapitalize="off"
          />
          <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
            {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        </PasswordContainer>
      </Wrapper>

      <Wrapper className="password">
        <Row>
          <Label>Confirm Password</Label>
          {!isMatching && <Text textType="p" color={theme.colors.red} align="right" text="Passwords do not match" />}
        </Row>
        <PasswordContainer>
          <PasswordInput
            type={isVisible ? 'text' : 'password'}
            name="password"
            value={confirmPassword}
            onChange={updateConfirmPassword}
            onBlur={handleBlur}
            autoComplete="off"
            autoCapitalize="off"
          />
          <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
            {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        </PasswordContainer>
      </Wrapper>

      {!valid && error && <Text textType="p" color={theme.colors.red} align="right" text={error.message} />}
      {success && <Text textType="p" color={theme.colors.green} align="right" text="Successfully changed password" />}

      <Button
        theme="primary"
        size="large"
        type="submit"
        isDisabled={!newPassword || !confirmPassword || newPassword !== confirmPassword || mutation.isLoading}
      >
        Change Password
      </Button>
    </StyledForm>
  );
};

export default FirstLogin;
