import React, { ReactElement, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api, findColConfig } from 'utils';
import { Table, CardLoader } from 'Common';
import { CardHeaderNew, CardHeaderNewTitle, CardHeaderActions, Card, LoaderMessage } from 'Common/Shared.styles';
import { DownloadCsvButton, IndexAndValuesParams } from 'Common/CsvButton/CsvButton';
import { PortfolioAndIndexReturns as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchTableDataProp = {
  financialModel: FinancialModels;
  benchmark: string;
  signal?: AbortSignal;
};

const CenterLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 654px;
`;

const fetchTableData = async ({ financialModel, benchmark, signal }: FetchTableDataProp) => {
  const { data } = await api.get('/components/ml_returns_and_index_table_data', {
    params: {
      financial_model: financialModel,
      benchmark_id: benchmark || null,
    },
    signal,
  });

  return { columns: data.columns.map(findColConfig) || [], rows: data.rows || [] };
};

const PortfolioAndIndexReturns = ({
  id,
  financialModel,
  maxRowsToDisplay,
  isPageLoading,
  benchmark,
  cacheKey,
}: Props): ReactElement => {
  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, financialModel, isPageLoading, benchmark],
    ({ signal }) => fetchTableData({ financialModel, benchmark, signal }),
    {
      enabled: !isPageLoading,
    },
  );

  const ref = useRef<HTMLDivElement | null>(null);

  if (isError) {
    return <span>An error has occured with QuarterlyReturnsTable</span>;
  }

  const componentLoading = isPageLoading || isLoading;

  return (
    <Card>
      <CardHeaderNew>
        <CardHeaderNewTitle data-testid="returnsAndIndexHeader">Returns and Index Values</CardHeaderNewTitle>

        {!componentLoading && (
          <CardHeaderActions>
            <DownloadCsvButton
              fetchData={{
                url: '/components/ml_returns_and_index_table_data',
                params: {
                  financial_model: financialModel,
                  output: 'csv',
                } as IndexAndValuesParams,
              }}
              testId="downloadIndexAndValuesTableButton"
              filename="mlQuarterlyReturns.csv"
              cacheKey={financialModel}
            />
          </CardHeaderActions>
        )}
      </CardHeaderNew>

      {componentLoading ? (
        <CenterLoader data-testid="returnsAndIndexTableLoader">
          <CardLoader />
          <LoaderMessage>Training the model</LoaderMessage>
        </CenterLoader>
      ) : (
        <Table
          id="returnsAndIndexTable"
          columns={data?.columns || []}
          data={data?.rows || []}
          withOverflow={window.innerWidth < 1200}
          maxRowsToDisplay={maxRowsToDisplay}
          width={ref.current?.offsetWidth || 300}
        />
      )}
    </Card>
  );
};

export default PortfolioAndIndexReturns;
