import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { FinancialModels } from 'types/shared.types';
import { PortfolioScatterPlot as Props } from '../LayoutBuilder';
import decorateChartData from './decorateChartData';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';

type HandlePortfolioClickProps = {
  points: {
    pointIndex: number;
    data: { name: string[] };
  }[];
};

type FetchChartDataProps = {
  financialModel: FinancialModels;
  chartFeature: string;
  signal?: AbortSignal;
};

const fetchReturningConcentrationChartData = async ({ financialModel, chartFeature, signal }: FetchChartDataProps) => {
  const { data } = await api.get('/components/v2/return_and_concentration', {
    params: {
      financial_model: financialModel,
      selected_feature: chartFeature || null,
    },
    signal,
  });

  return {
    selectedFeature: data.selected_feature || [],
    featureOptions: data.feature_options || [],
    chartData: data.chart_data,
  };
};

const PortfolioScatterPlot = ({
  id,
  financialModel,
  onClick,
  isPageLoading,
  presentation,
  portfolio,
  cacheKey,
  onLoad,
}: Props): ReactElement => {
  const [chartFeature, setChartFeature] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const { isLoading, data, isError } = useQuery(
    [cacheKey, id, financialModel, chartFeature],
    ({ signal }) => fetchReturningConcentrationChartData({ financialModel, chartFeature, signal }),
    {
      refetchInterval: isPageLoading ? 5000 : false,
      onSuccess: (data) => {
        if (isPageLoading === false) {
          // We only want to set the portfolio once the job is done (to reduce requests)
          onLoad(data.chartData.name[0]);
        }
      },
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Portfolio Scatter plot</Spacer>
        </Card>
      </Column>
    );
  }

  const handlePortfolioClick = ({ points }: HandlePortfolioClickProps) => {
    onClick(points[0].data.name[points[0].pointIndex]);
  };

  const styleChartData = data?.chartData
    ? decorateChartData({
        data: data?.chartData,
        portfolio,
      })
    : null;

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Portfolio Return And Concentration</CardHeaderNewTitle>
          {!isPageLoading && data?.featureOptions && data.featureOptions.length > 0 && (
            <CardHeaderActions>
              <Select
                testId="scatterPlotSelect"
                options={data?.featureOptions || []}
                isControlled={true}
                value={chartFeature || data?.selectedFeature || ''}
                onChange={(e) => setChartFeature(e.target.value)}
              />
              <IconButton data-testid="portfolioScatterPlotModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          )}
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="portfolioScatterPlot"
            data={styleChartData?.data}
            isLoading={isLoading}
            layout={{
              ...styleChartData?.layout,
              plot_bgcolor: 'transparent',
              paper_bgcolor: 'transparent',
              height,
              width,
            }}
            onClick={handlePortfolioClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Portfolio Return And Concentration</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="portfolioScatterPlot"
              data={styleChartData?.data}
              isLoading={isLoading}
              layout={{ ...styleChartData?.layout, plot_bgcolor: 'transparent', paper_bgcolor: 'transparent' }}
              onClick={handlePortfolioClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default PortfolioScatterPlot;
