import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generate } from 'shortid';

import { RootState } from 'store';

interface CrossSection {
  date: null | string;
  feature: string;
}
interface Attribution {
  date: string;
  sortBy: string;
  region: string;
  sector: string;
  view: string;
}
export type PerformanceDate = null | { date: string; index: number };

export interface MLState {
  id: string;
  benchmark: string;
  model: string; // this needs to store capitalcase values
  modelAccuracySelectedModel: string; // this needs to store lowercase values
  inProgress: boolean;
  feature: string;
  attribution: Attribution;
  crossSection: CrossSection;
  performanceDate: PerformanceDate;
  modelHorizon: number;
}

const initialState: MLState = {
  id: '',
  benchmark: '',
  modelAccuracySelectedModel: '',
  model: '',
  inProgress: true,
  feature: '',
  attribution: { date: '', sortBy: 'top_contributions', region: '', sector: '', view: 'regional' },
  performanceDate: null,
  crossSection: {
    date: null,
    feature: 'Sector',
  },
  modelHorizon: 1,
};

export const machineLearningSlice = createSlice({
  name: 'machineLearning',
  initialState,
  reducers: {
    setPerformanceDate: (state, action: PayloadAction<PerformanceDate>) => {
      state.performanceDate = action.payload;
    },
    setFeature: (state, action: PayloadAction<string>) => {
      state.feature = action.payload;
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.model = action.payload;
    },
    setAccuracyModel: (state, action: PayloadAction<string>) => {
      state.modelAccuracySelectedModel = action.payload;
    },
    setCrossSection: (state, action: PayloadAction<CrossSection>) => {
      state.crossSection = action.payload;
    },
    setAttributionDate: (state, action: PayloadAction<string>) => {
      state.attribution.date = action.payload;
    },
    setAttributionRegion: (state, action: PayloadAction<string>) => {
      state.attribution.region = action.payload;
    },
    setAttributionSector: (state, action: PayloadAction<string>) => {
      state.attribution.sector = action.payload;
    },
    setAttributionView: (state, action: PayloadAction<string>) => {
      state.attribution.view = action.payload;
    },
    setAttributionSortby: (state, action: PayloadAction<string>) => {
      state.attribution.sortBy = action.payload;
    },
    setConstructPortfolio: (state) => {
      state.id = generate();
      state.benchmark = initialState.benchmark;
      state.modelAccuracySelectedModel = initialState.modelAccuracySelectedModel;
      state.model = initialState.model;
      state.inProgress = true;
      state.feature = initialState.feature;
      state.attribution = initialState.attribution;
    },
    setConstructPortfolioStatus: (state, action: PayloadAction<boolean>) => {
      state.inProgress = action.payload;
    },
    setBenchmark: (state, action: PayloadAction<string>) => {
      state.benchmark = action.payload;
    },
    setModelHorizon: (state, action: PayloadAction<number>) => {
      state.modelHorizon = action.payload;
    },
  },
});

export const {
  setFeature,
  setConstructPortfolio,
  setConstructPortfolioStatus,
  setModel,
  setBenchmark,
  setPerformanceDate,
  setAttributionDate,
  setAttributionSector,
  setAttributionSortby,
  setAttributionView,
  setAttributionRegion,
  setAccuracyModel,
  setModelHorizon,
} = machineLearningSlice.actions;

export const selectMlId = (state: RootState): string => state.machineLearning.id;
export const selectBenchmark = (state: RootState): string => state.machineLearning.benchmark;
export const selectModelAccuracySelectedModel = (state: RootState): string =>
  state.machineLearning.modelAccuracySelectedModel;
export const selectModel = (state: RootState): string => state.machineLearning.model;
export const selectMlInProgress = (state: RootState): boolean => state.machineLearning.inProgress;
export const selectFeature = (state: RootState): string => state.machineLearning.feature;
export const selectCrossSection = (state: RootState): CrossSection => state.machineLearning.crossSection;
export const selectAttribution = (state: RootState): Attribution => state.machineLearning.attribution;
export const selectPerformanceDate = (state: RootState): PerformanceDate => state.machineLearning.performanceDate;
export const selectModelHorizon = (state: RootState): number => state.machineLearning.modelHorizon;

export default machineLearningSlice.reducer;
