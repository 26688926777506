import React, { ReactElement, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useMutation } from 'react-query';
import { IconButton } from 'Common/Shared.styles';
import { DownloadCsvButtonStyle } from 'Common/Shared.styles';
import { ReactComponent as Download } from 'icons/download.svg';
import { api } from 'utils';

export type PortfolioMetricsParams = {
  financial_model: string;
  portfolio_type: string;
  output: string;
};

export interface DataGroupingTableParams {
  financial_model: string;
  primary_group: string | null;
  sub_group: string | null;
  output: string;
  start_date: string | null;
  end_date: string | null;
  filterId: string | null;
}

export type ConstituentReturnsParams = {
  financial_model: string;
  portfolio_dropdown: string;
  portfolio_type: string;
  selected_feature: string | null;
  output: string;
};

export type AttrConstituentReturnsParams = {
  financial_model: string;
  portfolio_dropdown: string;
  portfolio_type: string;
  output: string;
};

export type FeatureImportanceParams = {
  financial_model: string;
  start_date: string;
  end_date: string;
  output: string;
};

export type IndexAndValuesParams = {
  financial_model: string;
  output: string;
  filterId?: string;
};

export type ProfitAndLossParams = {
  financial_model: string;
  output: string;
};

export type ExposureAndForecastsParams = ConstituentReturnsParams;
export type NumericTableParams = IndexAndValuesParams;

type Params =
  | DataGroupingTableParams
  | ConstituentReturnsParams
  | AttrConstituentReturnsParams
  | FeatureImportanceParams
  | PortfolioMetricsParams
  | IndexAndValuesParams
  | ProfitAndLossParams;

interface Props {
  fetchData: { url: string; params: Params };
  filename: string;
  testId: string;
  cacheKey?: string;
}

const handleCSVFetch = async (fetchData: { url: string; params: Params }) => {
  const { data } = await api.get(fetchData.url, {
    params: fetchData.params,
  });

  if (typeof data === 'string') {
    return data;
  } else return 'No available data found';
};

export const DownloadCsvButton = ({ fetchData, filename, testId }: Props): ReactElement => {
  const [data, setData] = useState('');
  const mutation = useMutation(handleCSVFetch, {
    onSuccess: (data) => {
      setData(data), setTimeout(() => csvLink?.current?.link?.click());
    },
  });

  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  if (mutation.isError) {
    return <span data-testid="errorMessage">Unexpected error</span>;
  }

  return (
    <DownloadCsvButtonStyle>
      <IconButton
        onClick={() => {
          mutation.mutate(fetchData);
        }}
        data-testid={testId}
      >
        <Download />
      </IconButton>
      <CSVLink data={data} filename={filename} style={{ color: 'inherit' }} ref={csvLink} />
    </DownloadCsvButtonStyle>
  );
};

export default DownloadCsvButton;
