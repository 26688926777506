import styled from 'styled-components';

export const MatrixSpacer = styled.div`
  margin-top: 2rem;
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 334px;
  justify-content: center;
  width: 100%;
`;

export const Matrix = styled.div`
  font-size: 12px;
  height: 334px;
  padding-left: 50px;
  position: relative;
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const MatrixHeader = styled.div`
  padding-left: 100px;
`;

export const MatrixHeaderActual = styled.div`
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  border-right: 2px solid #ccc;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
`;

export const MatrixCols = styled.div`
  width: 100%;
  display: flex;
`;

export const MatrixHeaderColsItem = styled.div`
  width: ${100 / 3}%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #0589d6;
  color: white;
`;

export const MatrixLeftHeader = styled.div`
  text-align: center;
`;

export const MatrixLeftHeaderPredicted = styled.div`
  position: absolute;
  left: -80px;
  width: 215px;
  transform: rotate(270deg);
  height: 45px;
  bottom: 104px;
  line-height: 45px;
  border-right: 2px solid #ccc;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
`;

export const MatrixLeftHeaderItem = styled.div`
  height: 71.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  background: #0589d6;
  color: white;
`;

export const MatrixBody = styled.div`
  display: flex;
`;

export const MatrixBodyRow = styled.div`
  width: 100%;
  border-bottom: 2px solid #ccc;
  border-right: 2px solid #ccc;
  display: flex;
`;

export const MatrixBodyRowCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: ${100 / 3}%;
  & + & {
    border-left: 2px solid #ccc;
  }
`;

export const MatrixBodyRowGroup = styled.div`
  width: 100%;
`;
