import { colorWay } from 'theme';

type Data = {
  x: number[];
  y: number[];
};

type Scatter = {
  y: number[];
  x: number[];
  mode: string;
  type: string;
  marker: {
    color: number[];
    colorscale: Array<Array<number | string>>;
  };
};

type ReturnShape = Scatter[];

const formatToScatterShape = (data: Data): ReturnShape => {
  return [
    {
      x: data.y,
      y: data.x,
      mode: 'markers',
      type: 'scatter',
      marker: {
        color: data.x.map((_, i) => (i % 3 === 0 ? 1 : i % 2 === 0 ? 0.5 : 0)),
        colorscale: [
          [0, colorWay[0]],
          [0.5, colorWay[1]],
          [1, colorWay[2]],
        ],
      },
    },
  ];
};

export default formatToScatterShape;
