import { chartHoverLabelAttributes } from 'utils';

type ApiChartItem = {
  x: string[];
  y: number[];
  type: string;
  name: string;
};

type Chart = {
  marker: {
    color: string;
  };
  opacity: number;
  text: string;
  textposition: string;
  x: string[];
  y: number[];
  type: string;
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
  unselected: {
    marker: {
      opacity: number;
    };
  };
  selectedpoints: number[];
  hovertemplate: string;
};

type TargetShape = Chart[];

type FormatToBarchartShapeProps = { chartData: ApiChartItem[]; feature: string };

const sharedValues = {
  opacity: 0.6,
  textposition: 'outside',
  type: 'bar',
  hoverlabel: chartHoverLabelAttributes,
  selected: {
    marker: {
      opacity: 1,
    },
  },
};

const formatToBarchartShape = (props: FormatToBarchartShapeProps): TargetShape => {
  const selectedpoints = [props.chartData[0].x.indexOf(props.feature)];
  const unselectedMarkerOpacity = props.chartData[0].x.indexOf(props.feature) >= 0 ? 0.7 : 1;

  const targetShape = props.chartData.map((i, idx) => {
    const colour = i.name === 'q1' ? 'transparent' : 'blue';
    const differenceOfQuartiles = () => props.chartData[idx].y.map((y, ydx) => y - props.chartData[idx - 1].y[ydx]);
    const value = i.name === 'q1' ? i.y : differenceOfQuartiles();

    return {
      ...sharedValues,
      ...i,
      y: value,
      marker: {
        color: colour,
      },
      selectedpoints,
      unselected: {
        marker: {
          opacity: unselectedMarkerOpacity,
        },
      },
      text: i.name,
      hovertemplate: `%{x}: %{customdata:.2f}<br>${i.name}<extra></extra>`,
      customdata: i.y,
    };
  });

  return targetShape;
};

export default formatToBarchartShape;
