import React, { ReactElement, useState } from 'react';
import { Col } from 'utils/findColConfigLong';
import Fuse from 'fuse.js';
import styled from 'styled-components';

import { Checkbox, Button } from 'Common';
import { SearchBox, SearchBoxWrapper, SearchCloseButton, ActionButtonWrapper, StickyWrapper } from 'App/App.styles';
import { FilterWrapperStyle } from 'Common/Shared.styles';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { ReactComponent as CloseIcon } from 'icons/delete.svg';
import { listItemStyle } from 'cssVars';

type Props = {
  id: string;
  columns: Array<Col>;
  onColumnsChange: (visibleCols: Col[], allCols: Col[]) => void;
  onClose: () => void;
};

export type SettingSwitchChange = (arg: string) => void;

export interface MenuItemProps {
  index: number;
  title: string;
  key: string;
  visible: boolean;
  onChange?: SettingSwitchChange;
}

const fuseOptions = {
  shouldSort: true,
  keys: ['key'],
  threshold: 0.2,
};

const ListWrap = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const ListWrapItem = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 1.75rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;

  ${listItemStyle}
`;

const TableFilter = ({ id, columns, onColumnsChange }: Props): ReactElement => {
  const [searchText, setSearchText] = useState('');

  const nonIndexColumns = columns.filter((_, idx) => idx !== 0);

  const filteredColumns = searchText
    ? new Fuse(nonIndexColumns, fuseOptions).search(searchText).map((i) => i.item)
    : nonIndexColumns;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const clearSearch = () => setSearchText('');

  const toggleColumnHidden: SettingSwitchChange = (col) => {
    const newColumns = [...columns];
    const index = newColumns.findIndex((column) => column.dataKey === col);

    newColumns[index].hidden = !newColumns[index].hidden;

    const newVisibleColumns = newColumns.filter((col) => col.hidden === false);

    onColumnsChange(newVisibleColumns, newColumns);
  };

  const selectAll = () => {
    const newColumns = [...columns];
    const allSelectColumns = newColumns.map((column) => ({
      ...column,
      hidden: false,
    }));
    onColumnsChange(allSelectColumns, allSelectColumns);
  };

  const deselectAll = () => {
    const newColumns = [...columns];
    const allSelectColumns = newColumns.map((column, idx) => ({
      ...column,
      hidden: idx !== 0,
    }));
    onColumnsChange(allSelectColumns, allSelectColumns);
  };

  return (
    <FilterWrapperStyle data-testid={id}>
      <StickyWrapper top={0}>
        <SearchBoxWrapper>
          <SearchIcon />
          <SearchBox value={searchText} onChange={(e) => handleChange(e)} />
          <SearchCloseButton onClick={clearSearch} type="button">
            <CloseIcon />
          </SearchCloseButton>
        </SearchBoxWrapper>
        <ActionButtonWrapper>
          <Button unStyled onClick={selectAll}>
            Select All
          </Button>
          <Button unStyled onClick={deselectAll}>
            Deselect All
          </Button>
        </ActionButtonWrapper>
      </StickyWrapper>
      <ListWrap data-testid={`filters-${id}`}>
        {filteredColumns.length === 0
          ? 'No matches…'
          : filteredColumns
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((column) => (
                <ListWrapItem key={`filters-${id}${column.dataKey}`}>
                  <Checkbox
                    id={`${id}${column.title}`}
                    isChecked={!column.hidden}
                    label={column.title}
                    onChange={(event) => toggleColumnHidden(event)}
                    value={column.dataKey}
                  />
                </ListWrapItem>
              ))}
      </ListWrap>
    </FilterWrapperStyle>
  );
};

export default TableFilter;
