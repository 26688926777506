const HAS_PERFORMED_SEARCH = 'hasPerformedSearch';
const HAS_PERFORMED_OPTIMISATION = 'hasPerformedOptimisation';

const QUERY_KEYS = {
  ML_ACCURACY_MODEL_OPTIONS: 'ML_ACCURACY_MODEL_OPTIONS',
  ML_JOB_STATUS: 'ML_JOB_STATUS',
  ML_ROC_CURVE: 'ML_ROC_CURVE',
  ML_CONFUSION_MATRIX: 'ML_CONFUSION_MATRIX',
  ML_GROUP_SCORE_AGAINST_TARGET: 'ML_GROUP_SCORE_AGAINST_TARGET',
  ML_PAGE_MODEL_OPTIONS: 'ML_PAGE_MODEL_OPTIONS',
  ML_CONSTITUENT_RETURNS_TABLE: 'ML_CONSTITUENT_RETURNS_TABLE',
  ML_PERFORMANCE_CHART: 'ML_PERFORMANCE_CHART',
  ML_QUARTERLY_RETURNS_TABLE: 'ML_QUARTERLY_RETURNS_TABLE',
  THRESHOLD_JOB_STATUS: 'THRESHOLD_JOB_STATUS',
  THRESHOLD_METRICS: 'THRESHOLD_METRICS',
  THRESHOLD_RETURN_AND_CONCENTRATION: 'THRESHOLD_RETURN_AND_CONCENTRATION',
  OPTIMISATION_JOB_STATUS: 'OPTIMISATION_JOBS_STATUS',
  NEW_FLOW_MANUAL_JOB_STATUS: 'NEW_FLOW_MANUAL_JOB_STATUS',
  NEW_FLOW_OPTIMISATION_JOB_STATUS: 'NEW_FLOW_OPTIMISATION_JOB_STATUS',
};

const CONFIGURATION_VIEWS = {
  SELECT_DATASET: 'SELECT_DATASET',
  CREATE_DATASET: 'CREATE_DATASET',
  DATA_EXPLORATION: 'DATA_EXPLORATION',
  FEATURE_IMPORTANCE: 'FEATURE_IMPORTANCE',
  FACTOR_EXPLORATION: 'FACTOR_EXPLORATION',
  SELECT_PORTFOLIO: 'SELECT_PORTFOLIO',
  CREATE_PORTFOLIO: 'CREATE_PORTFOLIO',
  RUN_ANALYSIS: 'RUN_ANALYSIS',
};

export default {
  HAS_PERFORMED_SEARCH,
  HAS_PERFORMED_OPTIMISATION,
  QUERY_KEYS,
  CONFIGURATION_VIEWS,
};
