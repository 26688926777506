import React, { ReactElement } from 'react';

import { Column, Card, CardHeaderNew, CardHeaderNewTitle, CardHeight, Spacer } from 'Common/Shared.styles';

import Heatmap from './Heatmap';

import { FeatureCorrelation as Props } from '../LayoutBuilder';

const FeatureCorrelation = ({
  id,
  options,
  financialModel,
  dateRange,
  presentation,
  cacheKey,
  isPageError,
}: Props): ReactElement => {
  return (
    <Column span={presentation.span}>
      <Card cardHeight={CardHeight.Full}>
        <CardHeaderNew>
          <CardHeaderNewTitle>Feature Correlation</CardHeaderNewTitle>
        </CardHeaderNew>
        <Spacer>
          <Heatmap
            financialModel={financialModel}
            dateRange={dateRange}
            features={options.filter((o) => o.isChecked).map((i) => i.value as string)}
            cacheKey={cacheKey + id}
            isPageError={isPageError}
          />
        </Spacer>
      </Card>
    </Column>
  );
};

export default FeatureCorrelation;
