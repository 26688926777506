import styled from 'styled-components';
import { Button } from 'Common';

export const StyledSection = styled.section`
  height: calc(100vh - 72px);
  width: 100vw;
  position: fixed;
  top: 72px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  width: 50vw;
  min-width: 450px;
  background: white;
  border-radius: 0.25rem;
  display: grid;
  grid-template-rows: auto 1fr;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2.25rem 1.75rem;
`;

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 3.5rem;
justify-content: space-between';
`;

export const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RegexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 1.5rem;
`;

export const Input = styled.input`
  padding: 1rem;
  border-radius: 0.25rem;
  border: ${({ theme }) => `2px solid ${theme.colors.lightGrey}`};
`;

export const PasswordContainer = styled.div`
  display: grid;
  align-items: center;
`;

export const PasswordInput = styled(Input)`
  grid-column: 1/2;
  grid-row: 1/2;
`;

export const VisibilityButton = styled(Button)`
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: right;
  margin-right: 2rem;
  width: 1rem;
  justify-self: end;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-weight: bold;
  font-size: 0.85rem;
`;

export const Header = styled.div`
  padding: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem 0.25rem 0 0;
`;
