type Z = number[];

type Props = { x: string[]; y: string[]; z: Z[] };

type Annotation = {
  font: {
    color: string;
    size: number;
  };
  showarrow: boolean;
  text: number;
  x: string;
  xref: string;
  y: string;
  yref: string;
};

type ReturnShape = Annotation[];

const formatToAnnotations = ({ x, y, z }: Props): ReturnShape => {
  const annotations = [];

  for (let i = 0; i < y.length; i++) {
    for (let j = 0; j < x.length; j++) {
      const val = z[i][j];

      const result = {
        xref: 'x1',
        yref: 'y1',
        x: x[j],
        y: y[i],
        text: val,
        font: {
          size: 10,
          color: '#000',
        },
        showarrow: false,
      };

      annotations.push(result);
    }
  }

  return annotations;
};

export default formatToAnnotations;
