import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from 'theme';

interface Props {
  onToggle: () => void;
  isToggled: boolean;
}

export const Toggle = styled.button<{ isToggled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: none;
  background: ${({ theme }) => theme.colors.white};
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  position: fixed;
  z-index: 2000;
  top: 84px;
  left: ${({ isToggled }) => (isToggled ? 'calc(max(25% - 1rem, 320px - 1rem))' : '1.75rem')};
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    left: ${({ isToggled }) => (isToggled ? 'calc(100% - 3.75rem)' : '1.75rem')};
  }
`;

const TogglePanelControl = ({ onToggle, isToggled }: Props): ReactElement => {
  return (
    <Toggle
      data-testid="toggleConfigPanel"
      type="button"
      onClick={onToggle}
      title="Toggle Config Visibility"
      isToggled={isToggled}
    >
      {!isToggled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          height="10px"
          width="10px"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          height="10px"
          width="10px"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
      )}
    </Toggle>
  );
};

export default TogglePanelControl;
