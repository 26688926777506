import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Column,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { colorWay } from 'theme';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { FeatureImportanceOverTime as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchChartDataProps = {
  financialModel: FinancialModels;
  startDate: string;
  endDate: string;
  mlAlgorithim: string;
  signal?: AbortSignal;
  filterId?: string;
};

interface ChartDataProps {
  default_ml_model: string;
  ml_model_options: { label: string; value: string }[];
  plot_data: {
    x: string[];
    y: number[];
    name: string;
    type: string;
  }[];
}

const fetchChartData = async ({
  financialModel,
  startDate,
  endDate,
  mlAlgorithim,
  signal,
  filterId,
}: FetchChartDataProps): Promise<ChartDataProps> => {
  const { data } = await api.get('/components/historical_feature_importance_over_time', {
    params: {
      financial_model: financialModel,
      start_date: startDate,
      end_date: endDate,
      ml_model: mlAlgorithim || null,
      filter_id: filterId,
    },
    signal,
  });

  return data;
};

const FeatureImportanceOverTime = ({
  id,
  presentation,
  financialModel,
  startDate,
  endDate,
  cacheKey,
  isPageError,
  options = [],
  filterId,
}: Props): ReactElement => {
  const [mlAlgorithim, setMlAlgorithim] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const payload = { financialModel, startDate, endDate, mlAlgorithim, filterId };
  const selectedFeatures = options.filter((i) => i.isChecked).map((i) => i.value);

  const { isLoading, data, isError } = useQuery([id, cacheKey, payload], ({ signal }) =>
    fetchChartData({ ...payload, signal }),
  );

  if (isError || isPageError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const chartLayout = {
    margin: {
      t: 0,
      r: 0,
      l: 28,
      b: 0,
    },
    colorway: colorWay,
    legend: { orientation: 'h' },
    hovermode: 'closest',
    showlegend: true,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    autosize: true,
    datarevision: new Date(),
  };

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Feature importance overtime</CardHeaderNewTitle>
          {!isLoading && (
            <CardHeaderActions>
              <Select
                testId="selectMLModel"
                isControlled={true}
                options={data?.ml_model_options || []}
                onChange={(e) => setMlAlgorithim(e.target.value)}
                value={mlAlgorithim || data?.default_ml_model || ''}
              />
              <IconButton data-testid="showFeatureImportanceOverTimeModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          )}
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="featureImportanceOvertime"
            data={data?.plot_data.filter((i) => selectedFeatures.includes(i.name)) || null}
            isLoading={isLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Feature importance overtime</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="featureImportanceOvertime"
              data={data?.plot_data.filter((i) => selectedFeatures.includes(i.name)) || null}
              isLoading={isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default FeatureImportanceOverTime;
