const compareStrings = (a: string, b: string): number => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const sortStringsAlphabetically = (columns: string[]): string[] => {
  return columns.sort(compareStrings);
};

export default sortStringsAlphabetically;
