import React, { ReactElement, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Navigation from './Navigation';
import CookiePolicy from './CookiePolicy';
import SignIn from './SignIn';

const ManageDataset = lazy(() => import('./ManageDataset'));
const PortfolioConstruction = lazy(() => import('./PortfolioConstruction'));
const FeatureEngineering = lazy(() => import('./FeatureEngineering'));
const PortfolioEvaluation = lazy(() => import('./PortfolioEvaluation'));
const Optimisation = lazy(() => import('./Optimisation'));

import { PageLoader } from 'Common';

export enum ConstructionMethods {
  THRESHOLDS = 'thresholds',
  OPTIMISATION = 'optimisation',
  MACHINE_LEARNING = 'machine_learning',
}

interface Props {
  isSignedIn: boolean;
}

const App = ({ isSignedIn }: Props): ReactElement => {
  return (
    <div id="main" data-testid="mainContent">
      {isSignedIn && <Navigation />}

      <Switch>
        <Route
          path="/sign-in"
          render={() => {
            return isSignedIn ? <Redirect to="/manage-dataset" /> : <SignIn />;
          }}
        />

        <Route
          path="/manage-dataset"
          render={() => {
            return isSignedIn ? (
              <Suspense fallback={<PageLoader />}>
                <ManageDataset />
              </Suspense>
            ) : (
              <Redirect to="/sign-in" />
            );
          }}
        />

        <Route
          path="/feature-engineering"
          render={() => {
            return isSignedIn ? (
              <Suspense fallback={<PageLoader />}>
                <FeatureEngineering />
              </Suspense>
            ) : (
              <Redirect to="/sign-in" />
            );
          }}
        />

        <Route
          path="/portfolio-construction"
          render={() => {
            return isSignedIn ? (
              <Suspense fallback={<PageLoader />}>
                <PortfolioConstruction />
              </Suspense>
            ) : (
              <Redirect to="/sign-in" />
            );
          }}
        />

        <Route
          path="/portfolio-evaluation"
          render={() => {
            return isSignedIn ? (
              <Suspense fallback={<PageLoader />}>
                <PortfolioEvaluation />
              </Suspense>
            ) : (
              <Redirect to="/sign-in" />
            );
          }}
        />

        <Route
          path="/optimisation"
          render={() => {
            return isSignedIn ? (
              <Suspense fallback={<PageLoader />}>
                <Optimisation />
              </Suspense>
            ) : (
              <Redirect to="/sign-in" />
            );
          }}
        />

        <Route
          exact
          path="/"
          render={() => {
            return isSignedIn ? <Redirect to="/manage-dataset" /> : <Redirect to="/sign-in" />;
          }}
        />

        <Redirect to="/sign-in" />
      </Switch>

      <CookiePolicy />
    </div>
  );
};

export default App;
