import React, { ReactElement, useRef } from 'react';
import { useQuery } from 'react-query';

import { Chart } from 'Common';
import { api, useDivSize } from 'utils';

import formatToFeatureCorrelationHeatmap from './formatToFeatureCorrelationHeatmap';
import formatToAnnotations from './formatToAnnotations';

import { ChartWrapper } from './Heatmap.styles';
import { FinancialModels } from 'types/shared.types';

type FetchProps = {
  dateRange: {
    startIndex: number;
    endIndex: number;
  };
  features: string[];
  financialModel: string;
  filterId: string;
};

interface Props {
  cacheKey: string;
  dateRange: {
    startIndex: number;
    endIndex: number;
  };
  features: string[];
  financialModel: FinancialModels;
  isPageError?: boolean;
  filterId?: string;
}

const fetchHeatMapData = async ({ dateRange, features, financialModel, filterId }: FetchProps) => {
  const { data } = await api.post(`/feature_correlation_heatmap`, {
    dates_correl: [dateRange.startIndex, dateRange.endIndex],
    x_feature: features,
    financial_model: financialModel,
    filter_id: filterId || null,
  });
  return {
    chartData: formatToFeatureCorrelationHeatmap(data),
    annotations: formatToAnnotations({ x: data.x, y: data.x, z: data.z }),
  };
};

const BarChart = ({
  dateRange,
  features,
  financialModel,
  cacheKey,
  isPageError,
  filterId = '',
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const descriptionBarChartValues = { dateRange, features, financialModel, filterId };
  const { isError, data, isLoading } = useQuery([cacheKey, descriptionBarChartValues], () =>
    fetchHeatMapData(descriptionBarChartValues),
  );

  if (isError || isPageError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const annotations = data ? data.annotations : [];

  return (
    <ChartWrapper ref={parentRef}>
      <Chart
        testId="heatmap"
        isLoading={isLoading}
        data={data?.chartData || null}
        layout={{
          height,
          width,
          margin: {
            t: 0,
            r: 0,
            l: 0,
            b: 0,
          },
          yaxis: {
            showspikes: true,
            spikemode: 'toaxis',
            autorange: 'reversed',
            automargin: true,
            tickangle: 0,
          },
          xaxis: {
            showspikes: true,
            spikemode: 'toaxis',
            automargin: true,
            tickangle: 30,
            side: 'top',
          },
          plot_bgcolor: 'transparent',
          paper_bgcolor: 'transparent',
          annotations,
          datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
        }}
      />
    </ChartWrapper>
  );
};

export default BarChart;
