type Z = number[];
type Features = string;

type Props = {
  x: Features[];
  y: Features[];
  z: Z[];
};

type CS = number | string;
type ColourScaleItem = CS[];

type Figure = {
  colorscale: ColourScaleItem[];
  reversescale: boolean;
  showscale: boolean;
  x: Features[];
  y: Features[];
  z: Z[];
  xgap: number;
  ygap: number;
  type: string;
  hovertemplate: string;
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
};

type ReturnShape = Figure[];

const formatToFeatureCorrelationHeatmap = (props: Props): ReturnShape => {
  const shape = [
    {
      type: 'heatmap',
      reversescale: false,
      showscale: true,
      x: props.x,
      y: props.y,
      z: props.z,
      xgap: 2,
      ygap: 2,
      hoverlabel: {
        bgcolor: '#ffec9f',
        bordercolor: '#333333',
        font: {
          color: '#333333',
        },
      },
      hovertemplate: 'x: %{x}<br>y: %{y}<br>Correlation: %{z: .2f}<extra></extra>',
      colorscale: [
        [0, '#b9b6e9'],
        [0.2, '#8ac9ed'],
        [0.4, '#ecf8f5'],
        [0.6, '#fffbe7'],
        [0.8, '#faccad'],
        [1, '#ffabc2'],
      ],
    },
  ];

  return shape;
};

export default formatToFeatureCorrelationHeatmap;
