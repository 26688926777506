import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api, isUnauthenticated, useDivSize } from 'utils';
import { Chart, Modal, Select, DropdownButton, Checkbox } from 'Common';
import {
  Card,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  ChartWrapper,
  ChartHeight,
  Pill,
  Column,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';

import { FeatureFormWrapper, SectionTitle, SmallSpacer, ListWrapItem } from './DistributionByFeature.styles';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';

import formatToViolinDataShape from './formatToViolinDataShape';

import { FinancialModels } from 'types/shared.types';
import { colorWay } from 'theme';
import { DistributionByFeature as Props } from '../LayoutBuilder';

interface FetchChartData {
  startDate: string;
  endDate: string;
  financialModel: FinancialModels;
  selectedFeature: string;
  excludeOutliers: boolean;
  groupBy: string;
  filterId: string;
  signal?: AbortSignal;
}

interface Point {
  x: string;
}

interface HandleClickProps {
  points: Point[];
}

const LegendWrap = styled.div`
  position: relative;
`;

const fetchChartData = async ({
  startDate,
  endDate,
  financialModel,
  selectedFeature,
  groupBy,
  excludeOutliers,
  filterId,
  signal,
}: FetchChartData) => {
  const { data } = await api.get(`/components/distribution_per_numeric_feature`, {
    params: {
      financial_model: financialModel,
      start_date: startDate,
      end_date: endDate,
      selected_feature: selectedFeature,
      group_by: groupBy,
      exclude_outliers: excludeOutliers,
      filter_id: filterId || null,
    },
    signal,
  });

  return formatToViolinDataShape(data.data || []);
};

const grouping = [
  { label: 'Region', value: 'Region' },
  { label: 'Country', value: 'Country' },
  { label: 'Sector', value: 'Sector' },
];

const DistributionByFeature = ({
  id,
  endDate,
  features,
  financialModel,
  selectedFeature,
  startDate,
  onChangeFeature,
  onViolinItemClick,
  presentation,
  cacheKey,
  filterId = '',
}: Props): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [groupBy, setGroupBy] = useState(grouping[0].value);
  const [excludeOutliers, setExcludeOutliers] = useState(true);

  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const payload = {
    startDate,
    endDate,
    financialModel,
    selectedFeature,
    excludeOutliers,
    groupBy,
    filterId,
  };

  const { status, data, error } = useQuery([id, cacheKey, payload], ({ signal }) =>
    fetchChartData({ ...payload, signal }),
  );

  if (status === 'error' || isUnauthenticated(error)) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const sharedChartLayout = {
    barmode: 'relative',
    plot_bgcolor: 'white',
    showlegend: false,
    datarevision: new Date(),
    yaxis: {
      showgrid: false,
      ticklen: 4,
      tickcolor: 'white',
    },
    xaxis: {
      automargin: true,
      tickfont: {
        size: 10,
      },
    },
    margin: {
      t: 0,
      l: 36,
      r: 0,
      b: 0,
    },
    colorway: colorWay,
  };

  const handleFeatureClick = ({ points }: HandleClickProps) => {
    if (points.length > 0) {
      onViolinItemClick({
        primaryGroup: groupBy,
        subGroup: points[0].x,
      });
    }
  };

  const handleExcludeOutliers = () => {
    setExcludeOutliers(!excludeOutliers);
  };

  return (
    <Column span={presentation.span}>
      <Card>
        <LegendWrap>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Distribution by <Pill>{selectedFeature}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <DropdownButton
                icon={<SettingsIcon />}
                title="Show Chart Filters"
                testId="showDistributionPerNumericFeatureInfo"
              >
                <FeatureFormWrapper>
                  <SectionTitle>Filters</SectionTitle>
                  <SmallSpacer>
                    <Select
                      id="feature"
                      name="feature"
                      testId="feature"
                      isControlled={true}
                      value={selectedFeature}
                      options={features}
                      onChange={(event) => onChangeFeature(event.target.value)}
                    />
                  </SmallSpacer>

                  <SmallSpacer>
                    <Select
                      id="grouping"
                      name="grouping"
                      testId="grouping"
                      isControlled={true}
                      value={groupBy}
                      options={grouping}
                      onChange={(event) => {
                        setGroupBy(event.target.value);
                        onViolinItemClick({ primaryGroup: event.target.value, subGroup: '' });
                      }}
                    />
                  </SmallSpacer>
                  <ListWrapItem>
                    <Checkbox
                      id="excludeOutliersCheckbox"
                      isChecked={excludeOutliers}
                      label="Exclude Outliers"
                      onChange={handleExcludeOutliers}
                      value={String(excludeOutliers)}
                    />
                  </ListWrapItem>
                </FeatureFormWrapper>
              </DropdownButton>
              <IconButton data-testid="showDitributionPerNumericFeatureModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        </LegendWrap>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="ditributionPerNumericFeature"
            data={data}
            isLoading={status === 'loading'}
            onClick={handleFeatureClick}
            layout={{
              ...sharedChartLayout,
              height,
              width,
            }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Distribution by <Pill>{selectedFeature}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <DropdownButton
                icon={<SettingsIcon />}
                title="Information"
                testId="showDistributionPerNumericFeatureFiltersModal"
              >
                <FeatureFormWrapper>
                  <SectionTitle>Filters</SectionTitle>
                  <SmallSpacer>
                    <Select
                      id="feature"
                      name="feature"
                      testId="feature"
                      isControlled={true}
                      value={selectedFeature}
                      options={features}
                      onChange={(event) => onChangeFeature(event.target.value)}
                    />
                  </SmallSpacer>

                  <SmallSpacer>
                    <Select
                      id="grouping"
                      name="grouping"
                      testId="grouping"
                      isControlled={true}
                      value={groupBy}
                      options={grouping}
                      onChange={(event) => {
                        setGroupBy(event.target.value);
                        onViolinItemClick({ primaryGroup: event.target.value, subGroup: '' });
                      }}
                    />
                  </SmallSpacer>
                  <ListWrapItem>
                    <Checkbox
                      id="excludeOutliersCheckbox"
                      isChecked={excludeOutliers}
                      label="Exclude Outliers"
                      onChange={handleExcludeOutliers}
                      value={String(excludeOutliers)}
                    />
                  </ListWrapItem>
                </FeatureFormWrapper>
              </DropdownButton>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="ditributionPerNumericFeatureModal"
              data={data}
              isLoading={status === 'loading'}
              layout={{
                ...sharedChartLayout,
              }}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default DistributionByFeature;
