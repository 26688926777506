import React, { useState, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { Auth } from 'aws-amplify';

import { Button, Text } from 'Common';
import {
  Wrapper,
  Label,
  Input,
  SwitchViewButton,
  StyledForm,
  PasswordContainer,
  PasswordInput,
  VisibilityButton,
  Row,
} from '../SignIn.styles';
import theme from 'theme';

import { ReactComponent as InvisibilityIcon } from 'icons/invisibility.svg';
import { ReactComponent as VisibilityIcon } from 'icons/visibility.svg';

interface ChangePasswordProps {
  backToForgotPassword: () => void;
  email: string;
  destination: string;
  backToLogin: () => void;
}

interface SubmitChangePasswordProps {
  email: string;
  code: string;
  newPassword: string;
}

const ForgotPassword = ({
  backToForgotPassword,
  email,
  destination,
  backToLogin,
}: ChangePasswordProps): ReactElement => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMatching, setIsMatching] = useState(true);

  const updateCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const updateNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePassword = async ({ email, code, newPassword }: SubmitChangePasswordProps) => {
    const request = await Auth.forgotPasswordSubmit(email, code, newPassword);
    return request;
  };

  const handleBlur = () => {
    if (newPassword === confirmPassword) {
      setIsMatching(true);
    } else {
      setIsMatching(false);
    }
  };

  const mutation = useMutation(handleChangePassword, {
    onError: (error: Error) => {
      setValid(false);
      console.log(error.message);
    },
    onSuccess: () => {
      setSuccess(true);
      setTimeout(() => backToLogin(), 1500);
    },
  });

  const { error } = mutation;

  return (
    <StyledForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        mutation.mutate({ email, code, newPassword });
      }}
    >
      <Wrapper>
        <Text
          textType="h2"
          color={theme.colors.darkGrey}
          align="left"
          text={`We have sent a password reset code by email to ${destination}. Enter it below to reset your password`}
        />
      </Wrapper>
      <Wrapper>
        <Label>Confirmation Code</Label>
        <Input
          type="text"
          name="confirmationCode"
          value={code}
          onChange={(e) => updateCode(e)}
          autoComplete="off"
          autoCapitalize="off"
        />
      </Wrapper>
      <Wrapper className="password">
        <Label>New Password</Label>
        <PasswordContainer>
          <PasswordInput
            type={isVisible ? 'text' : 'password'}
            name="password"
            value={newPassword}
            onChange={(e) => updateNewPassword(e)}
            autoComplete="off"
            autoCapitalize="off"
          />
          <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
            {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        </PasswordContainer>
      </Wrapper>
      <Wrapper className="password">
        <Row>
          <Label>Confirm Password</Label>
          {!isMatching && <Text textType="p" color={theme.colors.red} align="right" text="Passwords do not match" />}
        </Row>
        <PasswordContainer>
          <PasswordInput
            type={isVisible ? 'text' : 'password'}
            name="password"
            value={confirmPassword}
            onChange={(e) => updateConfirmPassword(e)}
            onBlur={handleBlur}
            autoComplete="off"
            autoCapitalize="off"
          />
          <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
            {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        </PasswordContainer>
        <SwitchViewButton unStyled onClick={backToForgotPassword}>
          Re-enter Email Address
        </SwitchViewButton>
      </Wrapper>
      {!valid && error && <Text textType="p" color={theme.colors.red} align="right" text={error.message} />}
      {success && <Text textType="p" color={theme.colors.green} align="right" text="Successfully changed password" />}
      <Button
        theme="primary"
        size="large"
        type="submit"
        isDisabled={!code || !newPassword || !confirmPassword || newPassword !== confirmPassword || mutation.isLoading}
      >
        Reset Password
      </Button>
    </StyledForm>
  );
};

export default ForgotPassword;
