import { Auth } from 'aws-amplify';

import { isAuthenticated, api } from 'utils';

const getAvailableModels = async () => {
  const { data } = await api.get('/v2/available_models');
  return (data && data.financial_models) || [];
};

export interface UserReturnShape {
  isAuthed: boolean;
  financialModels: string[];
  user: {
    username: string;
    email: string;
    group: string;
  } | null;
}

const handleAuthentication = async (): Promise<UserReturnShape> => {
  const isAuthed = await isAuthenticated();

  if (!isAuthed) {
    return {
      isAuthed,
      financialModels: [],
      user: null,
    };
  }

  const financialModels = await getAvailableModels();

  const {
    data: { preferred_group },
  } = await api.get('/v2/current_user');

  const {
    username,
    attributes: { email },
  } = await Auth.currentUserInfo();

  const payload = {
    isAuthed,
    financialModels,
    user: {
      username,
      email,
      group: preferred_group,
    },
  };

  return payload;
};

export default handleAuthentication;
