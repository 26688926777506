import React, { ReactElement, ChangeEvent } from 'react';
import styled from 'styled-components';

import { ReactComponent as Checked } from './check_box.svg';
import { ReactComponent as UnChecked } from './check_box_outline.svg';

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  width: 100%;
  height: 100%;

  svg {
    width: 1.1rem;
    margin-right: 0.25rem;
  }
`;

const Input = styled.input`
  display: none;
`;

const Text = styled.span<{ checked: boolean }>`
  font-size: 0.9rem;
  color: ${({ theme, checked }) => (checked ? '#000' : theme.colour.grey)};
`;

type Props = {
  id: string;
  label: string;
  value: string | number;
  isChecked: boolean;
  onChange: (arg: string) => void;
  isDisabled?: boolean;
};

const Checkbox = ({ id, value, label, isChecked, onChange, isDisabled = false }: Props): ReactElement => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Label htmlFor={id} data-testid={id}>
      <Input
        type="checkbox"
        id={id}
        name={id}
        value={value}
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      {isChecked ? <Checked /> : <UnChecked />}
      <Text checked={isChecked}>{label}</Text>
    </Label>
  );
};

export default Checkbox;
