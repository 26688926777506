export type TableColumn = {
  Header: string;
  accessor: string;
};

export type Option = {
  label: string;
  value: string | number;
};

export type ColString = {
  Header: string;
  accessor: string;
  width: number;
};

export type ColNumber = {
  Header: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: (arg: any) => number;
  width: number;
  sortType: string;
};

export enum FinancialModels {
  EQUITY_MODEL = 'equity_model',
  RATINGS_DOWNGRADE_MODEL = 'ratings_downgrade_model',
  SHARP_PRICE_DROP_MODEL = 'sharp_price_drop_model',
}

export enum ConstructionMethods {
  THRESHOLDS = 'thresholds',
  OPTIMISATION = 'optimisation',
  MACHINE_LEARNING = 'machine learning',
}

export enum financialModelMapping {
  equity_model = 'equity model',
  ratings_downgrade_model = 'ratings downgrade model',
  sharp_price_drop_model = 'sharp price drop model',
}

export enum constructionMethodMapping {
  thresholds = 'thresholds',
  optimisation = 'optimisation',
  machine_learning = 'machine learning',
}

export enum Direction {
  Upper = 'upper',
  Lower = 'lower',
}

export interface Dataset {
  id: string;
  name: string;
  dateConstructed: string;
  filters?: { key: string; type: string; values: string[] }[];
}

export interface SelectedFeature {
  id: string;
  label: string;
  lower: number;
  upper: number;
  step: number;
  operator: string;
}
