import DefaultColumnFilter from 'Common/Table/DefaultColumnFilter';
import NumberInputFilter from 'Common/Table/NumberFilter';

export interface Col {
  Header: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: (arg: any) => number | string;
  width: number;
  sortType: string;
  filter: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Filter: any;
}

type ColumnConfig = {
  [key: string]: number;
};

type ColumnConfigBool = {
  [key: string]: boolean;
};

const columnWidths: ColumnConfig = {
  sector: 260,
  country: 160,
  portfolio: 250,
  ml_algo: 200,
  ticker: 150,
};

const columnsWithStrings: ColumnConfigBool = {
  sector: true,
  country: true,
  portfolio: true,
  ml_algo: true,
  ticker: true,
  index: true,
};

const findColConfig = (keyName: string): Col => {
  const isColumnWithString = columnsWithStrings[keyName.toLowerCase()];

  return {
    width: columnWidths[keyName.toLowerCase()] || 120,
    accessor: (d: { [key: string]: string | number }) => {
      if (d[keyName] === null) {
        return -Infinity;
      }
      if (typeof d[keyName] === 'number') {
        return Number(d[keyName]);
      }

      return String(d[keyName]);
    },
    id: keyName,
    Header: keyName,
    sortType: 'basic',
    filter: isColumnWithString ? 'fuzzyText' : 'between',
    Filter: isColumnWithString ? DefaultColumnFilter : NumberInputFilter,
  };
};

export default findColConfig;
