import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';

import { api } from 'utils';
import { CardLoader, Modal } from 'Common';
import {
  Card,
  Spacer,
  CardHeaderNew,
  CardHeaderNewTitle,
  Pill,
  Column,
  ChartHeight,
  ChartWrapper,
  CardHeaderActions,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';

import {
  Matrix,
  MatrixHeader,
  MatrixHeaderActual,
  MatrixCols,
  MatrixHeaderColsItem,
  MatrixLeftHeader,
  MatrixLeftHeaderPredicted,
  MatrixLeftHeaderItem,
  MatrixBody,
  MatrixBodyRow,
  MatrixBodyRowCell,
  MatrixBodyRowGroup,
  LoaderWrapper,
} from './ConfusionMatrix.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { ConfusionMatrix as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchData = {
  financialModel: FinancialModels;
  selectedModel: string;
  signal?: AbortSignal;
};

const fetchData = async ({ financialModel, selectedModel, signal }: FetchData) => {
  const { data } = await api.get('/v2/confusion_matrix_data', {
    params: {
      financial_model: financialModel,
      ml_model: selectedModel,
    },
    signal,
  });

  return data.plot_data;
};

const numberFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Tooltips = [
  ['TP', 'FN', 'P = (TP + FN) = Actual Total Positives'],
  ['FP', 'TN', 'N = (FP + TN) = Actual Total Negatives'],
  ['Predicted Total Negatives', 'Predicted Total Negatives', 'Total'],
];

const ConfusionMatrix = ({ id, financialModel, cacheKey, selectedModel, presentation }: Props): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, data, isError } = useQuery([id, cacheKey, { financialModel, selectedModel }], ({ signal }) =>
    fetchData({ financialModel, selectedModel, signal }),
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const memoizedRows = useMemo(
    () =>
      data &&
      data.map((i: number[], rowIndex: number) => (
        <MatrixBodyRow key={`matrix-row-${rowIndex}`}>
          {i.map((o: number, cellIndex: number) => {
            return (
              <MatrixBodyRowCell key={`matrix-cell-${cellIndex}`} data-tip={Tooltips[rowIndex][cellIndex]}>
                {numberFormatter.format(o)}
              </MatrixBodyRowCell>
            );
          })}
        </MatrixBodyRow>
      )),
    [data],
  );

  if (isError) {
    return (
      <Card>
        <Spacer data-testid="errorMessage">An error has occured with the Confusion Matrix</Spacer>
      </Card>
    );
  }

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>
            Confusion Matrix <Pill>{selectedModel}</Pill>
          </CardHeaderNewTitle>
          <CardHeaderActions>
            <IconButton data-testid="confusionMatrixModalButton" onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        {isLoading ? (
          <LoaderWrapper data-testid="confusionMatrixLoader">
            <CardLoader />
          </LoaderWrapper>
        ) : (
          <ChartWrapper chartHeight={ChartHeight.Half}>
            <Matrix>
              <MatrixHeader>
                <MatrixHeaderActual>Actual</MatrixHeaderActual>
                <MatrixCols>
                  <MatrixHeaderColsItem>No Downgrade</MatrixHeaderColsItem>
                  <MatrixHeaderColsItem>Downgrade</MatrixHeaderColsItem>
                  <MatrixHeaderColsItem>Total</MatrixHeaderColsItem>
                </MatrixCols>
              </MatrixHeader>

              <MatrixBody>
                <MatrixLeftHeader>
                  <MatrixLeftHeaderPredicted>Predicted</MatrixLeftHeaderPredicted>
                  <div>
                    <MatrixLeftHeaderItem>
                      <span>No Downgrade</span>
                    </MatrixLeftHeaderItem>
                    <MatrixLeftHeaderItem>
                      <span>Downgrade</span>
                    </MatrixLeftHeaderItem>
                    <MatrixLeftHeaderItem>
                      <span>Total</span>
                    </MatrixLeftHeaderItem>
                  </div>
                </MatrixLeftHeader>

                <MatrixBodyRowGroup>{memoizedRows || []}</MatrixBodyRowGroup>
              </MatrixBody>
            </Matrix>
            <ReactTooltip />
          </ChartWrapper>
        )}

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Confusion Matrix <Pill>{selectedModel}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            {!isLoading && (
              <Matrix>
                <MatrixHeader>
                  <MatrixHeaderActual>Actual</MatrixHeaderActual>
                  <MatrixCols>
                    <MatrixHeaderColsItem>No Downgrade</MatrixHeaderColsItem>
                    <MatrixHeaderColsItem>Downgrade</MatrixHeaderColsItem>
                    <MatrixHeaderColsItem>Total</MatrixHeaderColsItem>
                  </MatrixCols>
                </MatrixHeader>

                <MatrixBody>
                  <MatrixLeftHeader>
                    <MatrixLeftHeaderPredicted>Predicted</MatrixLeftHeaderPredicted>
                    <div>
                      <MatrixLeftHeaderItem>
                        <span>No Downgrade</span>
                      </MatrixLeftHeaderItem>
                      <MatrixLeftHeaderItem>
                        <span>Downgrade</span>
                      </MatrixLeftHeaderItem>
                      <MatrixLeftHeaderItem>
                        <span>Total</span>
                      </MatrixLeftHeaderItem>
                    </div>
                  </MatrixLeftHeader>

                  <MatrixBodyRowGroup>{memoizedRows || []}</MatrixBodyRowGroup>
                </MatrixBody>
              </Matrix>
            )}
            <ReactTooltip />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default ConfusionMatrix;
