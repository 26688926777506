import { chartHoverLabelAttributes } from 'utils';

type Props = {
  [key: string]: {
    x: string[];
    y: number[];
  };
};

type Violin = {
  name: string;
  type: string;
  x: string[];
  y: number[];
  box: {
    visible: boolean;
  };
  line: {
    color: string;
  };
  meanline: {
    visible: boolean;
  };
  hoverlabel: {
    bgcolor: string;
    bordercolor: string;
    font: {
      color: string;
    };
  };
};

type ReturnShape = Violin[];

const formatToViolinDataShape = (props: Props): ReturnShape => {
  const objectToArray = Object.entries(props);

  const shape = objectToArray.map(([key, value]) => ({
    name: key,
    type: 'violin',
    x: value.x,
    y: value.y,
    box: {
      visible: true,
    },
    line: {
      color: 'dark grey',
    },
    meanline: {
      visible: true,
    },
    hoverlabel: chartHoverLabelAttributes,
  }));

  return shape;
};

export default formatToViolinDataShape;
