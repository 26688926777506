export const colorWay = ['#33CCFF', '#434967', '#9B97B2', '#42BB5E', '#D17B88'];

export default {
  spacer: '3.25rem',
  spacerSmall: '1.5rem',

  colour: {
    primary: '#434967',
    primaryDark: '#202a44',
    grey: '#606060',
    lightGrey: '#F3F4F4',
    green: 'rgb(102, 194, 165)',
    peach: 'rgb(252, 141, 98)',
  },

  colors: {
    black: '#202125',
    white: '#F7FBFF',
    darkGrey: '#56585B',
    grey: '#E5E5E5',
    lightGrey: '#EFF3F9',
    primary: '#33CCFF',
    primaryDark: '#434967',
    red: '#FF6249',
    green: '#42BB5E',
  },

  fontSizes: {
    medium: '1',
    small: '0.875rem',
    extraSmall: '0.75rem',
  },

  breakpoints: {
    mobile: '720px',
    tablet: '1024px',
    desktop: '1230px',
  },
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1);',
};
