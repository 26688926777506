import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  appearance: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const Default = styled.div`
  background: white;
  box-shaddow: 3px 3px 3px #ddd;
  padding: 0.5rem 1rem 0.75rem;
  border-radius: 0.3rem;
  margin-right: 4px;
`;

const ToastBlock = ({ appearance, children }: Props): ReactElement => {
  if (appearance === 'info') {
    return <Default>{children}</Default>;
  }

  return <Default>{children}</Default>;
};

export default ToastBlock;
