import React, { ReactElement, useRef } from 'react';
import { useQuery } from 'react-query';

import { Loader, TableLong } from 'Common';
import { CenterAlign, Card, Column } from 'Common/Shared.styles';
import { sortStringsAlphabetically, api, findColConfigLong, useDivSize } from 'utils';
import { chartMargin } from 'utils/useDivSize';
import { FinancialModels } from 'types/shared.types';

import { NumericDataTable as Props } from '../LayoutBuilder';

const fetchTableData = async (financialModel: FinancialModels, filterId: string, signal?: AbortSignal) => {
  const { data: numericalData } = await api.get('/components/numeric_data', {
    params: {
      financial_model: financialModel,
      filter_id: filterId || null,
    },
    signal,
  });

  return {
    columns: ['index', ...sortStringsAlphabetically(numericalData.columns)].map((x: string, i: number) =>
      findColConfigLong(x, i),
    ),
    rows: numericalData.rows,
  };
};

const NumericDataTable = ({ id, financialModel, presentation, cacheKey, filterId = '' }: Props): ReactElement => {
  const { isLoading, data, isError } = useQuery([id, cacheKey, financialModel, filterId], ({ signal }) =>
    fetchTableData(financialModel, filterId, signal),
  );

  const parentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDivSize(parentRef);

  if (isLoading) {
    return (
      <CenterAlign>
        <Loader testId="detailViewLoader" />
      </CenterAlign>
    );
  }

  if (isError) {
    return <span data-testid="errorMessage">Unexpected error</span>;
  }

  return (
    <Column span={presentation.span}>
      <Card ref={parentRef}>
        <TableLong
          width={width - chartMargin}
          columns={data?.columns || []}
          data={data?.rows || []}
          endpoint="numeric_data"
          financialModel={financialModel}
          id="numTable"
          filterId={filterId}
          initCollapsed={true}
          maxRowsToDisplay={15}
          title="Numeric Features"
          withColumnShowHide={true}
          cardWidth={width - chartMargin}
        />
      </Card>
    </Column>
  );
};

export default NumericDataTable;
