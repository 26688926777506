import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioType } from 'Common/LayoutBuilder/LayoutBuilder';
import { RootState } from 'store';
import { FinancialModels } from 'types/shared.types';
import { UserReturnShape } from 'utils/useIsAuthed/handleAuthentication';

export const DEFAULT_DATASET_NAME = 'All';

export const BASE_DATASET = {
  id: '',
  name: DEFAULT_DATASET_NAME,
  dateConstructed: '',
};

export enum DatasetPanel {
  CreateDataset = 'createDataset',
  EditDataset = 'editDataset',
  ExistingDataset = 'existingDataset',
}

interface CurrentDataset {
  id: string;
  name: string;
  dateConstructed: string;
}

interface DatasetManagement {
  currentDatasetPanel: DatasetPanel;
  isEditableDataset: boolean;
  viewDatasetIndex: number;
  isDatasetConfigPanelVisible: boolean;
}

export interface AppState {
  models: FinancialModels[];
  isDatasetMonthly: boolean;
  currentModel: FinancialModels;
  portfolioId: string;
  constructionMethod: PortfolioType;
  currentDataset: CurrentDataset;
  datasetManagement: DatasetManagement;
}

const initialState: AppState = {
  models: [],
  isDatasetMonthly: false,
  currentModel: FinancialModels.EQUITY_MODEL,
  portfolioId: '',
  constructionMethod: PortfolioType.MACHINE_LEARNING,
  currentDataset: BASE_DATASET,
  datasetManagement: {
    currentDatasetPanel: DatasetPanel.ExistingDataset,
    isEditableDataset: false,
    viewDatasetIndex: 0,
    isDatasetConfigPanelVisible: true,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPortfolioId: (state, action: PayloadAction<string>) => {
      state.portfolioId = action.payload;
    },
    setConstructionMethod: (state, action: PayloadAction<PortfolioType>) => {
      state.constructionMethod = action.payload;
    },
    isAuthenticated: (state, action: PayloadAction<UserReturnShape>) => {
      state.models = action.payload.financialModels as FinancialModels[];
      state.isDatasetMonthly = action.payload.user?.group === 'rlam';
      state.currentModel = action.payload.financialModels[0] as FinancialModels;
    },
    setFinancialModel: (state, action: PayloadAction<FinancialModels>) => {
      if (action.payload !== FinancialModels.EQUITY_MODEL) {
        state.constructionMethod = PortfolioType.MACHINE_LEARNING;
      }
      state.currentModel = action.payload;
    },
    setDataset: (state, action: PayloadAction<CurrentDataset>) => {
      state.currentDataset = action.payload;
    },
    setNewDataset: (state, action: PayloadAction<CurrentDataset>) => {
      state.currentDataset = action.payload;
      state.datasetManagement.currentDatasetPanel = DatasetPanel.ExistingDataset;
    },
    setShowExistingDatasetPanel: (state) => {
      state.datasetManagement.currentDatasetPanel = DatasetPanel.ExistingDataset;
    },
    setShowCreateDatasetPanel: (state) => {
      state.datasetManagement.currentDatasetPanel = DatasetPanel.CreateDataset;
    },
    setShowEditDatasetPanel: (
      state,
      action: PayloadAction<{
        isDatasetEditable: boolean;
        datasetIndex: number;
      }>,
    ) => {
      state.datasetManagement.currentDatasetPanel = DatasetPanel.EditDataset;
      state.datasetManagement.isEditableDataset = action.payload.isDatasetEditable;
      state.datasetManagement.viewDatasetIndex = action.payload.datasetIndex;
    },
    setToggleDatasetConfigPanelVisibility: (state) => {
      state.datasetManagement.isDatasetConfigPanelVisible = !state.datasetManagement.isDatasetConfigPanelVisible;
    },
  },
});

export const {
  isAuthenticated,
  setFinancialModel,
  setPortfolioId,
  setConstructionMethod,
  setNewDataset,
  setShowExistingDatasetPanel,
  setShowEditDatasetPanel,
  setShowCreateDatasetPanel,
  setToggleDatasetConfigPanelVisibility,
  setDataset,
} = appSlice.actions;

export const selectModels = (state: RootState): FinancialModels[] => state.app.models;
export const selectIsDatasetMonthly = (state: RootState): boolean => state.app.isDatasetMonthly;
export const currentFinancialModel = (state: RootState): FinancialModels => state.app.currentModel;
export const selectPortfolioId = (state: RootState): string => state.app.portfolioId;
export const selectConstructionMethod = (state: RootState): PortfolioType => state.app.constructionMethod;
export const selectCurrentDataset = (state: RootState): CurrentDataset => state.app.currentDataset;
export const selectCurrentDatasetManagement = (state: RootState): DatasetManagement => state.app.datasetManagement;

export default appSlice.reducer;
