import { Auth } from 'aws-amplify';

async function getToken(): Promise<string> {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return accessToken;
  } catch {
    return '';
  }
}

export default getToken;
