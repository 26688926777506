type Data = {
  x: number[];
  y: number[];
};

type Histogram = {
  type: string;
  x: number[];
};

type ReturnShape = Histogram[];

const formatToHistogramShape = (data: Data): ReturnShape => {
  return [
    { x: data.y, type: 'histogram' },
    { x: data.x, type: 'histogram' },
  ];
};

export default formatToHistogramShape;
