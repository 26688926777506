import React, { ReactElement, ChangeEvent } from 'react';
import styled from 'styled-components';

type DataProp = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type DefaultColumnFilterProps = {
  data: DataProp[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: any;
};

const StyledColumnFilter = styled.input`
  align-self: start;
  margin-top: 0.5rem;
  width: 95%;
`;

function DefaultColumnFilter({ column: { filterValue, setFilter } }: DefaultColumnFilterProps): ReactElement {
  return (
    <StyledColumnFilter
      value={filterValue || ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Filter…"
    />
  );
}

export default DefaultColumnFilter;
