import React, { useState, useCallback, ReactElement } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components';

import { Option } from 'types/shared.types';
import { DropdownButton, StyledSlider } from 'Common';
import { SliderWrapper } from 'App/App.styles';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { Tooltip } from '@material-ui/core';
import theme from 'theme';

interface Payload {
  startIndex: number;
  endIndex: number;
}
interface Props {
  startIndex: number;
  endIndex: number;
  options: Option[];
  onChange: (arg: Payload) => void;
}

const Spacer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SelectedDate = styled.p`
  display: none;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    display: block;
    font-size: 0.75rem;
  }
`;

const DateRangePicker = ({ options, startIndex, endIndex, onChange }: Props): ReactElement => {
  const [selectedRange, setSelectedRange] = useState({ startIndex, endIndex });
  const lastIndex = options.length - 1;

  const debouncedSave = useCallback(
    debounce((nextValue: Payload) => {
      onChange(nextValue);
      setSelectedRange(nextValue);
    }, 250),
    [],
  );

  const handleChange = (arg: Payload) => {
    debouncedSave(arg);
  };

  const ValueLabelComponent = (props: { children: ReactElement; open: boolean; value: number }) => {
    const { children, open, value } = props;
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={options[value].label}>
        {children}
      </Tooltip>
    );
  };

  return (
    <DropdownButton
      icon={
        <Spacer>
          <SelectedDate>{`${options[startIndex].label} - ${options[endIndex].label}`}</SelectedDate>
          <CalendarIcon />
        </Spacer>
      }
      title="Select investment period"
      testId="investmentPeriodDropdown"
    >
      <SliderWrapper>
        <StyledSlider
          data-testid="investmentPeriodSlider"
          min={0}
          max={lastIndex || 0}
          value={[selectedRange.startIndex, selectedRange.endIndex]}
          aria-labelledby="Investment period range slider"
          ValueLabelComponent={ValueLabelComponent}
          className="slider"
          marks={[
            {
              value: 0,
              label: options[0].label,
            },
            {
              value: lastIndex,
              label: options[lastIndex].label,
            },
          ]}
          onChange={(_e, value: number | number[]) => {
            if (typeof value !== 'number') {
              let [startId, endId] = value;

              if (startId === endId && endId === lastIndex) {
                startId = startId - 1;
              }

              if (startId === endId && endId !== lastIndex) {
                endId = endId + 1;
              }

              handleChange({
                endIndex: endId,
                startIndex: startId,
              });
            }
          }}
        />
      </SliderWrapper>
    </DropdownButton>
  );
};

export default DateRangePicker;
