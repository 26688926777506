import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import { DownloadCsvButton, FeatureImportanceParams } from 'Common/CsvButton/CsvButton';
import {
  Card,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Column,
  ChartWrapper,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { colorWay } from 'theme';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { FeatureImportancePlot as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchFeatureImportanceChartDataProps = {
  financialModel: FinancialModels;
  startDate: string;
  endDate: string;
  signal?: AbortSignal;
  filterId?: string;
};

interface ChartDataProps {
  name: string;
  orientation: string;
  stackgroup: string;
  x: number[];
  y: string[];
}

const fetchFeatureImportanceChartData = async ({
  financialModel,
  startDate,
  endDate,
  signal,
  filterId,
}: FetchFeatureImportanceChartDataProps): Promise<ChartDataProps[]> => {
  const { data } = await api.get('/components/feature_importance_chart_data', {
    params: {
      financial_model: financialModel,
      start_date: startDate,
      end_date: endDate,
      filter_id: filterId,
    },
    signal,
  });

  return data;
};

const FeatureImportancePlot = ({
  id,
  presentation,
  financialModel,
  startDate,
  endDate,
  cacheKey,
  isPageError,
  options = [],
  filterId,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fetchProps = { financialModel, startDate, endDate, filterId };
  const selectedFeatures = options.filter((i) => i.isChecked).map((i) => i.value);

  const { isLoading, data, isError } = useQuery([id, cacheKey, fetchProps], ({ signal }) =>
    fetchFeatureImportanceChartData({ ...fetchProps, signal }),
  );

  if (isError || isPageError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const selectedFeaturesIndices = selectedFeatures.map((i) => (data ? data[0].y.indexOf(i as string) : i));
  const displayedData: ChartDataProps[] =
    data?.map((i) => ({
      ...i,
      x: i.x.filter((_, idx) => selectedFeaturesIndices.includes(idx)),
      y: i.y.filter((_, idx) => selectedFeaturesIndices.includes(idx)),
    })) || [];

  const chartLayout = {
    showlegend: true,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    legend: {
      orientation: 'h',
    },
    margin: {
      t: 0,
      r: 0,
      l: 0,
      b: 0,
    },
    yaxis: {
      tickmode: 'linear',
      automargin: true,
      autorange: 'reversed',
    },
    xaxis: {
      automargin: true,
    },
    colorway: colorWay,
    barmode: 'stack',
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
  };

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Feature Importance by Machine Learning Model</CardHeaderNewTitle>
          <CardHeaderActions>
            <DownloadCsvButton
              fetchData={{
                url: '/components/feature_importance_table_data',
                params: {
                  financial_model: financialModel,
                  start_date: startDate,
                  end_date: endDate,
                  output: 'csv',
                } as FeatureImportanceParams,
              }}
              testId="downloadFeatureImportanceButton"
              filename="feature_importance_machine_learning.csv"
              cacheKey={`${financialModel}${startDate}${endDate}${cacheKey}`}
            />
            <IconButton data-testid="showFeatureImportanceOverTimeModal" onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef}>
          <Chart
            testId="featureImportancePlot"
            data={displayedData || null}
            isLoading={isLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Feature importance overtime</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="featureImportancePlot"
              data={displayedData || null}
              isLoading={isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default FeatureImportancePlot;
