import React, { ReactElement } from 'react';
import Plot from 'react-plotly.js';

import styled from 'styled-components';
import { CardLoader } from 'Common';
import { LoaderMessage } from 'Common/Shared.styles';

import { ReactComponent as ChartIcon } from './placeholder.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlotlyData = any; // TODO: get type from plotly lib

const CenterAlign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

type Props = {
  testId: string;
  isLoading: boolean;
  data: null | PlotlyData;
  layout: null | PlotlyData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (args: any) => void;
  loaderMessage?: string;
};

const Chart = ({ isLoading, data, layout, testId, onClick, loaderMessage }: Props): ReactElement => {
  if (isLoading) {
    return (
      <CenterAlign data-testid={`${testId}Loader`}>
        <CardLoader />
        {loaderMessage && <LoaderMessage>{loaderMessage}</LoaderMessage>}
      </CenterAlign>
    );
  }

  if (!data) {
    return (
      <CenterAlign>
        <ChartIcon data-testid={`${testId}Placeholder`} />
      </CenterAlign>
    );
  }

  return (
    <CenterAlign data-testid={`${testId}Chart`}>
      <Plot
        style={{ width: '100%', height: '100%' }}
        config={{ responsive: true, displayModeBar: false }}
        data={data}
        layout={layout}
        onClick={onClick}
      />
    </CenterAlign>
  );
};

export default Chart;
