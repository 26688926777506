import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appReducer from 'appReducer';
import displayReducer from 'displayReducer';
import featureEngineeringReducer from 'featureEngineeringReducer';
import machineLearningReducer from 'machineLearningReducer';
import optimisationReducer from 'optimisationReducer';
import optimisationNewReducer from 'optimisationNewReducer';
import screeningReducer from 'screeningReducer';
import dataExplorationReducer from 'dataExplorationReducer';

export const store = configureStore({
  reducer: {
    app: appReducer,
    machineLearning: machineLearningReducer,
    screening: screeningReducer,
    optimisation: optimisationReducer,
    optimisationNew: optimisationNewReducer,
    featureEngineering: featureEngineeringReducer,
    display: displayReducer,
    dataExploration: dataExplorationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
