import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { Chart, Modal } from 'Common';
import {
  Card,
  ChartHeight,
  ChartWrapper,
  CardHeaderNew,
  CardHeaderNewTitle,
  Pill,
  Column,
  CardHeaderActions,
  ModalChartWrap,
  IconButton,
} from 'Common/Shared.styles';
import { api, useDivSize } from 'utils';
import { colorWay } from 'theme';
import { DataGroupingPieChart as Props } from '../LayoutBuilder';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { ReactComponent as InfoIcon } from 'icons/information.svg';
import { FinancialModels } from 'types/shared.types';

interface FetchChartData {
  financialModel: FinancialModels;
  groupBy: string;
  filterId: string;
  signal?: AbortSignal;
}

type Point = {
  label: string;
};

type HandleClickProps = {
  points: Point[];
};

const fetchChartData = async ({ financialModel, groupBy, filterId, signal }: FetchChartData) => {
  const { data } = await api.get('/components/data_grouping_pie_chart', {
    params: {
      financial_model: financialModel,
      group_by: groupBy || null,
      filter_id: filterId || null,
    },
    signal,
  });

  return data;
};

const DataGroupingPieChart = ({
  id,
  financialModel,
  groupBy,
  onClick,
  presentation,
  cacheKey,
  isPageError,
  filterId = '',
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLegendVisible, setIsLegendVisible] = useState(false);

  const payload = { financialModel, groupBy, filterId };

  const { isLoading, data, isError } = useQuery([id, cacheKey, payload], ({ signal }) =>
    fetchChartData({ ...payload, signal }),
  );

  if (isError || isPageError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const handleFeatureClick = ({ points }: HandleClickProps) => {
    if (points.length > 0) {
      onClick(points[0].label);
    }
  };

  const sharedChartLayout = {
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: isLegendVisible,
    datarevision: new Date(),
    margin: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
    colorway: colorWay,
  };

  const chartData = [
    {
      marker: {
        color: 'white',
      },
      textposition: 'inside',
      automargin: true,
      type: 'pie',
      hoverlabel: {
        bgcolor: '#ffec9f',
        bordercolor: '#333333',
        font: {
          color: '#333333',
        },
      },
      textinfo: 'label+value',
      labels: (data && data.x) || [],
      values: (data && data.y) || [],
    },
  ];

  const safeGroupBy = groupBy || (data && data.default_grouping) || '';

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Data grouping {safeGroupBy && <Pill>{safeGroupBy}</Pill>}</CardHeaderNewTitle>
          <CardHeaderActions>
            <IconButton onClick={() => setIsLegendVisible(!isLegendVisible)} title="Show Legend">
              <InfoIcon />
            </IconButton>
            <IconButton onClick={() => setIsModalVisible(true)} title="Fullscreen">
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="dataGroupingPie"
            data={chartData}
            isLoading={isLoading}
            layout={{ ...sharedChartLayout, height, width }}
            onClick={handleFeatureClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Data grouping</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsLegendVisible(!isLegendVisible)} title="Show Legend">
                <InfoIcon />
              </IconButton>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>

          <ModalChartWrap>
            <Chart
              testId="dataGroupingPie"
              data={chartData}
              isLoading={isLoading}
              layout={sharedChartLayout}
              onClick={handleFeatureClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default DataGroupingPieChart;
