import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from 'icons/settings.svg';

const Button = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ isActive }) => (isActive ? '#f1f1f1' : '#fff')};
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
`;

type Props = {
  'data-testid': string;
  onClick: () => void;
  isActive: boolean;
};

const SettingsButton = ({ ...props }: Props): ReactElement => (
  <Button type="button" {...props}>
    <Icon />
  </Button>
);

export default SettingsButton;
