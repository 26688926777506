import React, { ReactElement, useState } from 'react';
import { Text, Button } from 'Common';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-query';
import {
  StyledSection,
  Modal,
  Wrapper,
  RegexWrapper,
  StyledForm,
  Label,
  PasswordContainer,
  PasswordInput,
  VisibilityButton,
  PasswordWrapper,
  Header,
  Container,
} from './ResetPassword.styles';
import theme from 'theme';
import { ReactComponent as InvisibilityIcon } from 'icons/invisibility.svg';
import { ReactComponent as VisibilityIcon } from 'icons/visibility.svg';
import { ReactComponent as CloseIcon } from 'icons/closeDark.svg';

interface ResetPasswordProps {
  handleClose: () => void;
}

const ResetPassword = ({ handleClose }: ResetPasswordProps): ReactElement => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validCharacters, setValidCharacters] = useState(false);
  const [validUpperCase, setValidUpperCase] = useState(false);
  const [validLowerCase, setValidLowerCase] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validSpecial, setValidSpecial] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { darkGrey, red, primaryDark } = theme.colors;

  const regexRules = {
    uppercase: /([A-Z])/g,
    lowercase: /([a-z])/g,
    number: /([0-9])/g,
    special: /([^A-Za-z0-9])/g,
  };

  const updateOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const updateNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    setValidCharacters(value.length > 7);
    setValidUpperCase(regexRules.uppercase.test(value));
    setValidLowerCase(regexRules.lowercase.test(value));
    setValidNumber(regexRules.number.test(value));
    setValidSpecial(regexRules.special.test(value));
  };

  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmPassword(value);
  };

  const handleChangePassword = async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
    const user = await Auth.currentAuthenticatedUser();
    const request = await Auth.changePassword(user, oldPassword, newPassword);
    return request;
  };

  const mutation = useMutation(handleChangePassword, {
    onSuccess: () => {
      setTimeout(() => handleClose(), 1500);
    },
    onError: (error: Error) => console.log(error.message),
  });

  return (
    <StyledSection
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          handleClose();
        }
      }}
    >
      <Modal>
        <Header>
          <Text textType="h1" text="Change Password" color={darkGrey} align="left" />
          <Button onClick={handleClose} withIcon type="button">
            <CloseIcon />
          </Button>
        </Header>
        <Container>
          <Wrapper>
            <RegexWrapper>
              <Text textType="p" text="Password must contain:" color={darkGrey} align="left" />
              <Text
                textType="p"
                text="At least 8 characters"
                color={validCharacters ? primaryDark : red}
                align="left"
                fontWeight="bold"
              />
              <Text
                textType="p"
                text="At least 1 upper case letter"
                color={validUpperCase ? primaryDark : red}
                align="left"
                fontWeight="bold"
              />
              <Text
                textType="p"
                text="At least 1 lower case letter"
                color={validLowerCase ? primaryDark : red}
                align="left"
                fontWeight="bold"
              />
              <Text
                textType="p"
                text="At least 1 number"
                color={validNumber ? primaryDark : red}
                align="left"
                fontWeight="bold"
              />
              <Text
                textType="p"
                text="At least 1 special character"
                color={validSpecial ? primaryDark : red}
                align="left"
                fontWeight="bold"
              />
            </RegexWrapper>
          </Wrapper>
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              mutation.mutate({ oldPassword, newPassword });
            }}
          >
            <PasswordWrapper className="password">
              <Label>Current Password</Label>
              <PasswordContainer>
                <PasswordInput
                  type={isVisible ? 'text' : 'password'}
                  name="password"
                  value={oldPassword}
                  onChange={(e) => updateOldPassword(e)}
                  autoComplete="off"
                  autoCapitalize="off"
                />
                <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
                  {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
                </VisibilityButton>
              </PasswordContainer>
            </PasswordWrapper>
            <PasswordWrapper className="password">
              <Label>New Password</Label>
              <PasswordContainer>
                <PasswordInput
                  type={isVisible ? 'text' : 'password'}
                  name="password"
                  value={newPassword}
                  onChange={(e) => updateNewPassword(e)}
                  autoComplete="off"
                  autoCapitalize="off"
                />
                <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
                  {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
                </VisibilityButton>
              </PasswordContainer>
            </PasswordWrapper>
            <PasswordWrapper className="password">
              <Label>Confirm Password</Label>
              <PasswordContainer>
                <PasswordInput
                  type={isVisible ? 'text' : 'password'}
                  name="password"
                  value={confirmPassword}
                  onChange={(e) => updateConfirmPassword(e)}
                  autoComplete="off"
                  autoCapitalize="off"
                />
                <VisibilityButton onClick={() => setIsVisible(!isVisible)} unStyled>
                  {isVisible ? <InvisibilityIcon /> : <VisibilityIcon />}
                </VisibilityButton>
                <Text
                  textType="p"
                  text={newPassword === confirmPassword ? 'Passwords match' : 'Passwords must match'}
                  color={newPassword === confirmPassword ? primaryDark : red}
                  align="right"
                  fontWeight="bold"
                />
              </PasswordContainer>
            </PasswordWrapper>
            <Button
              type="submit"
              theme="primary"
              size="large"
              isDisabled={
                !oldPassword ||
                !newPassword ||
                !confirmPassword ||
                !validCharacters ||
                !validUpperCase ||
                !validLowerCase ||
                !validNumber ||
                !validSpecial ||
                newPassword !== confirmPassword ||
                mutation.isLoading
              }
            >
              SAVE
            </Button>
          </StyledForm>
        </Container>
      </Modal>
    </StyledSection>
  );
};

export default ResetPassword;
