import { api } from 'utils';
import { PortfolioType } from '../LayoutBuilder';

interface Props {
  financialModel: string;
  portfolio: string;
  attributionDate?: string;
  attributionView: string;
  attributionSortBy: string;
  attributionRegion: string;
  attributionSector: string;
  portfolioType: PortfolioType;
  signal?: AbortSignal;
}

interface ReturnShape {
  availableRegions: { label: string; value: string }[];
  availableSectors: { label: string; value: string }[];
  contributionsData: {
    x: string[];
    y: number[];
    type: string;
    marker: {
      color: string;
    };
  }[];
}

const barChartColors = {
  regional: 'rgba(217,132,22,0.8)',
  sectoral: 'rgba(46,54,115, 0.8)',
  stock: 'rgba(222,45,38,0.8)',
};

const fetchAttributionContributions = async ({
  financialModel,
  portfolio,
  attributionDate,
  attributionView,
  attributionSector,
  attributionSortBy,
  attributionRegion,
  portfolioType,
  signal,
}: Props): Promise<ReturnShape> => {
  const {
    data: { available_regions, available_sectors },
  } = await api.get('/v2/attribution/available_regions_and_sectors', {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
      selected_date: attributionDate,
    },
    signal,
  });

  const { data } = await api.get(`/v2/attribution/${attributionView}_attribution`, {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
      selected_date: attributionDate,
      region: attributionRegion || null,
      option: attributionSortBy || null,
      sector: attributionSector || null,
    },
    signal,
  });

  return {
    availableRegions: available_regions.map((region: string) => ({
      label: region,
      value: region,
    })),
    availableSectors: available_sectors.map((sector: string) => ({
      label: sector,
      value: sector,
    })),
    contributionsData: [
      {
        x: data.x,
        y: data.y,
        type: 'bar',
        marker: {
          color: barChartColors[attributionView as 'regional'],
        },
      },
    ],
  };
};

export default fetchAttributionContributions;
