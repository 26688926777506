import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface FeatureEngineeringOption {
  id: string;
  label: string;
  value: string;
  isChecked: boolean;
}

export interface DataExplorationState {
  options: FeatureEngineeringOption[];
  selectedFeature: string;
}

const initialState: DataExplorationState = {
  options: [],
  selectedFeature: '',
};

export const dataExplorationSlice = createSlice({
  name: 'dataExploration',
  initialState,
  reducers: {
    setDataExplorationFeatureOptions: (state, action: PayloadAction<FeatureEngineeringOption[]>) => {
      const filteredOptions = action.payload.filter((i) => i.isChecked);

      state.options = action.payload;
      state.selectedFeature = filteredOptions.find((i) => i.id === state.selectedFeature)
        ? state.selectedFeature
        : filteredOptions[0].id;
    },
    setDataExplorationFeatureOptionsChecked: (state) => {
      state.options = state.options.map((i: FeatureEngineeringOption) => ({ ...i, isChecked: true }));
    },
    setDataExplorationFeatureOptionsUnChecked: (state) => {
      state.options = state.options.map((i: FeatureEngineeringOption) => ({ ...i, isChecked: false }));
    },
    setDataExplorationSelectedFeature: (state, action: PayloadAction<string>) => {
      state.selectedFeature = action.payload;
    },
  },
});

export const {
  setDataExplorationFeatureOptions,
  setDataExplorationFeatureOptionsChecked,
  setDataExplorationFeatureOptionsUnChecked,
  setDataExplorationSelectedFeature,
} = dataExplorationSlice.actions;

export const selectDataExplorationFeatures = (state: RootState): FeatureEngineeringOption[] =>
  state.dataExploration.options;
export const selectDataExplorationSelectedFeature = (state: RootState): string => state.dataExploration.selectedFeature;

export default dataExplorationSlice.reducer;
