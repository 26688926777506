import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import formatToBarchartShape from './formatToBarchartShape';
import { NumericFeatures as Props } from '../LayoutBuilder';

type HandleFeatureClickProps = {
  points: {
    x: string;
  }[];
};

const fetchChartData = async (
  financialModel: string,
  filterId: string,
  portfolioType: string,
  signal?: AbortSignal,
) => {
  const { data } = await api.get('/components/portfolio_numeric_features', {
    params: {
      financial_model: financialModel,
      filter_id: filterId || null,
      portfolio_type: portfolioType,
    },
    signal,
  });

  return data.chart_data;
};

const NumericFeatures = ({
  id,
  financialModel,
  onClick,
  presentation,
  feature,
  isPageLoading,
  cacheKey,
  filterId = '',
  portfolioType,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, data, isError } = useQuery([id, cacheKey, financialModel, filterId, portfolioType], ({ signal }) =>
    fetchChartData(financialModel, filterId, portfolioType, signal),
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Numeric Features</Spacer>
        </Card>
      </Column>
    );
  }

  const handleFeatureClick = ({ points }: HandleFeatureClickProps) => {
    if (points.length > 0) {
      onClick(points[0].x);
    }
  };

  const formatChartData = data ? formatToBarchartShape({ chartData: data, feature }) : null;

  const chartLayout = {
    margin: {
      t: 0,
      l: 40,
      r: 30,
      b: 20,
    },
    xaxis: {
      automargin: true,
      tickangle: 50,
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
  };

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Numeric Features</CardHeaderNewTitle>
          <CardHeaderActions>
            <IconButton onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="numericFeatures"
            data={formatChartData}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chartLayout, height, width }}
            onClick={handleFeatureClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Numeric Features</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="numericFeaturesModal"
              data={formatChartData}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
              onClick={handleFeatureClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default NumericFeatures;
