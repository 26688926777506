import styled from 'styled-components';
import { Button } from 'Common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-weight: bold;
`;

export const Input = styled.input`
  padding: 1rem;
  border-radius: 0.25rem;
  border: ${({ theme }) => `2px solid ${theme.colors.lightGrey}`};
`;

export const SwitchViewButton = styled(Button)`
  text-align: right;
  color: ${({ theme }) => theme.colors.primaryDark};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 75%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 3rem;
  }
`;

export const PasswordContainer = styled.div`
  display: grid;
  align-items: center;
`;

export const PasswordInput = styled(Input)`
  grid-column: 1/2;
  grid-row: 1/2;
`;

export const VisibilityButton = styled(Button)`
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: right;
  margin-right: 2rem;
  width: 1rem;
  justify-self: end;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
