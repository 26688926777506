import React, { ReactElement, useState } from 'react';
import { Menu } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';

import { ReactComponent as Logo } from 'icons/logo.svg';
import { FinancialModels } from 'types/shared.types';
import { useIsAuthed } from 'utils';
import {
  StyledHeader,
  StyledHeaderWrapper,
  NavWrap,
  NavControl,
  StyledLink,
  SelectModel,
  Avatar,
  MenuItem,
  MenuLink,
  Link,
  MenuTitle,
  ControlWrap,
} from './Navigation.styles';
import ResetPassword from './ResetPassword';
import theme from 'theme';
import { UserReturnShape } from 'utils/useIsAuthed/handleAuthentication';
import { IS_AUTHED_KEY } from 'utils/useIsAuthed/useIsAuthed';
import { useAppDispatch, useAppSelector } from 'hooks';
import { currentFinancialModel, selectModels, setFinancialModel, selectCurrentDataset, BASE_DATASET } from 'appReducer';

const Navigation = (): ReactElement => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const financialModels = useAppSelector(selectModels);
  const financialModel = useAppSelector(currentFinancialModel);
  const dataset = useAppSelector(selectCurrentDataset);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [viewChangePassword, setViewChangePassword] = useState(false);
  const { data } = useIsAuthed();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // This could be handled by useMutation onError hook
      console.log('Error signing out', error);
    }
  };

  const signOutMutation = useMutation(handleSignOut, {
    onSuccess: () => {
      queryClient.setQueryData<UserReturnShape | undefined>(IS_AUTHED_KEY, () => {
        return {
          isAuthed: false,
          financialModels: [],
          user: null,
        };
      });
      queryClient.setQueryData(['FILTER_LIST', dataset.id], () => {
        return [BASE_DATASET];
      });

      history.push('/');
    },
  });

  const activeStyle = {
    color: theme.colors.primary,
    background: theme.colors.lightGrey,
    borderBottom: `4px solid ${theme.colors.lightGrey}`,
    fontWeight: 'bold',
  } as React.CSSProperties;

  return (
    <StyledHeader>
      <StyledHeaderWrapper>
        <ControlWrap>
          <Logo />
          <SelectModel>
            <select
              data-testid="selectFinancialModel"
              value={financialModel}
              onChange={(e) => dispatch(setFinancialModel(e.target.value as FinancialModels))}
            >
              {financialModels.map((i) => (
                <option key={i} value={i}>
                  {i.replaceAll('_', ' ')}
                </option>
              ))}
            </select>
          </SelectModel>
        </ControlWrap>
        <NavWrap>
          <NavControl>
            <StyledLink to="/manage-dataset" activeStyle={activeStyle} data-testid="selectDatasetButton">
              {`Data Exploration - ${dataset.name.length > 10 ? `${dataset.name.slice(0, 10)}...` : dataset.name}`}
            </StyledLink>

            <StyledLink
              to="/feature-engineering"
              activeStyle={activeStyle}
              data-testid="selectFeatureEngineeringButton"
            >
              Feature Engineering
            </StyledLink>

            <StyledLink
              to="/portfolio-construction"
              activeStyle={activeStyle}
              data-testid="portfolioConstructionButton"
            >
              Portfolio Construction
            </StyledLink>

            <StyledLink to="/portfolio-evaluation" activeStyle={activeStyle} data-testid="portfolioEvaluationButton">
              Portfolio Evaluation
            </StyledLink>
          </NavControl>
          <Avatar onClick={handleClick}>{data && data.user ? data.user.email[0].toUpperCase() : 'U'}</Avatar>
        </NavWrap>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          {data && data.user && <MenuTitle>{data.user.email}</MenuTitle>}
          <MenuLink to="/manage-dataset" activeStyle={activeStyle} data-testid="selectDatasetMenuButton">
            {`Data Exploration - ${dataset.name.length > 10 ? `${dataset.name.slice(0, 10)}...` : dataset.name}`}
          </MenuLink>

          <MenuLink
            to="/feature-engineering"
            activeStyle={activeStyle}
            data-testid="selectFeatureEngineeringMenuButton"
          >
            Feature Engineering
          </MenuLink>

          <MenuLink
            to="/portfolio-construction"
            activeStyle={activeStyle}
            data-testid="portfolioConstructionMenuButton"
          >
            Portfolio Construction
          </MenuLink>

          <MenuLink to="/portfolio-evaluation" activeStyle={activeStyle} data-testid="portfolioEvaluationMenuButton">
            Portfolio Evaluation
          </MenuLink>
          <MenuItem
            type="button"
            onClick={() => {
              setViewChangePassword(true);
              handleClose();
            }}
          >
            Change Password
          </MenuItem>
          <Link href="https://insg.ai/privacy-policy/" target="_blank" rel="noreferrer" onClick={handleClose}>
            Privacy Policy
          </Link>
          <Link href="https://insg.ai/cookies/" target="_blank" rel="noreferrer" onClick={handleClose}>
            Cookie Policy
          </Link>
          <MenuItem
            type="button"
            onClick={() => {
              !signOutMutation.isLoading ? signOutMutation.mutate() : handleClose();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </StyledHeaderWrapper>
      {viewChangePassword && <ResetPassword handleClose={() => setViewChangePassword(false)} />}
    </StyledHeader>
  );
};

export default Navigation;
