import React, { ReactElement } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api } from 'utils';
import { Card, Spacer, Column } from 'Common/Shared.styles';
import TopStock from '../TopStock';
import BottomStock from '../BottomStock';

import theme from 'theme';

import { FinancialModels } from 'types/shared.types';

import { TopBottomStocks as Props, PortfolioType, SpanTypes, ComponentTypes } from '../LayoutBuilder';

type FetchDataProps = {
  financialModel: FinancialModels;
  portfolio: string;
  portfolioType: PortfolioType;
  date: string;
  signal?: AbortSignal;
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 1.75rem;
  width: 100%;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const fetchMLOrManualChartData = async ({ financialModel, portfolio, portfolioType, date, signal }: FetchDataProps) => {
  const { data } = await api.get('/components/top_bottom_stocks', {
    params: {
      financial_model: financialModel,
      portfolio_dropdown: portfolio,
      portfolio_type: portfolioType,
      selected_date: date || null,
    },
    signal,
  });

  return {
    top: data.top,
    bottom: data.bottom,
  };
};

const TopBottomStock = ({
  id,
  financialModel,
  portfolio,
  presentation,
  portfolioType,
  date,
  onRemoveDate,
  isPageLoading,
  cacheKey,
}: Props): ReactElement => {
  const fetchProps = { financialModel, portfolio, portfolioType, date };

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, fetchProps],
    ({ signal }) => fetchMLOrManualChartData({ ...fetchProps, signal }),
    {
      enabled: Boolean(portfolio),
      refetchInterval: isPageLoading ? 1500 : false,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Top and Bottom Stocks</Spacer>
        </Card>
      </Column>
    );
  }

  return (
    <Column span={presentation.span} data-testid="topBottomStocks">
      <Row>
        <TopStock
          id={`${id}TopStocks`}
          portfolio={portfolio}
          date={date}
          onRemoveDate={onRemoveDate}
          isPageLoading={isPageLoading || isLoading}
          cacheKey={cacheKey}
          presentation={{
            span: SpanTypes.FULL,
          }}
          type={ComponentTypes.TOP_STOCKS}
          data={data?.top}
          portfolioType={portfolioType}
          financialModel={financialModel}
        />
        <BottomStock
          id={`${id}BottomStocks`}
          portfolio={portfolio}
          portfolioType={portfolioType}
          date={date}
          onRemoveDate={onRemoveDate}
          isPageLoading={isPageLoading || isLoading}
          cacheKey={cacheKey}
          financialModel={financialModel}
          presentation={{
            span: SpanTypes.FULL,
          }}
          type={ComponentTypes.BOTTOM_STOCKS}
          data={data?.bottom}
        />
      </Row>
    </Column>
  );
};

export default TopBottomStock;
