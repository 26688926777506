import { useQuery } from 'react-query';

import handleAuthentication, { UserReturnShape } from './handleAuthentication';

export const IS_AUTHED_KEY = 'USER_AUTH';

// eslint-disable-next-line
const useIsAuthed = () =>
  useQuery<UserReturnShape, Error>(IS_AUTHED_KEY, handleAuthentication, {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 5000 || 1000 * 60 * 60 * 8,
  });

export default useIsAuthed;
