import axios, { AxiosRequestConfig } from 'axios';

import getToken from '../getToken';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = await getToken();

    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers['X-Insight-Oidc-AccessToken'] = token ? token : '';

    return config;
  });

  return instance;
};

export default fetchClient();
