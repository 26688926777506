import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  ChartHeight,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  ChartWrapper,
  Column,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { DownloadCsvButton } from 'Common/CsvButton/CsvButton';
import formatToBarchartShape from './formatToBarchartShape';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { ComparisonAcrossNumericFeatures as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type Point = {
  x: string;
};

type HandleClickProps = {
  points: Point[];
};

type FetchChartData = {
  startDate: string;
  endDate: string;
  features: string;
  financialModel: FinancialModels;
  filterId: string;
  signal?: AbortSignal;
};

const fetchChartData = async ({ startDate, endDate, features, financialModel, filterId, signal }: FetchChartData) => {
  const { data } = await api.get('/components/numeric_features_comparison', {
    params: {
      financial_model: financialModel,
      start_date: startDate,
      end_date: endDate,
      selected_features: features || null,
      filter_id: filterId || null,
    },
    signal,
  });

  return { chartData: formatToBarchartShape(data) };
};

const ComparisonAcrossNumericFeatures = ({
  id,
  startDate,
  endDate,
  features,
  financialModel,
  onClick,
  presentation,
  cacheKey,
  filterId = '',
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, { financialModel, startDate, endDate, features, filterId }],
    ({ signal }) =>
      fetchChartData({
        startDate,
        endDate,
        financialModel,
        features,
        filterId,
        signal,
      }),
  );

  if (isError) {
    return <span data-testid="errorMessage">Error</span>;
  }

  const handleFeatureClick = ({ points }: HandleClickProps) => {
    if (points.length > 0) {
      onClick(points[0].x);
    }
  };

  const sharedChartLayout = {
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(),
  };

  const key = `${financialModel}_numeric_features${filterId}`;
  const url = '/components/numeric_data';
  const params = {
    financial_model: financialModel,
    output: 'csv',
    filter_id: filterId || null,
  };

  const ChartInit = (
    <Chart
      testId="numericFeatures"
      data={data?.chartData || []}
      isLoading={isLoading}
      layout={{
        ...sharedChartLayout,
        height,
        width,
        xaxis: {
          automargin: true,
          tickfont: {
            size: 10,
          },
        },
        yaxis: {
          showgrid: false,
          ticklen: 4,
          tickcolor: 'white',
        },
        margin: {
          t: 0,
          l: 28,
          r: 0,
          b: 36,
        },
      }}
      onClick={handleFeatureClick}
    />
  );
  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>Comparison Across Numeric Features</CardHeaderNewTitle>
          <CardHeaderActions>
            <DownloadCsvButton
              fetchData={{ url, params }}
              testId="comparisonAcrossNumericFeaturesCsvDownload"
              filename={`${key}.csv`}
              cacheKey={key}
            />
            <IconButton data-testid="showNumericFeaturesBarchartModal" onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          {ChartInit}
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Comparison Across Numeric Features</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>{ChartInit}</ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default ComparisonAcrossNumericFeatures;
