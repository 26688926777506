type Data = {
  x: number[];
  y: number[];
};

type Scatter = {
  y: number[];
  x: number[];
  mode: string;
  type: string;
};

type ReturnShape = Scatter[];

const formatToScatterShape = (data: Data): ReturnShape => {
  return [{ x: data.x, y: data.y, mode: 'markers', type: 'scatter' }];
};

export default formatToScatterShape;
