import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import CSVButton from 'Common/CsvButton';

import { api, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  ChartWrapper,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { ProfitAndLoss as Props, PortfolioType } from '../LayoutBuilder';
import { colorWay } from 'theme';

interface FetchChartDataProps {
  financialModel: string;
  benchmark: string;
  portfolioType: PortfolioType;
  portfolio: string;
  signal?: AbortSignal;
}

interface DataProp {
  x: string[];
  y: number[];
}

interface ConstructChartDataProps {
  data: null | DataProp[];
  isNotOptimisation: boolean;
}

interface Point {
  data: {
    name: string;
  };
}

interface OnSegmentClick {
  points: Point[];
}

const formatToOptions = (i: { id: number; name: string }) => ({
  label: i.name,
  value: i.id,
});

const fetchMLOrManualData = async ({
  financialModel,
  benchmark,
  portfolioType,
  portfolio,
  signal,
}: FetchChartDataProps) => {
  const {
    data: { data: chartData, preferred_benchmark_id, benchmarks },
  } = await api.get('/components/return_indices', {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      benchmark_id: benchmark || null,
      portfolio_dropdown: portfolio || null,
    },
    signal,
  });

  return {
    chartData: chartData.map((i: { x: string[]; y: number[] }[]) => ({ ...i, line: { width: 3 } })),
    defaultBenchmark: preferred_benchmark_id,
    benchmarkOptions: benchmarks.map(formatToOptions),
  };
};

const determineTickAngle = (count: number): number => (count > 30 ? 90 : 30);

const constructChartData = ({ data, isNotOptimisation }: ConstructChartDataProps) => {
  const xAxis =
    data && data.length && data[0].x.length > 12
      ? {
          automargin: true,
          type: 'date',
          ticklen: 8,
          tickcolor: 'white',
          showgrid: false,
          tickfont: {
            size: 10,
          },
        }
      : {
          automargin: true,
          type: 'date',
          ticklen: 8,
          tickcolor: 'white',
          showgrid: false,
          tickangle: data && data.length && determineTickAngle(data[0].x.length),
          ticktext: data && data.length && data[0].x.map((i: string) => format(new Date(i), 'd LLL y')),
          tickvals: data && data.length && data[0].x,
          tickfont: {
            size: data && data.length && data[0].x.length > 30 ? 8 : 10,
          },
        };

  const layout = {
    hovermode: 'closest',
    margin: {
      t: 0,
      b: 0,
      r: 0,
      l: 50,
    },
    yaxis: {
      automargin: true,
      gridcolor: 'lightgrey',
      showgrid: false,
      ticklen: 4,
      tickcolor: 'white',
      tickfont: {
        size: 10,
      },
      title: {
        text: 'Total Return',
        font: {
          size: 10,
        },
        standoff: 16,
      },
    },
    xaxis: xAxis,
    legend: {
      orientation: 'v',
      font: {
        size: 10,
      },
      tracegroupgap: 20,
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: isNotOptimisation,
    datarevision: new Date(), // should be able to remove when https://github.com/plotly/plotly.js/issues/2389 is merged
    colorway: colorWay,
  };

  return {
    data,
    layout,
  };
};

const ProfitAndLoss = ({
  id,
  financialModel,
  portfolio,
  presentation,
  isPageLoading,
  portfolioType,
  onSelectPortfolio,
  onChangeBenchmark,
  cacheKey,
}: Props): ReactElement => {
  const [benchmark, setBenchmark] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);

  const payload = { financialModel, benchmark, portfolioType, portfolio };

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchMLOrManualData({ ...payload, signal }),
    {
      enabled: isPageLoading === false,
    },
  );

  const csvFetchData = {
    url: '/components/ml_returns_and_index_table_data',
    params: {
      financial_model: financialModel,
      output: 'csv',
    },
  };

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Portfolio Performance</Spacer>
        </Card>
      </Column>
    );
  }

  const chart = constructChartData({
    data: data?.chartData || null,
    isNotOptimisation: portfolioType !== PortfolioType.OPTIMISATION,
  });

  const handleClick = ({ points }: OnSegmentClick) => {
    const isNotABenchmark = !data?.benchmarkOptions.find((i: { label: string }) => i.label === points[0].data.name);
    if (isNotABenchmark) {
      onSelectPortfolio && onSelectPortfolio(points[0].data.name);
    }
  };

  return (
    <Column span={presentation.span} data-testid="profitAndLoss">
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>
            Profit &amp; Loss
            {portfolioType === PortfolioType.MANUAL ? portfolio && <Pill>{portfolio}</Pill> : null}
          </CardHeaderNewTitle>

          {!isPageLoading && !isLoading && (
            <CardHeaderActions>
              <Select
                testId="benchMarkSelect"
                options={data?.benchmarkOptions || []}
                isControlled={true}
                value={benchmark || data?.defaultBenchmark}
                onChange={(e) => {
                  onChangeBenchmark && onChangeBenchmark(e.target.value);
                  setBenchmark(e.target.value);
                }}
              />
              <IconButton data-testid="profitAndLossQuarterlyReturnsModal" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
              {portfolioType === PortfolioType.MACHINE_LEARNING && (
                <CSVButton
                  fetchData={csvFetchData}
                  testId="mlProfitAndLossQuarterlyReturnsButton"
                  filename="mlProfitAndLossQuarterlyReturns.csv"
                />
              )}
            </CardHeaderActions>
          )}
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="returnIndices"
            data={chart?.data || null}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chart?.layout, height, width }}
            onClick={handleClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Profit &amp; Loss
              {portfolioType === PortfolioType.MANUAL ? portfolio && <Pill>{portfolio}</Pill> : null}
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>

          <ModalChartWrap>
            <Chart
              testId="returnIndices"
              data={chart?.data || null}
              isLoading={isPageLoading || isLoading}
              layout={chart?.layout}
              onClick={handleClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default ProfitAndLoss;
