import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface DisplayState {
  isFeatureEngineeringPanelVisible: boolean;
  isPortfolioConstructionPanelVisible: boolean;
}

const initialState: DisplayState = {
  isFeatureEngineeringPanelVisible: true,
  isPortfolioConstructionPanelVisible: true,
};

export const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    setToggleFeatureEngineeringPanelVisible: (state) => {
      state.isFeatureEngineeringPanelVisible = !state.isFeatureEngineeringPanelVisible;
    },
    setTogglePortfolioConstructionPanelVisible: (state) => {
      state.isPortfolioConstructionPanelVisible = !state.isPortfolioConstructionPanelVisible;
    },
  },
});

export const { setToggleFeatureEngineeringPanelVisible, setTogglePortfolioConstructionPanelVisible } =
  displaySlice.actions;

export const selectIsFeatureEngineeringPanelVisible = (state: RootState): boolean =>
  state.display.isFeatureEngineeringPanelVisible;
export const selectIsPortfolioConstructionPanelVisible = (state: RootState): boolean =>
  state.display.isPortfolioConstructionPanelVisible;

export default displaySlice.reducer;
