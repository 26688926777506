import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  Spacer,
  CardHeaderNew,
  CardHeaderNewTitle,
  Pill,
  Column,
  ChartWrapper,
  ChartHeight,
  CardHeaderActions,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import formatToBarchartShape from './formatToBarchartShape';

import { GroupedScoreAgainstTargetRate as Props } from '../LayoutBuilder';
import { FinancialModels } from 'types/shared.types';

type FetchData = {
  financialModel: FinancialModels;
  selectedModel: string;
  signal?: AbortSignal;
};

const fetchData = async ({ financialModel, selectedModel, signal }: FetchData) => {
  const { data } = await api.get('/v2/grouped_probability_performance_data', {
    params: {
      financial_model: financialModel,
      ml_model: selectedModel,
    },
    signal,
  });

  return {
    yLabel: data.plot_data.yLabel,
    xLabel: data.plot_data.xLabel,
    chart: formatToBarchartShape(data.plot_data),
  };
};

const GroupedScoreAgainstTargetRate = ({
  id,
  financialModel,
  selectedModel,
  presentation,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, data, isError } = useQuery([id, cacheKey, { financialModel, selectedModel }], ({ signal }) =>
    fetchData({ financialModel, selectedModel, signal }),
  );

  const chartLayout = {
    yaxis: {
      title: data?.yLabel || '',
      automargin: true,
    },
    xaxis: {
      title: data?.xLabel || '',
      automargin: true,
    },
    margin: {
      t: 0,
      l: 10,
      r: 10,
      b: 50,
    },
    barmode: 'relative',
    plot_bgcolor: 'white',
    showlegend: false,
    datarevision: new Date(),
  };

  if (isError) {
    return (
      <Card>
        <Spacer data-testid="errorMessage">An error has occured with Grouped Score against target rate</Spacer>
      </Card>
    );
  }

  return (
    <Column span={presentation.span}>
      <Card>
        <CardHeaderNew>
          <CardHeaderNewTitle>
            Grouped Score Against Target Rate <Pill>{selectedModel}</Pill>
          </CardHeaderNewTitle>
          <CardHeaderActions>
            <IconButton data-testid="groupedScoreModalButton" onClick={() => setIsModalVisible(true)}>
              <FullScreen />
            </IconButton>
          </CardHeaderActions>
        </CardHeaderNew>

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="groupedScoreAgainstTargetRate"
            data={data?.chart || null}
            isLoading={isLoading}
            layout={{
              ...chartLayout,
              height,
              width,
            }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Grouped Score Against Target Rate <Pill>{selectedModel}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="groupedScoreAgainstTargetRateModal"
              data={data?.chart || null}
              isLoading={isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default GroupedScoreAgainstTargetRate;
