import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { api, findColConfig } from 'utils';

import { CardLoader, Table, Button } from 'Common';
import { Card, Column, CardHeaderNew, CardHeaderNewTitle } from 'Common/Shared.styles';
import { DownloadCsvButton, PortfolioMetricsParams } from 'Common/CsvButton/CsvButton';
import { FinancialModels } from 'types/shared.types';

import { PortfolioMetrics as Props, PortfolioType } from '../LayoutBuilder';

const ML_MANUAL_URL = '/components/portfolio_metrics';

interface FetchProps {
  financialModel: FinancialModels;
  portfolioType: PortfolioType;
  signal?: AbortSignal;
}

const fetchMLOrManualOptimisationData = async (props: FetchProps) => {
  const {
    data: { columns, rows },
  } = await api.get(ML_MANUAL_URL, {
    params: {
      financial_model: props.financialModel,
      portfolio_type: props.portfolioType,
    },
    signal: props.signal,
  });

  return { rows, columns: columns.map(findColConfig) };
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const PortfolioMetrics = ({
  id,
  presentation,
  financialModel,
  portfolioType,
  isPageLoading,
  portfolioToFind,
  onRemoveFilter,
  onClick,
  cacheKey,
  isPageError,
}: Props): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { isLoading, data, isError } = useQuery(
    [id, financialModel, portfolioType, cacheKey],
    ({ signal }) => fetchMLOrManualOptimisationData({ financialModel, portfolioType, signal }),
    {
      enabled: isPageLoading === false,
    },
  );

  if (isLoading || isPageLoading) {
    return (
      <Column span={presentation.span} data-testid="portfolioMetricsTableLoader">
        <Card>
          <LoaderWrapper>
            <CardLoader />
          </LoaderWrapper>
        </Card>
      </Column>
    );
  }

  if (isError || isPageError) {
    return <Card data-testid="errorMessage">Error</Card>;
  }

  const tableData =
    portfolioToFind && data?.rows
      ? data?.rows.filter((i: { portfolio: string }) => i.portfolio === portfolioToFind)
      : data?.rows;

  return (
    <Column span={presentation.span}>
      <Card ref={ref}>
        <CardHeaderNew>
          <CardHeaderNewTitle>Portfolio Metrics</CardHeaderNewTitle>
          <DownloadCsvButton
            fetchData={{
              url: ML_MANUAL_URL,
              params: {
                financial_model: financialModel,
                portfolio_type: portfolioType,
                output: 'csv',
              } as PortfolioMetricsParams,
            }}
            testId="downloadPortfolioMetricsButton"
            filename="metricsTable.csv"
            cacheKey={`${financialModel}${id}Metrics`}
          />
        </CardHeaderNew>

        <Table
          id="portfolioMetricsTable"
          withOverflow={false}
          maxRowsToDisplay={tableData.length === 1 ? 1 : 10}
          columns={data?.columns || []}
          data={tableData || []}
          withFilterColumn={tableData.length > 1}
          cellClickConfig={onClick && { portfolio: (arg: string | number) => onClick(arg as string) }}
        />

        {portfolioToFind && tableData.length === 1 && onRemoveFilter && (
          <Button testId="seeAllPortfolioMetrics" type="button" theme="transparent" onClick={onRemoveFilter}>
            See all
          </Button>
        )}
      </Card>
    </Column>
  );
};

export default PortfolioMetrics;
