import React, { ReactElement } from 'react';
import { H1, H2, Paragraph, TextProps } from './Text.styles';

const Text = (props: TextProps): ReactElement => {
  const TextOptions = {
    h1: (
      <H1 {...props} data-testid={props.testId}>
        {props.text}
      </H1>
    ),
    h2: (
      <H2 {...props} data-testid={props.testId}>
        {props.text}
      </H2>
    ),
    p: (
      <Paragraph {...props} data-testid={props.testId}>
        {props.text}
      </Paragraph>
    ),
  };

  return TextOptions[props.textType];
};

export default Text;
