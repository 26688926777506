import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Option } from 'types/shared.types';

import { listItemStyle } from 'cssVars';

import { StyledSlider } from 'Common';
import { Tooltip } from '@material-ui/core';

const DateWrap = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  font-size: 0.9rem;

  ${listItemStyle}
`;

interface Props {
  investmentPeriodOptions: Option[];
  selectedInvestmentPeriod: { startIndex: number; endIndex: number };
  isDisabled?: boolean;
  onSetPeriod: (arg: { startIndex: number; endIndex: number }) => void;
}

const ValueLabelComponent = (props: { children: ReactElement; open: boolean; value: number }) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const ExposedDateRangePicker = ({
  investmentPeriodOptions,
  selectedInvestmentPeriod,
  onSetPeriod,
  isDisabled = false,
}: Props): ReactElement => (
  <DateWrap>
    <StyledSlider
      min={0}
      disabled={isDisabled}
      ValueLabelComponent={ValueLabelComponent}
      max={investmentPeriodOptions.length - 1 || 0}
      value={[selectedInvestmentPeriod.startIndex, selectedInvestmentPeriod.endIndex]}
      aria-labelledby="Investment period range slider"
      valueLabelFormat={(value: number): string => investmentPeriodOptions[value].label}
      className="slider"
      marks={[
        {
          value: 0,
          label: investmentPeriodOptions[0].label,
        },
        {
          value: investmentPeriodOptions.length - 1,
          label: investmentPeriodOptions[investmentPeriodOptions.length - 1].label,
        },
      ]}
      onChange={(_e, value: number | number[]) => {
        if (typeof value !== 'number') {
          const [startId, endId] = value;

          onSetPeriod({
            endIndex: endId,
            startIndex: startId,
          });
        }
      }}
      data-testid="investmentPeriodSlider"
    />
  </DateWrap>
);

export default ExposedDateRangePicker;
