import React, { useMemo, useState, useCallback, ReactElement, useEffect, PropsWithChildren } from 'react';
import { useTable, useBlockLayout, useSortBy, useFilters, TableOptions, useResizeColumns } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList } from 'react-window';
import ReactTooltip from 'react-tooltip';

import { Button } from 'Common';
import { TableFilterWrapper, Header } from 'Common/Shared.styles';
import { DownloadCsvButton } from 'Common/CsvButton/CsvButton';

import TableFilter from './TableFilter';
import DefaultColumnFilter from './DefaultColumnFilter';
import scrollbarWidth from './scrollbarWidth';

import {
  StyledTable,
  HeaderWrapper,
  HeaderWrapperButton,
  ThWrap,
  THText,
  LinkSpan,
  Span,
  Pagination,
  PageCount,
  Resizer,
  TableWrapper,
} from './Table.styles';

import { ReactComponent as SortIcon } from './sort.svg';
import { ReactComponent as SortDescendingIcon } from './sort-desc.svg';
import { ReactComponent as SortAscendingIcon } from './sort-asc.svg';

type CellClickConfig = {
  [key: string]: (arg: string | number) => void;
};

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
  cellClickConfig?: CellClickConfig;
  columnWidth?: number;
  height?: number;
  id: string;
  initCollapsed?: boolean;
  maxRowsToDisplay?: number;
  title?: string;
  withColumnShowHide?: boolean;
  withFilterColumn?: boolean;
  withOverflow?: boolean;
  withTip?: boolean;
  financialModel?: string;
  endpoint?: string;
  isSortByVisible?: boolean;
  onFetchMore?: () => void;
  onFetchLess?: () => void;
  pagination?: {
    current: number;
    total: number;
  };
  width?: number;
  filterId?: string;
}

type CsvParams = {
  financialModel: string;
  endpoint: string;
  filterId?: string;
};

export const TABLE_ROW_HEIGHT = 25;

const DownloadTableCsv = ({ financialModel, endpoint, filterId = '' }: CsvParams): ReactElement => {
  const url = `components/${endpoint}`;
  const params = {
    financial_model: financialModel,
    output: 'csv',
    filter_id: filterId || null,
  };

  return (
    <DownloadCsvButton
      fetchData={{ url, params }}
      testId={`downloadTableButton-${endpoint}`}
      filename={`${financialModel}_${endpoint}_table.csv`}
      cacheKey={`${financialModel}${endpoint}`}
    />
  );
};

const numberFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function Table<T extends Record<string, unknown>>({
  cellClickConfig,
  columns,
  data,
  id,
  initCollapsed = false,
  maxRowsToDisplay,
  title = '',
  withColumnShowHide = false,
  withFilterColumn = false,
  withOverflow = false,
  withTip = false,
  financialModel = '',
  endpoint = '',
  isSortByVisible = true,
  onFetchMore,
  onFetchLess,
  pagination,
  width,
  filterId = '',
}: PropsWithChildren<TableProperties<T>>): ReactElement {
  const [isCollapsed, setIsCollapsed] = useState(initCollapsed);
  const toggleOptions = () => setIsCollapsed(!isCollapsed);

  const defaultColumn = useMemo(() => ({ width: 170, Filter: DefaultColumnFilter }), []);
  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { allColumns, getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useSticky,
  );

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      const isOdd = index % 2;
      const className = isOdd ? 'trOdd' : 'tr';

      prepareRow(row);

      const trProps = withOverflow
        ? { ...row.getRowProps({ style, className }) }
        : {
            ...row.getRowProps({
              className,
              style: { ...style, width: '100%', display: 'flex' },
            }),
          };

      return (
        <>
          <div {...trProps}>
            {row.cells.map((cell) => {
              const isNumber = typeof cell.value === 'number';
              const classNameExtended = 'td'.concat(isNumber ? ' right' : '');
              const tip = withTip ? { 'data-tip': row.cells[0].value } : null;

              const tdProps = withOverflow
                ? { ...cell.getCellProps({ className: classNameExtended }), ...tip }
                : {
                    ...cell.getCellProps({
                      className: classNameExtended,
                      style: { display: 'flex', boxSizing: 'border-box', width: '100%' },
                    }),
                    ...tip,
                  };

              const onClick = cellClickConfig && cellClickConfig[cell.column.id];

              const cellValue = isNumber ? numberFormatter.format(cell.value) : cell.render('Cell');

              if (onClick) {
                return (
                  // key is added in by helper method
                  // eslint-disable-next-line react/jsx-key
                  <div {...tdProps} onClick={() => onClick(cell.value)} data-testid="tableLink">
                    <LinkSpan>{cell.value === -Infinity ? '-' : cellValue}</LinkSpan>
                  </div>
                );
              }

              return (
                // key is added in by helper method
                // eslint-disable-next-line react/jsx-key
                <div {...tdProps}>
                  <Span>{cell.value === -Infinity ? '-' : cellValue}</Span>
                </div>
              );
            })}
          </div>

          {onFetchMore && onFetchLess && pagination && rows.length - 1 === index && (
            <Pagination {...row.getRowProps({ style })} key="pagination">
              <Button theme="transparent" type="button" onClick={onFetchLess} isDisabled={pagination.current === 0}>
                Back
              </Button>

              <PageCount>{`${pagination.current + 1} of ${pagination.total}`}</PageCount>

              <Button
                theme="transparent"
                type="button"
                onClick={onFetchMore}
                isDisabled={pagination.current === pagination.total}
              >
                Next
              </Button>
            </Pagination>
          )}
        </>
      );
    },
    [prepareRow, rows],
  );

  const height =
    maxRowsToDisplay && rows.length >= maxRowsToDisplay
      ? maxRowsToDisplay * TABLE_ROW_HEIGHT
      : rows.length * TABLE_ROW_HEIGHT;

  return (
    <div data-testid={id} style={{ width: '100%' }}>
      {withColumnShowHide && (
        <TableFilterWrapper>
          {isCollapsed ? (
            <HeaderWrapper>
              {title && <Header>{title}</Header>}

              <HeaderWrapperButton>
                <Button type="button" testId="showFilterButton" theme="transparent" onClick={toggleOptions}>
                  Options
                </Button>
              </HeaderWrapperButton>
              <DownloadTableCsv financialModel={financialModel} endpoint={endpoint} filterId={filterId || ''} />
            </HeaderWrapper>
          ) : (
            <TableFilter id={`${id}Filter`} columns={allColumns} onClose={toggleOptions} />
          )}
        </TableFilterWrapper>
      )}

      <TableWrapper>
        <StyledTable
          {...getTableProps({ className: 'table' })}
          withOverflow={Boolean(width && width > totalColumnsWidth)}
        >
          <div style={{ width: '100%' }}>
            {headerGroups.map((headerGroup) => {
              const trProps = withOverflow
                ? { ...headerGroup.getHeaderGroupProps({ className: 'tr' }) }
                : { ...headerGroup.getHeaderGroupProps({ style: { width: '100%' }, className: 'tr' }) };

              return (
                // key is added in by helper method
                // eslint-disable-next-line react/jsx-key
                <div {...trProps}>
                  {headerGroup.headers.map((column) => {
                    const thProps = withOverflow
                      ? {
                          ...column.getHeaderProps({
                            className: 'th',
                            style: {
                              display: 'flex',
                            },
                          }),
                        }
                      : {
                          ...column.getHeaderProps({
                            style: {
                              width: '100%',
                            },
                            className: 'th',
                          }),
                        };

                    const toggleProps = isSortByVisible && column.getSortByToggleProps();

                    return (
                      // key is added in by helper method
                      // eslint-disable-next-line react/jsx-key
                      <div {...thProps}>
                        <ThWrap>
                          <THText {...toggleProps} data-testid={column.id}>
                            {isSortByVisible && (
                              <div>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDescendingIcon />
                                  ) : (
                                    <SortAscendingIcon />
                                  )
                                ) : (
                                  <SortIcon />
                                )}
                              </div>
                            )}

                            {column.render('Header')}
                          </THText>

                          {withOverflow && <Resizer {...column.getResizerProps()} isResizing={column.isResizing} />}

                          {withFilterColumn && column.canFilter && column.render('Filter')}
                        </ThWrap>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          {rows.length === 0 ? (
            <p data-testid="noTableResults">No results…</p>
          ) : (
            <div {...getTableBodyProps()}>
              <FixedSizeList
                height={height} // TODO PDT-475 move all height calculation here
                itemCount={rows.length}
                itemSize={TABLE_ROW_HEIGHT}
                width={withOverflow ? totalColumnsWidth + scrollBarSize : '100%'}
              >
                {RenderRow}
              </FixedSizeList>
            </div>
          )}
        </StyledTable>
      </TableWrapper>

      {withTip && <ReactTooltip />}
    </div>
  );
}

export default Table;
