import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, formatDate, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  ChartWrapper,
  CardTip,
  CardHeaderActions,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { TopLevelReturns as Props, PortfolioType } from 'Common/LayoutBuilder/LayoutBuilder';

type FetchTopLevelReturnsProps = {
  financialModel: string;
  portfolio: string;
  portfolioType: PortfolioType;
  attributionDate?: string;
  signal?: AbortSignal;
};

const fetchTopLevelReturns = async ({
  financialModel,
  portfolio,
  attributionDate,
  portfolioType,
  signal,
}: FetchTopLevelReturnsProps) => {
  const {
    data: { available_dates },
  } = await api.get('/v2/attribution/available_portfolio_dates', {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
    },
    signal,
  });

  const dateUsed = attributionDate || available_dates[0].value;

  const { data } = await api.get('/v2/attribution/top_level_returns', {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
      selected_date: attributionDate || available_dates[0].value,
    },
    signal,
  });

  return {
    dateUsed: dateUsed,
    availableDates: available_dates
      .sort((a: string, b: string) => {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .map((date: string) => ({
        label: formatDate({ date }),
        value: date,
      })),
    returnsData: [
      {
        x: data.y,
        y: data.x,
        type: 'bar',
      },
    ],
  };
};

const TopLevelReturns = ({
  id,
  financialModel,
  portfolio,
  presentation,
  attributionDate,
  isPageLoading,
  onChange,
  portfolioType,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const payload = { financialModel, portfolio, attributionDate, portfolioType };
  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchTopLevelReturns({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Top Level Returns</Spacer>
        </Card>
      </Column>
    );
  }

  const chartLayout = {
    margin: {
      t: 10,
      r: 10,
      l: 10,
      b: 10,
    },
    yaxis: {
      gridcolor: 'light grey',
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    hovermode: 'closest',
    showlegend: false,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    autosize: true,
    datarevision: new Date(),
  };

  return (
    <Column span={presentation.span} data-testid="topLevelReturns">
      <Card>
        {hasPortfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>Top Level Returns</CardHeaderNewTitle>

            {isPageLoading === false && isLoading === false && (
              <CardHeaderActions>
                <Select
                  testId="attributionDateSelect"
                  options={data?.availableDates || []}
                  value={attributionDate || data?.dateUsed}
                  isControlled={true}
                  onChange={(e) => onChange(e.target.value)}
                />
                <IconButton data-testid="topLevelModalButton" onClick={() => setIsModalVisible(true)}>
                  <FullScreen />
                </IconButton>
              </CardHeaderActions>
            )}
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see top level returns please construct
            <br />a <strong>portfolio</strong> above
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="topLevelReturns"
            data={data?.returnsData || null}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Top Level Overall Attributions</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="topLevelReturnsModal"
              data={data?.returnsData || null}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default TopLevelReturns;
