import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  ChartWrapper,
  CardTip,
  CardHeaderActions,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
export type Presentation = {
  span: 'half' | 'full' | 'third';
};

import { TopLevelOverallAttributions as Props, PortfolioType } from 'Common/LayoutBuilder/LayoutBuilder';

type FetchTopLevelOverallAttributionProps = {
  financialModel: string;
  portfolio: string;
  date?: string;
  portfolioType: PortfolioType;
  signal?: AbortSignal;
};

type Point = {
  x: string;
};

type HandleAttributionViewClickProps = {
  points: Point[];
};

const fetchTopLevelOverallAttribution = async ({
  financialModel,
  portfolio,
  date,
  portfolioType,
  signal,
}: FetchTopLevelOverallAttributionProps) => {
  const {
    data: { available_options },
  } = await api.get('/v2/attribution/available_stock_filter_options', {
    params: {
      financial_model: financialModel,
    },
    signal,
  });

  const { data } = await api.get('/v2/attribution/top_level_overall_attribution', {
    params: {
      financial_model: financialModel,
      portfolio_type: portfolioType,
      portfolio,
      selected_date: date,
    },
    signal,
  });

  return {
    availableOptions: available_options.map((date: string) => ({
      label: date,
      value: date,
    })),
    overallAttributionsData: [
      {
        x: data.x,
        y: data.y,
        type: 'bar',
      },
    ],
  };
};

const TopLevelOverallAttributions = ({
  id,
  financialModel,
  portfolio,
  presentation,
  date,
  setAttributionSortBy,
  view,
  isPageLoading,
  onChangeAttributionSegment,
  attributionSortBy,
  portfolioType,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const payload = { financialModel, portfolio, date, portfolioType };
  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchTopLevelOverallAttribution({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span} data-testid="topLevelOverallAttribution">
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Top Level Overall Attribution</Spacer>
        </Card>
      </Column>
    );
  }

  const handleAttributionViewClick = ({ points }: HandleAttributionViewClickProps) => {
    if (points.length > 0) {
      onChangeAttributionSegment(points[0].x);
    }
  };

  const barChartColors = {
    regional: ['rgba(204,204,204,1)', 'rgba(217,132,22,0.8)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)'],
    sectoral: ['rgba(204,204,204,1)', 'rgba(204,204,204,1)', 'rgba(46,54,115, 0.8)', 'rgba(204,204,204,1)'],
    stock: ['rgba(204,204,204,1)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)', 'rgba(222,45,38,0.8)'],
  };

  const formatBarChart = (data: { x: string[]; y: number[] }[]) => {
    return [
      {
        x: data[0].x,
        y: data[0].y,
        type: 'bar',
        marker: {
          color: barChartColors[view as 'regional'],
        },
      },
    ];
  };

  const chartData = data && formatBarChart(data.overallAttributionsData);

  const chartLayout = {
    margin: {
      t: 10,
      r: 10,
      b: 10,
      l: 10,
    },
    yaxis: {
      gridcolor: 'light grey',
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    showlegend: false,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    autosize: true,
    datarevision: new Date(),
  };

  return (
    <Column span={presentation.span}>
      <Card>
        {hasPortfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>Top Level Overall Attributions</CardHeaderNewTitle>
            <CardHeaderActions>
              {isPageLoading === false && isLoading === false && view === 'stock' && (
                <Select
                  testId="selectAttributionSrt"
                  options={data?.availableOptions || []}
                  value={attributionSortBy}
                  isControlled={true}
                  onChange={(e) => setAttributionSortBy(e.target.value)}
                />
              )}
              <IconButton data-testid="topLevelOverallModalButton" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see top level overall attributions please construct
            <br />a <strong>portfolio</strong> above
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="topLevelOverallAttributions"
            data={chartData || null}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chartLayout, height, width }}
            onClick={handleAttributionViewClick}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>Top Level Overall Attributions</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="topLevelOverallAttributionsModal"
              data={chartData || null}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
              onClick={handleAttributionViewClick}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default TopLevelOverallAttributions;
