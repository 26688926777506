import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { api, useDivSize } from 'utils';
import { Chart, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  CardHeaderActions,
  Pill,
  ChartWrapper,
  CardTip,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { FinancialModels } from 'types/shared.types';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { PortfolioType, TimeSeriesExposure as Props } from '../LayoutBuilder';
import formatToTimeSeries from './formatToTimeSeries';

type FetchChartData = {
  financialModel: FinancialModels;
  portfolio: string;
  portfolioType: PortfolioType;
  feature: string;
  crossSectionGrouping: string;
  signal?: AbortSignal;
};

const fetchChartData = async ({
  portfolioType,
  financialModel,
  portfolio,
  feature,
  crossSectionGrouping,
  signal,
}: FetchChartData) => {
  const { data } = await api.get('/components/time_series_exposure', {
    params: {
      portfolio_type: portfolioType,
      financial_model: financialModel,
      portfolio_dropdown: portfolio || null,
      selected_feature: feature,
      q_feature: crossSectionGrouping,
    },
    signal,
  });

  return formatToTimeSeries(data.exp_data);
};

const TimeSeriesExposure = ({
  financialModel,
  portfolio,
  portfolioType,
  feature,
  crossSectionGrouping,
  presentation,
  id,
  isPageLoading,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const payload = {
    portfolioType,
    financialModel,
    portfolio,
    feature,
    crossSectionGrouping,
  };

  const hasRequiredFields = Boolean(portfolio) && Boolean(feature);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchChartData({ ...payload, signal }),
    {
      enabled: hasRequiredFields,
    },
  );

  const chartLayout = {
    margin: {
      t: 0,
      l: 50,
      r: 20,
      b: 20,
    },
    yaxis: {
      automargin: true,
      title: 'Portfolio Feature Exposure',
    },
    xaxis: {
      automargin: true,
      tickangle: 30,
    },
    barmode: 'relative',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    showlegend: false,
    datarevision: new Date(),
  };

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Time Series Exposure</Spacer>
        </Card>
      </Column>
    );
  }

  return (
    <Column span={presentation.span} data-testid="timeSeriesExposure">
      <Card>
        {hasRequiredFields ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>
              Time Series Exposure <Pill>{portfolio}</Pill> <Pill>{feature}</Pill>
            </CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see exposure over time please select
            <br />a <strong>portfolio</strong> and <strong>feature</strong> above
          </CardTip>
        )}

        <ChartWrapper ref={parentRef}>
          <Chart
            testId="timeSeriesExposure"
            data={data || null}
            isLoading={isPageLoading || isLoading}
            layout={{
              ...chartLayout,
              height,
              width,
            }}
          />

          <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
            <CardHeaderNew>
              <CardHeaderNewTitle>
                Time Series Exposure <Pill>{portfolio}</Pill> <Pill>{feature}</Pill>
              </CardHeaderNewTitle>
              <CardHeaderActions>
                <IconButton onClick={() => setIsModalVisible(false)}>
                  <CloseDark />
                </IconButton>
              </CardHeaderActions>
            </CardHeaderNew>
            <ModalChartWrap>
              <Chart
                testId="timeSeriesExposureModal"
                data={data || null}
                isLoading={isPageLoading || isLoading}
                layout={chartLayout}
              />
            </ModalChartWrap>
          </Modal>
        </ChartWrapper>
      </Card>
    </Column>
  );
};

export default TimeSeriesExposure;
