import React, { forwardRef, ReactElement, ChangeEvent } from 'react';
import styled from 'styled-components';

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  id?: string;
  testId?: string;
  name?: string;
  ref: HTMLElement;
  options: Option[];
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  isControlled?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  isDisabled?: boolean;
  type?: string;
  label?: string;
};

const Wrapper = styled.div<{ type: string }>`
  position: relative;
  width: 100%;
  display; flex;
  padding-bottom: 0rem;
  color: ${({ theme }) => theme.colors.darkGrey};

  label {
    font-size: 10px;
    padding-left: 3px;
    font-weight: bold;
  }

  ${({ theme, type }) => {
    if (type === 'secondary') {
      return `
      border-left: 1px solid ${theme.colors.lightGrey};
      border-bottom: 1px solid ${theme.colors.lightGrey};
  `;
    }
  }}
`;

const SelectStyle = styled.select<{ type: string; value?: string | number }>`
  outline: none;
  border: none;
  background: none;
  text-align-last: right;
  padding-right: 0.75rem;
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.darkGrey};
  height: 100%;

  ${({ theme, type, value }) => {
    if (type === 'secondary') {
      return `
        color: ${!value ? theme.colors.darkGrey : theme.colors.black};
        box-sizing: border-box;
        width: 100%;
        text-align-last: unset;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 0;
        height: auto;
        line-height: unset;
        padding: 0.5rem;
        padding-left: 0;
        margin-left: 0;
        border-bottom: ${!value ? `2px solid ${theme.colors.red}` : `2px solid ${theme.colors.white}`};
        font-size: 0.75rem;
        font-weight: ${value && 'bold'};
        
        &:disabled:hover {
          border-bottom: 2px solid ${theme.colors.white};
          cursor: not-allowed;
        }
        
        &:hover,
        &:focus {
          border-bottom: 2px solid ${theme.colors.primary};
        }
      `;
    }
  }}
`;

const Select = forwardRef(
  (
    {
      options,
      id,
      testId,
      name,
      defaultValue = '',
      onChange,
      value,
      isControlled = false,
      isDisabled = false,
      type = '',
      label,
    }: Props,
    ref,
  ): ReactElement => {
    const props = isControlled ? { value, onChange } : { defaultValue };

    return (
      <Wrapper type={type}>
        {label && <label htmlFor={id}>{label}</label>}
        <SelectStyle
          {...props}
          id={id}
          data-testid={testId}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={ref as any}
          name={name}
          disabled={isDisabled}
          type={type}
        >
          <option value="" disabled>
            --Choose an option--
          </option>

          {options.map(({ label, value: optionValue }) => (
            <option key={optionValue} value={optionValue}>
              {label}
            </option>
          ))}
        </SelectStyle>
      </Wrapper>
    );
  },
);

Select.displayName = 'Select';

export default Select;
