import React, { ReactElement, ChangeEvent } from 'react';

import Button from '../Button';
import Input from '../Input';

import { HeaderStyle, InputWrap, ClearButton } from './FilterSearchHeader.style';
import { ReactComponent as ClearSearchIcon } from './icon_close.svg';

type Props = {
  testId: string;
  onClose?: () => void;
  onClear: () => void;
  onSearch: (arg: string) => void;
  searchText: string;
};

const FilterSearchHeader = ({ testId, onClose, onClear, onSearch, searchText }: Props): ReactElement => {
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <HeaderStyle data-testid={`${testId}Wrapper`}>
      <InputWrap>
        <Input
          testId={`${testId}Input`}
          type="text"
          placeholder="Filter results…"
          defaultValue={searchText}
          onChange={handleSearch}
        />

        {searchText && (
          <ClearButton type="button" onClick={onClear}>
            <ClearSearchIcon />
          </ClearButton>
        )}
      </InputWrap>

      {Boolean(onClose) && (
        <Button type="button" theme="transparent" onClick={onClose} testId="onClose">
          Close
        </Button>
      )}
    </HeaderStyle>
  );
};

export default FilterSearchHeader;
