import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import Carousel from './Carousel';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';

import { ReactComponent as Logo } from './logo.svg';

const StyledSection = styled.section`
  width: 100vw;
  height: 100vh;

  .featuresExpose {
    display: none;
    padding: 4rem 2rem;
    width: 100%;
    height: 100vh;
    min-height: 350px;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.white};
    height: 100vh;
    padding: 2rem 5vw;
    min-height: 350px;
  }

  p {
    text-align: center;
    span {
      display: block;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .loginForm {
      padding: 4rem 2rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1.5fr;

    .loginForm,
    .featuresExpose {
      min-height: 750px;
    }

    .featuresExpose {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to top right, ${theme.colors.primary}, ${theme.colors.black});
      height: 100vh;
    }
  } ;
`;

const SignInV2 = (): ReactElement => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <StyledSection data-testid="signInPage">
      <section className="loginForm">
        <Logo />
        {showForgotPassword ? (
          <ForgotPassword switchView={() => setShowForgotPassword(false)} />
        ) : (
          <LoginForm switchView={() => setShowForgotPassword(true)} />
        )}
        <p>
          <span>Don&apos;t have an account yet? </span>
          <a href="mailto:info@insg.ai?subject=Request a Demo">Request a Demo</a>
        </p>
      </section>
      <section className="featuresExpose">
        {/* TODO PDT-2132 */}
        <Carousel />
      </section>
    </StyledSection>
  );
};

export default SignInV2;
