import React, { useState, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { Auth } from 'aws-amplify';

import { Button, Text } from 'Common';
import { Wrapper, Label, Input, SwitchViewButton, StyledForm } from '../SignIn.styles';
import theme from 'theme';

import ChangePassword from '../ChangePassword';

interface ForgotPasswordProps {
  switchView: () => void;
}

interface ForgotPasswordResponse {
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
}

const ForgotPassword = ({ switchView }: ForgotPasswordProps): ReactElement => {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [confirmView, setConfirmView] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async (email: string): Promise<ForgotPasswordResponse> => {
    const request = await Auth.forgotPassword(email);
    return request;
  };

  const mutation = useMutation(handleForgotPassword, {
    onError: (error: Error) => {
      setValid(false);
      console.log('unable to reset password', error);
    },
    onSuccess: () => setConfirmView(true),
  });

  const { data, error } = mutation;

  return !confirmView ? (
    <StyledForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        mutation.mutate(email);
      }}
    >
      <Wrapper>
        <Text textType="h1" color={theme.colors.black} align="left" text="Forgot your password?" />
        <Text
          textType="h2"
          color={theme.colors.darkGrey}
          align="left"
          text="Enter your email address below and we will send a message to reset your password"
        />
      </Wrapper>
      <Wrapper>
        <Label>Email Address</Label>
        <Input
          type="text"
          name="email"
          value={email}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
          autoCapitalize="off"
        />
        <SwitchViewButton onClick={switchView} unStyled>
          Return to Login
        </SwitchViewButton>
      </Wrapper>

      {!valid && error && <Text textType="p" color={theme.colors.red} align="right" text={error.message} />}

      <Button theme="primary" size="large" type="submit" isDisabled={!email || mutation.isLoading}>
        Reset Password
      </Button>
    </StyledForm>
  ) : (
    <ChangePassword
      backToForgotPassword={() => setConfirmView(false)}
      email={email}
      destination={data?.CodeDeliveryDetails.Destination || ''}
      backToLogin={() => {
        setConfirmView(false);
        switchView();
      }}
    />
  );
};

export default ForgotPassword;
