import React, { ReactElement, ChangeEvent, useMemo } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

type FilterValue = (number | undefined)[];

type NumberInputFilterProps = {
  column: {
    filterValue: FilterValue;
    preFilteredRows: Row[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilter: (arg: any) => void;
    id: string;
  };
};

const StyledNumberInputFilter = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-self: start;
  margin-top: 0.5rem;
  width: 100%;
  grid-gap: 4px;
  align-items: center;
  input {
    width: 90%;
  }
`;

function NumberInputFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: NumberInputFilterProps): ReactElement {
  const [min, max] = useMemo(() => {
    let newMin = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let newMax = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      newMin = Math.min(row.values[id], newMin);
      newMax = Math.max(row.values[id], newMax);
    });
    return [newMin, newMax];
  }, [id, preFilteredRows]);

  return (
    <StyledNumberInputFilter>
      <input
        type="number"
        value={(filterValue.length && filterValue[0]) || ''}
        placeholder={min === -Infinity ? 'Min' : `${min}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        data-testid="numberFilterMin"
      />
      to
      <input
        type="number"
        value={(filterValue.length && filterValue[1]) || ''}
        placeholder={max === Infinity ? 'Max' : `${max}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        data-testid="numberFilterMax"
      />
    </StyledNumberInputFilter>
  );
}

export default NumberInputFilter;
