import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from './icon_full-screen-view.svg';

const Button = styled.button`
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
`;

type Props = {
  'data-testid': string;
  onClick: () => void;
};

const ExpansionButton = ({ ...props }: Props): ReactElement => (
  <Button type="button" {...props}>
    <Icon />
  </Button>
);

export default ExpansionButton;
