import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Text } from 'Common';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as CarouselContainer } from 'react-responsive-carousel';
import theme from 'theme';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 0.5rem;
    height: 250px;
    width: 400px;
    box-shadow: ${({ theme }) => theme.boxShadow};
  }
`;

const Carousel = (): ReactElement => {
  return (
    <CarouselContainer
      width="450px"
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      thumbWidth={99}
    >
      <StyledContainer>
        <img src="/images/dataExploration.png" alt="Data exploration" />
        <Text
          textType="p"
          color={theme.colors.white}
          text="Explore your data through visualisations and descriptive summaries."
          align="center"
        />
      </StyledContainer>
      <StyledContainer>
        <img src="/images/dataFiltering.png" alt="Dataset filtering" />
        <Text
          textType="p"
          color={theme.colors.white}
          text="Filter your data by region, sector or country, to construct and analyse focused portfolios."
          align="center"
        />
      </StyledContainer>
      <StyledContainer>
        <img src="/images/portfolioConstruction.png" alt="Portfolio construction" />
        <Text
          textType="p"
          color={theme.colors.white}
          text="Construct portfolios using Screening, Machine Learning or Optimisation techniques."
          align="center"
        />
      </StyledContainer>
      <StyledContainer>
        <img src="/images/attribution.png" alt="Attribution" />
        <Text
          textType="p"
          color={theme.colors.white}
          text="Manage the risk of your portfolio by analysing exposures and understanding performance attribution."
          align="center"
        />
      </StyledContainer>
    </CarouselContainer>
  );
};

export default Carousel;
