import { format } from 'date-fns';

interface Props {
  date: string;
  isDatasetMonthly?: boolean;
}

const formatDate = ({ date, isDatasetMonthly = false }: Props): string =>
  format(new Date(date), isDatasetMonthly ? 'MMM yy' : 'QQQ y');

export default formatDate;
