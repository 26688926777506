import React, { ReactElement, useState, useRef } from 'react';
import { useQuery } from 'react-query';

import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  ChartWrapper,
  CardTip,
  CardHeaderActions,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { AttributionWeights as Props } from '../LayoutBuilder';
import fetchAttributionWeights from './fetchAttributionWeights';
import { useDivSize } from 'utils';

const AttributionWeights = ({
  id,
  financialModel,
  portfolio,
  presentation,
  attributionDate,
  attributionView,
  attributionSector,
  attributionSortBy,
  attributionRegion,
  isPageLoading,
  portfolioType,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [relative, setRelative] = useState(true);
  const weightOptions = [
    {
      value: 'Relative Weights',
      label: 'Relative Weights',
    },
    {
      value: 'Absolute Weights',
      label: 'Absolute Weights',
    },
  ];

  const payload = {
    financialModel,
    portfolio,
    attributionDate,
    attributionView,
    attributionSector,
    attributionSortBy,
    attributionRegion,
    portfolioType,
  };
  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchAttributionWeights({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  const chartData = !relative && data ? [data[0], data[1]] : data ? [data[2]] : null;

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Attribution Weights</Spacer>
        </Card>
      </Column>
    );
  }

  const chartLayout = {
    margin: {
      t: 10,
      r: 10,
      b: 10,
      l: 10,
    },
    yaxis: {
      gridcolor: 'light grey',
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    hovermode: 'closest',
    showlegend: false,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    autosize: true,
    datarevision: new Date(),
  };

  return (
    <Column span={presentation.span} data-testid="attributionWeights">
      <Card>
        {hasPortfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>{attributionView} Weights</CardHeaderNewTitle>
            <CardHeaderActions>
              {isPageLoading === false && isLoading === false && (
                <Select
                  testId="attributionDateSelect"
                  options={weightOptions}
                  value={relative ? 'Relative Weights' : 'Absolute Weights'}
                  isControlled={true}
                  onChange={(e) => setRelative(e.target.value === 'Relative Weights')}
                />
              )}
              <IconButton data-testid="attributionWeightsModalButton" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see attribution weights please select
            <br />a <strong>portfolio</strong> to the left
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="attributionWeights"
            data={chartData}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>{attributionView} Weights</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="attributionWeightsModal"
              data={chartData}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default AttributionWeights;
