import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { Chart, Select, Modal } from 'Common';
import {
  Card,
  Spacer,
  Column,
  CardHeaderNew,
  CardHeaderNewTitle,
  ChartWrapper,
  CardTip,
  CardHeaderActions,
  ChartHeight,
  IconButton,
  ModalChartWrap,
} from 'Common/Shared.styles';
import { ReactComponent as FullScreen } from 'icons/fullscreen.svg';
import { ReactComponent as CloseDark } from 'icons/closeDarkLarge.svg';
import { AttributionContributions as Props } from '../LayoutBuilder';

import fetchAttributionContributions from './fetchAttributionContributions';
import { useDivSize } from 'utils';

const AttributionContributions = ({
  id,
  financialModel,
  portfolio,
  presentation,
  attributionDate,
  attributionView,
  attributionSector,
  attributionSortBy,
  attributionRegion,
  selectAttributionRegion,
  selectAttributionSector,
  isPageLoading,
  portfolioType,
  cacheKey,
}: Props): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDivSize(parentRef);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const payload = {
    financialModel,
    portfolio,
    attributionDate,
    attributionView,
    attributionSector,
    attributionSortBy,
    attributionRegion,
    portfolioType,
  };

  const hasPortfolio = Boolean(portfolio);

  const { isLoading, data, isError } = useQuery(
    [id, cacheKey, payload],
    ({ signal }) => fetchAttributionContributions({ ...payload, signal }),
    {
      enabled: hasPortfolio,
    },
  );

  if (isError) {
    return (
      <Column span={presentation.span}>
        <Card>
          <Spacer data-testid="errorMessage">An error has occured with Contributions</Spacer>
        </Card>
      </Column>
    );
  }

  const chartLayout = {
    margin: {
      t: 10,
      b: 10,
      l: 10,
      r: 10,
    },
    yaxis: {
      gridcolor: 'light grey',
      automargin: true,
    },
    xaxis: {
      automargin: true,
    },
    hovermode: 'closest',
    showlegend: false,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    autosize: true,
    datarevision: new Date(),
  };

  return (
    <Column span={presentation.span} data-testid="attributionContributions">
      <Card>
        {hasPortfolio ? (
          <CardHeaderNew>
            <CardHeaderNewTitle>{attributionView} Contributions</CardHeaderNewTitle>

            <CardHeaderActions>
              {isPageLoading == false && isLoading === false && (
                <>
                  {attributionView === 'stock' && (
                    <Select
                      testId="attributionSectorSelect"
                      options={data?.availableSectors || []}
                      value={attributionSector}
                      isControlled={true}
                      onChange={(e) => selectAttributionSector(e.target.value)}
                    />
                  )}

                  {(attributionView === 'stock' || attributionView === 'sectoral') && (
                    <Select
                      testId="attributionRegionSelect"
                      options={data?.availableRegions || []}
                      value={attributionRegion}
                      isControlled={true}
                      onChange={(e) => selectAttributionRegion(e.target.value)}
                    />
                  )}
                </>
              )}
              <IconButton data-testid="attributionContributionsModalButton" onClick={() => setIsModalVisible(true)}>
                <FullScreen />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
        ) : (
          <CardTip>
            To see contributions please select
            <br />a <strong>portfolio</strong> to the left
          </CardTip>
        )}

        <ChartWrapper ref={parentRef} chartHeight={ChartHeight.Half}>
          <Chart
            testId="attributionContributions"
            data={data?.contributionsData || null}
            isLoading={isPageLoading || isLoading}
            layout={{ ...chartLayout, height, width }}
          />
        </ChartWrapper>

        <Modal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <CardHeaderNew>
            <CardHeaderNewTitle>{attributionView} Contributions</CardHeaderNewTitle>
            <CardHeaderActions>
              <IconButton onClick={() => setIsModalVisible(false)}>
                <CloseDark />
              </IconButton>
            </CardHeaderActions>
          </CardHeaderNew>
          <ModalChartWrap>
            <Chart
              testId="attributionContributionsModal"
              data={data?.contributionsData || null}
              isLoading={isPageLoading || isLoading}
              layout={chartLayout}
            />
          </ModalChartWrap>
        </Modal>
      </Card>
    </Column>
  );
};

export default AttributionContributions;
