import React, { useMemo, ReactElement, useEffect, PropsWithChildren, useState } from 'react';
import { useTable, useBlockLayout, useSortBy, useFilters, TableOptions, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList } from 'react-window';
import ReactTooltip from 'react-tooltip';

import { Button, DropdownButton } from 'Common';
import { Header, CardHeaderActions } from 'Common/Shared.styles';
import { DownloadCsvButton } from 'Common/CsvButton/CsvButton';
import DefaultColumnFilter from './DefaultColumnFilter';

import StyledTableSticky, { THText, ThWrap, HeaderWrapper, Pagination, PageCount } from './TableSticky.styles';

import { ReactComponent as SortIcon } from 'icons/sort.svg';
import { ReactComponent as SortDescendingIcon } from 'icons/sort-desc.svg';
import { ReactComponent as SortAscendingIcon } from 'icons/sort-asc.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';

import TableFilter from './TableFilter';

interface CellClickConfig {
  [key: string]: (arg: string | number) => void;
}

interface CsvParams {
  financialModel: string;
  endpoint: string;
  filterId?: string;
  dataGroupingParams?: {
    primary_group: string;
    sub_group: string;
    start_date: string;
    end_date: string;
  };
}

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
  cellClickConfig?: CellClickConfig;
  columnWidth?: number;
  height?: number;
  id: string;
  initCollapsed?: boolean;
  maxRowsToDisplay?: number;
  title?: string | JSX.Element;
  withColumnShowHide?: boolean;
  withFilterColumn?: boolean;
  financialModel?: string;
  endpoint?: string;
  isSortByVisible?: boolean;
  width?: number;
  filterId?: string;
  fixedHeight?: number;
  isPaginated?: boolean;
  cardWidth?: number;
  dataGroupingParams?: {
    primary_group: string;
    sub_group: string;
    start_date: string;
    end_date: string;
  };
}

export const TABLE_ROW_HEIGHT = 40;

const DownloadTableCsv = ({ financialModel, endpoint, filterId = '', dataGroupingParams }: CsvParams): ReactElement => {
  const url = `components/${endpoint}`;
  const params = {
    financial_model: financialModel,
    output: 'csv',
    filter_id: filterId || null,
    ...dataGroupingParams,
  };

  return (
    <DownloadCsvButton
      fetchData={{ url, params }}
      testId={`downloadTableButton-${endpoint}`}
      filename={
        dataGroupingParams
          ? `${dataGroupingParams.primary_group}${dataGroupingParams.sub_group}Table.csv`
          : `${financialModel}_${endpoint}_table.csv`
      }
      cacheKey={`${financialModel}${endpoint}`}
    />
  );
};

const numberFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function TableSticky<T extends Record<string, unknown>>({
  columns,
  data,
  id,
  initCollapsed = false,
  maxRowsToDisplay,
  title = '',
  withColumnShowHide = false,
  withFilterColumn = false,
  financialModel = '',
  endpoint = '',
  isSortByVisible = true,
  filterId = '',
  fixedHeight,
  isPaginated = false,
  cardWidth = 0,
  dataGroupingParams,
}: PropsWithChildren<TableProperties<T>>): ReactElement {
  const [isCollapsed, setIsCollapsed] = useState(initCollapsed);
  const toggleOptions = () => setIsCollapsed(!isCollapsed);
  const defaultColumn = useMemo(() => ({ width: 170, Filter: DefaultColumnFilter }), []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    prepareRow,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    useSticky,
    usePagination,
  );

  const height =
    maxRowsToDisplay && rows.length >= maxRowsToDisplay
      ? maxRowsToDisplay * TABLE_ROW_HEIGHT
      : (rows.length + 1) * TABLE_ROW_HEIGHT;

  const withOverflow = totalColumnsWidth > cardWidth;

  const TableHeader = (
    <div className="header" style={{ width: '100%' }}>
      {headerGroups.map((headerGroup) => {
        const trProps = withOverflow
          ? { ...headerGroup.getHeaderGroupProps({ className: 'tr' }) }
          : { ...headerGroup.getHeaderGroupProps({ style: { width: '100%' }, className: 'tr' }) };

        return (
          // key is added in by helper method
          // eslint-disable-next-line react/jsx-key
          <div {...trProps}>
            {headerGroup.headers.map((column) => {
              const thProps = withOverflow
                ? {
                    ...column.getHeaderProps({
                      className: 'th',
                      style: {
                        display: 'flex',
                      },
                    }),
                  }
                : {
                    ...column.getHeaderProps({
                      style: {
                        width: '100%',
                      },
                      className: 'th',
                    }),
                  };

              const toggleProps = isSortByVisible && column.getSortByToggleProps({ title: null });

              return (
                // key is added in by helper method
                // eslint-disable-next-line react/jsx-key
                <div {...thProps}>
                  <ThWrap>
                    <THText {...toggleProps} data-testid={column.id} data-tip={true} data-for={column.id}>
                      <p>{column.render('Header')}</p>
                      {isSortByVisible && (
                        <div>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDescendingIcon />
                            ) : (
                              <SortAscendingIcon />
                            )
                          ) : (
                            <SortIcon />
                          )}
                        </div>
                      )}
                    </THText>

                    {withFilterColumn && column.canFilter && column.render('Filter')}

                    <ReactTooltip place="bottom" id={column.id} effect="solid" className="tooltip">
                      {column.id}
                    </ReactTooltip>
                  </ThWrap>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );

  // key is added in by helper method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const RenderRow = ({ index, style }: any) => {
    const row = rows[index];
    if (!row) return <div>Loading...</div>;
    prepareRow(row);
    const { style: rowStyle, ...restRow } = row.getRowProps({ style });
    return (
      <div {...restRow} style={{ ...rowStyle, width: totalColumnsWidth }} className="tr">
        {row.cells.map((cell, idx) => {
          const isNumber = typeof cell.value === 'number';
          const cellValue = isNumber ? numberFormatter.format(cell.value) : cell.render('Cell');

          return (
            // key is added in by helper method
            // eslint-disable-next-line react/jsx-key
            <div {...cell.getCellProps()} className="td" key={idx}>
              <span>{cell.value === -Infinity ? '-' : cellValue}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderPage = page.map((row) => {
    const className = 'tr';
    if (!row) return <div>Loading...</div>;
    prepareRow(row);

    const trProps = withOverflow
      ? { ...row.getRowProps({ className }) }
      : {
          ...row.getRowProps({
            className,
            style: { width: '100%', display: 'flex' },
          }),
        };

    return (
      // key is added in by helper method
      // eslint-disable-next-line react/jsx-key
      <div {...trProps}>
        {row.cells.map((cell) => {
          const isNumber = typeof cell.value === 'number';
          const classNameExtended = 'td'.concat(isNumber ? ' right' : '');

          const tdProps = withOverflow
            ? { ...cell.getCellProps({ className: classNameExtended }) }
            : {
                ...cell.getCellProps({
                  className: classNameExtended,
                  style: { display: 'flex', boxSizing: 'border-box', width: '100%' },
                }),
              };

          const cellValue = isNumber ? numberFormatter.format(cell.value) : cell.render('Cell');

          return (
            // key is added in by helper method
            // eslint-disable-next-line react/jsx-key
            <div {...tdProps}>
              <span>{cell.value === -Infinity ? '-' : cellValue}</span>
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div>
      <StyledTableSticky withOverflow data-testid={id}>
        {(withColumnShowHide === true || endpoint || title) && (
          <HeaderWrapper>
            {title && <Header>{title}</Header>}

            <CardHeaderActions>
              {withColumnShowHide && (
                <DropdownButton icon={<SettingsIcon />} title="Show Table Filters" testId="showFilterButton">
                  <TableFilter id={`${id}Filter`} columns={allColumns} onClose={toggleOptions} />
                </DropdownButton>
              )}

              {endpoint && (
                <DownloadTableCsv
                  financialModel={financialModel}
                  endpoint={endpoint}
                  filterId={filterId || ''}
                  dataGroupingParams={dataGroupingParams}
                />
              )}
            </CardHeaderActions>
          </HeaderWrapper>
        )}
        <div {...getTableProps()} className="table sticky" style={{ width: '100%', height: '100%' }}>
          <div style={{ position: 'relative', flex: 1, zIndex: 0 }}>
            {isPaginated ? (
              <>
                {TableHeader}
                <div
                  {...getTableBodyProps()}
                  className="body"
                  style={{
                    height: fixedHeight ? fixedHeight : height,
                    width: withOverflow ? totalColumnsWidth : '100%',
                  }}
                >
                  {RenderPage}
                </div>
              </>
            ) : (
              <FixedSizeList
                height={fixedHeight ? fixedHeight : height}
                itemCount={data.length + 1}
                itemSize={TABLE_ROW_HEIGHT}
                width={'100%'}
                // key is added in by helper method
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                innerElementType={({ children, style, ...rest }: any) => (
                  <>
                    {TableHeader}
                    <div style={{ height: '100%' }} className="body">
                      <div {...getTableBodyProps()} {...rest} style={style}>
                        {children}
                      </div>
                    </div>
                  </>
                )}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </div>
        </div>
      </StyledTableSticky>
      {isPaginated && (
        <Pagination width={250}>
          <Button withIcon type="button" onClick={previousPage} isDisabled={!canPreviousPage}>
            Back
          </Button>

          <PageCount>{`${state.pageIndex + 1} of ${pageOptions.length}`}</PageCount>

          <Button withIcon type="button" onClick={nextPage} isDisabled={!canNextPage}>
            Next
          </Button>
        </Pagination>
      )}
    </div>
  );
}

export default TableSticky;
