import React, { ReactNode, ReactElement, MouseEvent } from 'react';

import { ButtonStyle, ButtonLoader } from './Button.styles';

export interface Props {
  testId?: string;
  type?: 'button' | 'submit';
  theme?: 'primary' | 'transparent';
  size?: 'small' | 'large';
  children: string | ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (arg?: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  withIcon?: boolean;
  unStyled?: boolean;
  title?: string;
}

const Button = ({
  testId,
  type = 'button',
  theme = 'primary',
  size,
  isDisabled = false,
  onClick,
  children,
  isLoading = false,
  className,
  withIcon = false,
  unStyled = false,
  title,
}: Props): ReactElement => (
  <ButtonStyle
    data-testid={testId}
    type={type}
    btnTheme={theme}
    onClick={onClick}
    disabled={isDisabled || isLoading}
    size={size}
    className={className}
    withIcon={withIcon}
    unStyled={unStyled}
    title={title}
  >
    {isLoading ? (
      <ButtonLoader data-testid={`${testId}Loader`}>
        <div className="container">
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
        </div>
      </ButtonLoader>
    ) : (
      children
    )}
  </ButtonStyle>
);

export default Button;
